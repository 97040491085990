import { Fragment, useEffect, useState } from 'react'
import { GroupedBiomarkers } from '../../../lib/validators'

import BiomarkerResultRow from '../../biomarker/BiomarkerResultRow'
import RoundedSection from '../../layout/RoundedSection'
import { ResultsBiomarkerProps } from '../../../lib/interfaces'
import {
  defaultConclusion,
  defaultDisclaimer,
  HistoricalBiomarkerResultMap,
} from '../../../lib/results'
import ResultAISummarySection from '../../result/ResultAISummarySection'
import posthog from '../../../lib/posthog'
import { simpleMdTransform } from '../../../lib/utils'
import { Button } from '../../form/button/Button'
import { useUser } from '../../../lib/store'

const baseDisclaimerKey = 'bgt_disclaimer'

export const ResultTabBiomarkers: React.FC<ResultsBiomarkerProps> = ({
  isResultOwner = false,
  groupedBiomarkers,
  resultUuid,
  resultAiSummaryConsentUuid,
  resultAiSummary,
  historicalData,
}) => {
  const [groupsOfBiomarkers, setGroupsOfBiomarkers] =
    useState<GroupedBiomarkers>()
  const [disclaimerDismissed, setDisclaimerDismissed] = useState(false)
  const [historicalBiomarkerData, setHistoricalBiomarkerData] = useState<
    HistoricalBiomarkerResultMap | undefined
  >(historicalData)
  const [disclaimerKey, setDisclaimerKey] = useState<string | undefined>()
  const user = useUser()

  useEffect(() => {
    setHistoricalBiomarkerData(historicalData)
  }, [historicalData])

  // on load, check the disclmaimer state
  useEffect(() => {
    if (!posthog.isFeatureEnabled('can-view-new-results-page')) {
      return
    }
    // update the disclaimer key for the user
    if (user && disclaimerKey === undefined) {
      setDisclaimerKey(`${baseDisclaimerKey}_${user.uuid}`)
      return
    }
    if (disclaimerKey === undefined) {
      return
    }
    const disclaimerState = localStorage.getItem(disclaimerKey)
    if (disclaimerState) {
      try {
        const disclaimerJson = JSON.parse(disclaimerState)
        if (disclaimerJson.expiresAt) {
          if (
            disclaimerJson.expiresAt &&
            disclaimerJson.expiresAt >
              Math.floor(Date.parse(new Date().toString()) / 1000)
          ) {
            setDisclaimerDismissed(true)
          }
        }
      } catch (e) {
        console.log('do nothing')
      }
    }
  }, [disclaimerKey, user])

  useEffect(() => {
    setGroupsOfBiomarkers(groupedBiomarkers)
    setHistoricalBiomarkerData(historicalData)
  }, [groupedBiomarkers, historicalData])

  const hideDisclaimer = () => {
    if (!disclaimerKey) {
      return
    }
    const d = new Date()

    d.setDate(d.getDate() + 30)
    // d.setMinutes(d.getMinutes() + 1)
    localStorage.setItem(
      disclaimerKey,
      JSON.stringify({
        expiresAt: Date.parse(d.toString()) / 1000,
        hidden: true,
      })
    )
    setDisclaimerDismissed(true)
  }

  return (
    <Fragment>
      {!groupsOfBiomarkers && <div>Loading...</div>}
      {groupsOfBiomarkers && groupsOfBiomarkers.length > 0 && (
        <Fragment>
          {posthog.isFeatureEnabled('can-view-new-results-page') &&
            !disclaimerDismissed && (
              <RoundedSection size="lg" className="mb-6 ">
                <div
                  className="select-none"
                  dangerouslySetInnerHTML={{
                    __html: simpleMdTransform('## Disclaimer') || '',
                  }}
                ></div>
                <p
                  className=" dark:text-gray-dark text-black/70 text-sm select-none italic"
                  dangerouslySetInnerHTML={{
                    __html: defaultDisclaimer || '',
                  }}
                ></p>
                <Button
                  color={'plain'}
                  classNames={`!m-0 !p-0 !mt-6 !text-md ${!disclaimerKey ? 'hidden' : ''}`}
                  label="Click here to hide the disclaimer for 30 days"
                  onClick={hideDisclaimer}
                ></Button>
              </RoundedSection>
            )}
          {posthog.isFeatureEnabled('can-use-ai-results-summary') && (
            <ResultAISummarySection
              enableGenerateButton={isResultOwner}
              resultUuid={resultUuid}
              resultAiSummaryConsentUuid={resultAiSummaryConsentUuid}
              resultAiSummary={resultAiSummary}
            />
          )}
          {groupsOfBiomarkers.map((group) => (
            <RoundedSection
              size="lg"
              className="mb-8"
              key={`${group.category.name}-section`}
            >
              <h2 className="text-lg font-semibold text-black/70 dark:text-white mb-2 select-none">
                {group.category.name}
              </h2>
              <div className="text-sm text-black/70 dark:text-gray-light mb-6 mt-4 select-none">
                {group.category.description}
              </div>
              {group.biomarkers.map((biomarker) => (
                <div className="rounded-md" key={biomarker.id}>
                  <BiomarkerResultRow
                    key={`${biomarker.id}-biomarker-row`}
                    // className="mt-4"
                    biomarker={biomarker}
                    historicalData={
                      historicalBiomarkerData &&
                      historicalBiomarkerData[biomarker.id] &&
                      historicalBiomarkerData[biomarker.id].length > 1
                        ? historicalBiomarkerData[biomarker.id]
                        : undefined
                    }
                  ></BiomarkerResultRow>
                  <hr className="border border-gray mt-8 mb-8 dark:border-dark-gray-light"></hr>
                </div>
              ))}
              <div className="mb-4"></div>
            </RoundedSection>
          ))}
          {posthog.isFeatureEnabled('can-view-new-results-page') && (
            <RoundedSection size="lg" className="mb-6 ">
              <div
                className="select-none"
                dangerouslySetInnerHTML={{
                  __html: simpleMdTransform('## Conclusion') || '',
                }}
              ></div>
              <p
                className=" text-sm select-none"
                dangerouslySetInnerHTML={{
                  __html: defaultConclusion || '',
                }}
              ></p>
            </RoundedSection>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}
