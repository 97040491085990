import { Fragment, useCallback, useEffect, useState } from 'react'
import { ThemeInterface } from '../../../../../lib/interfaces'
import { OrganisationWithBranding } from '../../../../context/OrganisationContext'
import { useNavigate } from 'react-router-dom'

import { Modal } from '../../../../layout/Modal'
import { getStoreUrl } from '../../../../../lib/routes'
import { InfoSection } from '../../../../ui/InfoSection'
import SimpleSelectField from '../../../../form/SimpleSelectField'
import TextInputField, {
  onChangeInterface,
} from '../../../../form/TextInputField'
import { authFetch } from '../../../../../providers/AuthProvider'
import { useDebouncedCallback } from 'use-debounce'
import { Store } from '../../../../../lib/validators'
import { createStore } from '../../../../../lib/organisation'

interface AddStoreModalProps {
  show: boolean
  theme?: ThemeInterface
  setShow: (openState: boolean) => void
  org: OrganisationWithBranding
  dismissOnAdd?: boolean
}

const slug = (s: string): string => {
  return s
    .toLowerCase()
    .replace(/\s\s+/gi, ' ')
    .replace(/\s+/gi, '-')
    .replace(/--/gi, '-')
    .replace(/[^A-Za-z0-9-]/gi, '')
}

export function AddStoreModal({
  show,
  setShow,
  theme,
  org,
  dismissOnAdd = false,
}: AddStoreModalProps) {
  const [open, setOpen] = useState(false)

  const [saveEnabled, setSaveEnabled] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const [selectedProvider, setSelectedProvider] = useState<string>('shopify')
  const [storeName, setStoreName] = useState<string>('')
  const [storeSigningSecret, setStoreSigningSecret] = useState<string>('')
  const [storeSlug, setStoreSlug] = useState<string>('')
  const [isStoreIdValid, setIsStoreIdValid] = useState<boolean | null>(null)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setOpen(show)

    if (show === true) {
      console.log('reset the dialog now')
      setError('')
      setStoreName('')
      setStoreSigningSecret('')
      setStoreSlug('')
      setIsStoreIdValid(null)
      setSelectedProvider('shopify')
    }
  }, [show])

  const checkIfStoreIdIsAvailable = async (slug: string): Promise<boolean> => {
    // setProcessing(true);
    const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify({
        query: `{
            isStoreIdAvailable(id: "${slug}")
          }
        `,
      }),
    })

    // setProcessing(false);
    setIsStoreIdValid(result.data.data.isStoreIdAvailable)

    return result.data.data.isStoreIdAvailable
  }
  const debouncedCheckIfStoreIdIsAvailable = useDebouncedCallback(
    checkIfStoreIdIsAvailable,
    1000
  )

  useEffect(() => {
    if (storeSlug) {
      debouncedCheckIfStoreIdIsAvailable(storeSlug)
    }
  }, [storeSlug, debouncedCheckIfStoreIdIsAvailable])

  useEffect(() => {
    console.log('is the store id value?', isStoreIdValid)
  }, [isStoreIdValid])

  const checkFormStatus = useCallback(() => {
    if (storeName.trim() === '') {
      setSaveEnabled(false)
      return
    }
    if (!isStoreIdValid) {
      setSaveEnabled(false)
      return
    }
    if (selectedProvider === 'shopify' && storeSigningSecret.trim() === '') {
      setSaveEnabled(false)
      return
    }
    setSaveEnabled(true)
  }, [storeName, isStoreIdValid, selectedProvider, storeSigningSecret])

  useEffect(() => {
    checkFormStatus()
  }, [checkFormStatus])

  return (
    <Modal
      show={open}
      error={error}
      loading={loading}
      options={{
        secondaryAction: {
          label: 'Dismiss',
          handler: () => {
            setShow(false)
          },
        },
        mainActions: [
          {
            label: 'Add',
            handler: async () => {
              // lets submit the form now.
              setLoading(true)
              let newStore: Store | undefined
              try {
                newStore = await createStore({
                  id: storeSlug,
                  name: storeName,
                  storeProvider: selectedProvider,
                  storeSigningSecret: storeSigningSecret,
                  organisationUuid: org.uuid,
                })
              } catch (e) {
                if (e instanceof Error) {
                  setError(e.message)
                }
              }

              setLoading(false)
              if (!newStore) {
                return
              }

              const storeUUid = newStore?.uuid

              if (dismissOnAdd) {
                setShow(false)
                return
              }
              navigate(getStoreUrl(org!.id, storeUUid))
            },
            disabled: !saveEnabled,
            loading: loading,
          },
        ],
        title: 'Configure new store',
        description: (
          <InfoSection>
            Configure a new store to enable the selling of your bundles you
            created here on an external store.
          </InfoSection>
        ),
        content: (
          <Fragment>
            <div className="mt-2">
              <SimpleSelectField
                name="provider"
                label="Store Provider"
                value={selectedProvider}
                handleChange={(e) => setSelectedProvider(e.strValue)}
                options={[
                  { label: 'Shopify', value: 'shopify' },
                  { label: 'Webflow', value: 'webflow' },
                ]}
              ></SimpleSelectField>
            </div>
            <div className="mt-2">
              <TextInputField
                id="storeName"
                name="storeName"
                type="text"
                label="Store Name"
                value={storeName}
                handleChange={(e) => {
                  setStoreName(e.strValue)
                  setStoreSlug(slug(e.strValue))
                }}
              ></TextInputField>
            </div>
            <div className="mt-2">
              <TextInputField
                id="storeSlug"
                label="Store Id"
                value={storeSlug}
                name="storeSlug"
                type="text"
                required={true}
                handleChange={(data: onChangeInterface) => {
                  setIsStoreIdValid(null)
                  setStoreSlug(slug(data.strValue))
                }}
              />
              <div>
                {isStoreIdValid === false && (
                  <span className="text-blood text-sm">
                    This store id is taken, please try another.
                  </span>
                )}
              </div>
            </div>
            {selectedProvider === 'shopify' && (
              <div className="mt-2">
                <TextInputField
                  id="storeSigningSecret"
                  name="storeSigningSecret"
                  type="text"
                  required={true}
                  label={
                    <div>
                      Store Signing Secret{' '}
                      <span className="text-blood font-bold">*</span>
                      <a
                        className="text-blood text-sm pl-4 float-right"
                        href="/help/"
                      >
                        What does this mean?
                      </a>
                    </div>
                  }
                  value={storeSigningSecret}
                  handleChange={(e) => setStoreSigningSecret(e.strValue)}
                ></TextInputField>
              </div>
            )}
          </Fragment>
        ),
      }}
      onClose={() => setShow(false)}
      theme={theme || undefined}
      size="lg"
    ></Modal>
  )
}
