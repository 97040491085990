import { Fragment, PropsWithChildren } from 'react'

interface ResultInfoSection extends PropsWithChildren {
  title?: string
}

export default function ResultInfoSection({
  title,
  children,
}: ResultInfoSection) {
  return (
    <Fragment>
      {title && (
        <div className="text-md font-bold pb-1 select-none dark:text-gray-dark">
          {title}
        </div>
      )}
      {typeof children === 'string' && (
        <p
          dangerouslySetInnerHTML={{
            __html: children?.toString().replace(/\n/gi, '<br />') || '',
          }}
          className="sm:pb-10 text-sm text-black/50 dark:text-off-white select-none"
        ></p>
      )}
      {typeof children === 'object' && <div>{children}</div>}
    </Fragment>
  )
}
