import { authFetch } from '../providers/AuthProvider'
import { Consent, ConsentSchema } from './validators'

interface ConsentToResultAiSummaryProps {
  resultUuid: string
  encodedSignature: string
}

export const consentToResultAiSummary = async (
  data: ConsentToResultAiSummaryProps
): Promise<Consent> => {
  const response = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation consentToResultAiSummary($data: ConsentToResultAiSummaryInput!) {
            consentToResultAiSummary(data: $data) {
              uuid
              profileUuid
              entity
              entityUuid
              consentData {
                firstName
                lastName
                email
                encodedSignature
              }
              signedAt
            }
          }
        `,
      variables: {
        data: data,
      },
    }),
  })
  const consent = ConsentSchema.parse(response.data.data.consentToResultAiSummary)

  return consent
}
