import { Fragment, useEffect, useState } from 'react'
import { setDocumentTitle } from '../../../../../lib/utils'
import { SettingsTabProps } from '../Settings'
import { useOrganisationContext } from '../../../../context/OrganisationContext'
import PageLoader from '../../../../layout/PageLoader'

import { Button } from '../../../../form/button/Button'
// import { OrganisationPayload } from '../../../../../lib/interfaces'
import { StoreList } from '../../../../../lib/validators'
import { useOrganisation } from '../../../../../lib/store'
import { GroupSection, GroupSections } from '../../../../layout/GroupSection'
import { getStoresForOrganisationUuid } from '../../../../../lib/organisation'
import ShopifyLogo from '../../../../../../public/img/providers/shopify.png?url'
import WebflowLogo from '../../../../../../public/img/providers/webflow.png?url'

import { Link } from 'react-router-dom'
import { InfoSection } from '../../../../ui/InfoSection'
import { AddStoreModal } from '../modals/AddStoreModal'

const getImageForProvider = (provider: string): string | undefined => {
  if (provider === 'shopify') {
    return ShopifyLogo
  }
  if (provider === 'webflow') {
    return WebflowLogo
  }
  return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
}

export const SettingsTabIntegrations: React.FC<SettingsTabProps> = () => {
  const { theme } = useOrganisationContext()
  const org = useOrganisation()
  const [stores, setStores] = useState<StoreList>([])

  const [loading, setLoading] = useState(true)
  const [openAddStoreModal, setOpenAddStoreModal] = useState(false)

  useEffect(() => {
    console.log('we got an org,', org)
    if (org) {
      setLoading(false)
      getStoresForOrganisationUuid(org.uuid).then(setStores)
    }
  }, [org])

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (!org) {
      return
    }

    console.log('handle submit fired.')
    // setIsSaving(true)
    // setIsSaving(false)
  }

  useEffect(() => {
    setDocumentTitle(`Integration Settings`, 'BRANDED')
  }, [])

  return (
    <Fragment>
      {loading && <PageLoader theme={org?.theme || undefined}></PageLoader>}
      {!loading && org && (
        <form onSubmit={handleSubmit}>
          <GroupSections>
            <GroupSection
              title="Store Fronts"
              description={
                <div>
                  Configure external stores to sell your bundles.
                  <br />
                  <Button
                    label="Configure new store"
                    onClick={() => setOpenAddStoreModal(true)}
                  ></Button>
                </div>
              }
              underneath
            >
              {(!stores || (stores && stores.length === 0)) && (
                <InfoSection>
                  Adding a store means you can sell your products at a markup.
                </InfoSection>
              )}
              {stores && stores.length > 0 && (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                  {stores.map((store) => (
                    <Link
                      to={`/org/${org.id}/settings/integrations/stores/${store.uuid}`}
                      className="border transition-all rounded-lg shadow-md hover:shadow-lg bg-white p-4 relative"
                      key={`'store-${store.id}`}
                    >
                      {store.name}
                      <img
                        src={getImageForProvider(store.storeProvider)}
                        className="max-h-6 max-w-24"
                      />
                      <div className="col-span-3 text-blood">edit</div>
                    </Link>
                  ))}
                </div>
              )}
              <AddStoreModal
                org={org}
                theme={theme || undefined}
                show={openAddStoreModal}
                setShow={setOpenAddStoreModal}
                dismissOnAdd={true}
              ></AddStoreModal>
            </GroupSection>
          </GroupSections>
        </form>
      )}
    </Fragment>
  )
}
