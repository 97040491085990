import React, { useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Modal, ModalOptionsProps } from '../layout/Modal'
import RoundedSection from '../layout/RoundedSection'
import { InfoSection } from '../ui/InfoSection'

interface ResultAISummaryConsentModalProps {
  onClose: () => void
  onConsent: (signature: string) => void
  isLoading: boolean
}

export const ResultAISummaryConsentModal: React.FC<
  ResultAISummaryConsentModalProps
> = ({ onClose, onConsent, isLoading }) => {
  const signatureRef = useRef<SignatureCanvas>(null)
  const [isSigned, setSigned] = useState(false)

  const handleConsentClick = () => {
    if (signatureRef.current) {
      const signature = signatureRef.current
        .getTrimmedCanvas() // For PR: is there a good argument not to trim?
        .toDataURL('image/png')
      onConsent(signature)
    }
  }

  const handleClearSignature = () => {
    if (signatureRef.current) {
      signatureRef.current.clear()
      setSigned(false)
    }
  }

  const handleCanvasChange = () => {
    if (signatureRef.current) {
      setSigned(!signatureRef.current.isEmpty())
    }
  }

  // this is a hack
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const modalOptions: ModalOptionsProps = {
    title: 'Consent Required',
    content: (
      <div className="consent-modal-content">
        <InfoSection className="!mb-2">
          You must consent to this really long thing that I came up with that
          requires your signature.
        </InfoSection>
        <RoundedSection size="lg" className="mb-2 !p-0 dark:!bg-gray-dark">
          <div className="overflow-hidden">
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                width: isMobile ? window.innerWidth - 40 : 462,
                height: 200,
              }}
              ref={signatureRef}
              onEnd={handleCanvasChange}
            />
          </div>
        </RoundedSection>
      </div>
    ),
    mainActions: [
      {
        label: isLoading ? 'Signing...' : 'Sign Consent',
        handler: handleConsentClick,
        disabled: isLoading || !isSigned,
        loading: isLoading,
        color: 'jade',
      },
      {
        label: 'Clear',
        handler: handleClearSignature,
        disabled: isLoading,
        color: 'mandarin',
      },
    ],
    secondaryAction: {
      label: 'Cancel',
      handler: onClose,
      disabled: isLoading,
      color: 'blood',
    },
  }

  return (
    <Modal show={true} onClose={onClose} options={modalOptions} size="lg" />
  )
}

export default ResultAISummaryConsentModal
