import { SparklesIcon } from '@heroicons/react/20/solid'
import { Button, ButtonProps } from './Button'

export function AIButton({ label, ...props }: Omit<ButtonProps, 'children'>) {
  const { loading, disabled } = props

  const glossEffect = `
      before:absolute before:top-0 before:left-[-150%] before:h-full before:w-[200%]
      before:bg-white/20 before:skew-x-[-25deg] before:opacity-0
      before:transition-all before:duration-[1.2s]
      ${!loading && !disabled ? 'hover:before:opacity-100 hover:before:translate-x-[150%]' : ''}
      overflow-hidden
    `

  return (
    <Button
      {...props}
      classNames={`relative inline-flex items-center space-x-2 ${glossEffect} ${props.classNames}`}
    >
      <span>{label}</span>
      <SparklesIcon height={20} />
    </Button>
  )
}
