import '../../../App.css'
import { PageHeading } from '../../layout/PageHeading'
import { actions, useUser } from '../../../lib/store'
import { useCallback, useEffect, useRef, useState } from 'react'
import TextInputField, { onChangeInterface } from '../../form/TextInputField'
import { useNavigate } from 'react-router-dom'
import slugify from 'slugify'
import toast from 'react-hot-toast'
import PageLoader from '../../layout/PageLoader'

import { getProfileData } from '../../../lib/profile'
import {
  CreateOrganisation,
  CreateOrganisationSchema,
  Profile,
} from '../../../lib/validators'
import { authFetch } from '../../../providers/AuthProvider'
// import SimpleSelectField from '../../form/SimpleSelectField'
import { useDebouncedCallback } from 'use-debounce'
import { refreshCurrentUser } from '../../../lib/utils'
import { orgHomePrefix } from '../../../lib/routes'
import { TermsAcceptanceModal } from './org/modals/TermsAcceptanceModal'
import { Button } from '../../form/button/Button'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { createStripeCustomer } from '../../../lib/billing'
// import {
//   createStripeCustomer,
//   createStripeSubscription,
// } from '../../../lib/billing'
// import {
//   CardCaptureDialog,
//   OnIntentRequestCallback,
//   OnIntentRequestError,
// } from '../../payment/CardCaptureDialog'

export default function OrganisationCreateForm() {
  const [openPlatformTermsModal, setOpenPlatformTermsModal] = useState(false)
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)
  const [organisationName, setOrganisationName] = useState<string>('')
  const [organisationUuid, setOrganisationUuid] = useState<string | undefined>()
  const [organisationEntityName, setOrganisationEntityName] =
    useState<string>('')
  const [organisationABN, setOrganisationABN] = useState<string>('')
  const [organisationId, setOrganisationId] = useState<string | undefined>()
  const [organisationSlug, setOrganisationSlug] = useState<string>('')
  // const [organisationType, setOrganisationType] = useState<
  //   'INDIVIDUAL' | 'COMPANY'
  // >('COMPANY')
  const organisationType = 'COMPANY'
  const [isOrgIdValid, setIsOrgIdValid] = useState<boolean | null>(null)
  const [saveEnabled, setSaveEnabled] = useState(false)
  const [saving, setSaving] = useState(false)
  // const [processing, setProcessing] = useState(false);
  const [profile, setProfile] = useState<Profile>()
  // const [open, setOpen] = useState(false)

  const user = useUser()
  const navigate = useNavigate()

  interface CreateOrganisationFormElement extends HTMLFormElement {
    readonly elements: HTMLFormControlsCollection
  }

  const handleSubmit = async (
    event: React.FormEvent<CreateOrganisationFormElement>
  ) => {
    event.preventDefault()
    console.log('form submitted..', organisationSlug, organisationName)
    // setProcessing(true);
    setSaving(true)

    if (profile === undefined) {
      toast.error('Missing profile, please try again later', {
        duration: 3000,
      })
      return
    }

    // commented out as we dont use the card dialog on the org creation flow
    // setOpen(true)

    // lets just create the org, and setup the access keys

    let orgUuid: string | undefined = organisationUuid
    let orgId: string | undefined = organisationId
    if (!organisationUuid) {
      console.log('we dont have an org, lets create one')
      const org = await createOrganisation({
        name: organisationName,
        id: organisationSlug,
        type: organisationType,
        profileUuid: profile.uuid,
        abn: organisationABN,
        entityName: organisationEntityName,
        orgTermsAccepted: acceptedTerms,
      })
      // create the customer
      await createStripeCustomer({
        organisationUuid: org.uuid,
        organisationName: org.name,
        entityName: organisationEntityName || undefined,
        abn: organisationABN || undefined,
      })

      orgUuid = org.uuid
      orgId = org.id
      setOrganisationUuid(org.uuid)
      setOrganisationId(org.id)
    } else {
      console.log('we do have an org, lets use it')
    }
    if (!orgUuid || !orgId) {
      throw new Error('Missing organisation uuid')
    }

    setSaving(false)
    await refreshCurrentUser()
    actions.setCurrentOrganisation(orgId)
    toast.success('Organisation created.', {
      duration: 3000,
    })
    navigate(`${orgHomePrefix}/${orgId}`)
  }

  interface CreateOrgProps {
    name: string
    id: string
    type: string
    profileUuid: string
    abn?: string
    entityName?: string
    orgTermsAccepted?: boolean
  }

  const createOrganisation = async (
    props: CreateOrgProps
  ): Promise<CreateOrganisation> => {
    const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify({
        query: `
          mutation name($data:CreateOrganisationInput!) {
            createOrganisation(data: $data) {
              uuid
              id
              name
              type
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          data: {
            name: props.name,
            id: slugify(props.id),
            type: props.type,
            profileUuid: props.profileUuid,
            abn: props.abn,
            entityName: props.entityName,
            orgTermsAccepted: props.orgTermsAccepted,
          } as CreateOrgProps,
        },
      }),
    })
    const org = CreateOrganisationSchema.parse(
      result.data.data.createOrganisation
    )
    return org
  }
  const checkIfOrgIdExists = async (slug: string): Promise<boolean> => {
    // setProcessing(true);
    const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify({
        query: `{
            doesOrganisationIdExist(id: "${slug}")
          }
        `,
      }),
    })
    console.log(result.data)
    // setProcessing(false);
    setIsOrgIdValid(!result.data.data.doesOrganisationIdExist)
    return result.data.data.doesOrganisationIdExist
    // const org = CreateOrganisationSchema.parse(
    //   result.data.data.createOrganisation
    // );
    // return org;
  }
  const debouncedCheckIfOrgIdExists = useDebouncedCallback(
    checkIfOrgIdExists,
    1000
  )

  // const resetForm = () => {
  //   formRef.current?.reset();
  //   setSaveEnabled(false);
  //   setProcessing(false);
  // };

  const checkFormStatus = useCallback(() => {
    console.log('check form status here, is org valid?', isOrgIdValid)
    if (!acceptedTerms) {
      setSaveEnabled(false)
      return
    }
    if (!isOrgIdValid) {
      setSaveEnabled(false)
      return
    }
    if (organisationName) {
      setSaveEnabled(true)
      return
    }
    setSaveEnabled(false)
  }, [organisationName, isOrgIdValid, acceptedTerms])

  useEffect(() => {
    if (organisationSlug) {
      debouncedCheckIfOrgIdExists(organisationSlug)
    }
  }, [organisationSlug, debouncedCheckIfOrgIdExists])

  const slug = (s: string): string => {
    return s
      .toLowerCase()
      .replace(/\s\s+/gi, ' ')
      .replace(/\s+/gi, '-')
      .replace(/--/gi, '-')
      .replace(/[^A-Za-z0-9-]/gi, '')
  }

  useEffect(() => {
    // if (organisationName && organisationName.length > 0) {
    //   // setIsOrgIdValid(null);
    //   // setOrganisationSlug(slug(organisationName));
    // }

    checkFormStatus()
  }, [
    organisationName,
    organisationSlug,
    organisationType,
    checkFormStatus,
    acceptedTerms,
  ])

  useEffect(() => {
    if (user) {
      getProfileData(user.profileUuid).then((profile) => {
        console.log(profile)
        setProfile(profile)
      })
    }
  }, [user])

  // const onIntentHandler = async (
  //   callback: OnIntentRequestCallback
  // ): Promise<void> => {
  //   if (profile === undefined) {
  //     toast.error('Missing profile, please try again later', {
  //       duration: 3000,
  //     })
  //     return
  //   }
  //   let orgUuid: string | undefined = organisationUuid
  //   let orgId: string | undefined = organisationId
  //   if (!organisationUuid) {
  //     console.log('we dont have an org, lets create one')
  //     const org = await createOrganisation({
  //       name: organisationName,
  //       id: organisationSlug,
  //       type: organisationType,
  //       profileUuid: profile.uuid,
  //     })
  //     orgUuid = org.uuid
  //     orgId = org.id
  //     setOrganisationUuid(org.uuid)
  //     setOrganisationId(org.id)
  //   } else {
  //     console.log('we do have an org, lets use it')
  //   }

  //   if (!orgUuid || !orgId) {
  //     throw new Error('Missing organisation uuid')
  //   }
  //   const customer = await createStripeCustomer({
  //     organisationUuid: orgUuid,
  //     organisationName: organisationName,
  //   })

  //   const response = await createStripeSubscription({
  //     customerId: customer.id,
  //     organisationUuid: orgUuid,
  //     productId: import.meta.env.VITE_API_ACCESS_STRIPE_ID, // api access for now
  //   })

  //   const intentResponse = await callback({
  //     clientSecret: response.clientSecret,
  //     type: response.type,
  //     returnUrl: `${import.meta.env.VITE_APP_URL}/org/${orgId}`,
  //   })

  //   // if we got a payment method, lets update the card.
  //   if (intentResponse && intentResponse.paymentMethod) {
  //     await finaliseBillingSetup({
  //       organisationUuid: orgUuid,
  //     })
  //     setSaving(false)
  //     await refreshCurrentUser()
  //     actions.setCurrentOrganisation(orgId)
  //     toast.success('Organisation created.', {
  //       duration: 3000,
  //     })
  //     intentResponse.finished()
  //     navigate(`${orgHomePrefix}/${orgId}`)
  //   }
  //   // else {
  //   //   toast.error('Failed to get payment method. Please try another card.', {
  //   //     duration: 5000,
  //   //   })
  //   // }
  // }
  // const onIntentError = (data: OnIntentRequestError) => {
  //   toast.error(data.message, {
  //     duration: 10000,
  //   })
  // }

  // const skipBilling = async () => {
  //   if (profile === undefined) {
  //     toast.error('Missing profile, please try again later', {
  //       duration: 3000,
  //     })
  //     return
  //   }
  //   const org = await createOrganisation({
  //     name: organisationName,
  //     id: organisationSlug,
  //     type: organisationType,
  //     profileUuid: profile.uuid,
  //   })
  //   // fix this at some point
  //   setSaving(false)
  //   await refreshCurrentUser()
  //   actions.setCurrentOrganisation(org.id)
  //   toast.success('Organisation created.', {
  //     duration: 3000,
  //   })
  //   navigate(`${orgHomePrefix}/${org.id}`)
  //   return
  // }

  // const onCancel = () => {
  //   // setProcessing(false);
  //   setSaving(false)
  // }

  return (
    <form className="max-w-5xl" onSubmit={handleSubmit} ref={formRef}>
      <PageHeading title="Create Organisation"></PageHeading>

      <div className="w-full pt-4">
        <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
          <TextInputField
            id="organisation-name"
            label="Organisation Name *"
            value={organisationName}
            name="name"
            placeholder="Example Company"
            type="text"
            required={true}
            handleChange={(data: onChangeInterface) => {
              setIsOrgIdValid(null)
              setOrganisationName(data.value.toString())
              setOrganisationSlug(slug(data.value.toString()))
              // setOrganisationEntityName(`${data.value.toString()} Pty Ltd`)
            }}
          />
        </div>
        <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
          <TextInputField
            id="organisation-entity-name"
            label="Entity name (for Tax purposes; if applicable)"
            value={organisationEntityName}
            name="entityName"
            placeholder="Example Company Pty Ltd"
            type="text"
            required={false}
            handleChange={(data: onChangeInterface) => {
              setOrganisationEntityName(data.value.toString())
            }}
          />
        </div>
        <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
          <TextInputField
            id="organisation-abn"
            label="Australian Business Number (ABN)"
            value={organisationABN}
            name="abn"
            placeholder="12312312398"
            type="text"
            required={false}
            handleChange={(data: onChangeInterface) => {
              if (data.value.toString().length <= 11) {
                setOrganisationABN(data.value.toString())
              }
            }}
          />
        </div>
        <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
          <TextInputField
            id="organisation-slug"
            label="Organisation Url Path *"
            value={organisationSlug}
            name="name"
            type="text"
            prefix={`${import.meta.env.VITE_API_URL.replace(
              'https://',
              ''
            )}/org/`}
            required={true}
            handleChange={(data: onChangeInterface) => {
              setIsOrgIdValid(null)
              setOrganisationSlug(slug(data.value.toString()))
            }}
          />
          <div>
            {isOrgIdValid === false && (
              <span className="text-blood">
                This organisation id is taken, please try another.
              </span>
            )}
          </div>
        </div>
        {/* <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
          <SimpleSelectField
            label="Organisation Type"
            value={organisationType}
            name="type"
            required={true}
            options={[
              {
                value: 'INDIVIDUAL',
                label: 'Individual',
              },
              {
                value: 'COMPANY',
                label: 'Company',
              },
            ]}
            handleChange={(data: onChangeInterface) =>
              setOrganisationType(
                data.value.toString() === 'INDIVIDUAL'
                  ? 'INDIVIDUAL'
                  : 'COMPANY'
              )
            }
          />
        </div> */}
        <div className="mt-6 flex items-center justify-end gap-x-2">
          {acceptedTerms && (
            <div>
              <CheckCircleIcon
                height={20}
                className="inline text-jade"
              ></CheckCircleIcon>{' '}
              Terms Accepted
            </div>
          )}
          <Button
            label={'View Platform Terms'}
            onClick={() => {
              setOpenPlatformTermsModal(true)
            }}
            color={acceptedTerms ? 'jade' : undefined}
          ></Button>{' '}
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          {/* <button
                type="button"
                onClick={resetForm}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Clear
              </button> */}
          <button
            type="submit"
            className={`rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm bg-bgt-primary/90 hover:bg-bgt-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-dark`}
            disabled={!saveEnabled}
          >
            Create Organisation
          </button>
        </div>
      </div>
      <TermsAcceptanceModal
        show={openPlatformTermsModal}
        setShow={setOpenPlatformTermsModal}
        termsToAcceptUrl={`${import.meta.env.VITE_APP_URL}/legal/BGT_Service_Provider_Agreement_1.0_2024.pdf`}
        onAccept={async () => {
          console.log('Accepted the terms.')
          setAcceptedTerms(true)
        }}
      ></TermsAcceptanceModal>
      {/* <CardCaptureDialog
        show={open}
        options={{
          title: 'Create subscription',
          showSkip: true,
          proceedText: 'Create subscription',
        }}
        onCancel={onCancel}
        onSkip={skipBilling}
        onClose={() => {
          setOpen(false)
          setSaving(false)
        }}
        onIntentRequestHandler={onIntentHandler}
        onError={onIntentError}
      ></CardCaptureDialog> */}
      {saving && <PageLoader></PageLoader>}
    </form>
  )
}
