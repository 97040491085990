import { Menu, Transition } from '@headlessui/react'
import React, { Fragment, ReactNode, useMemo } from 'react'
import { classNames } from '../../lib/utils'
import { Link } from 'react-router-dom'
import {
  actions,
  useCurrentOrganisation,
  useCurrentPage,
  useOrganisations,
  useUser,
} from '../../lib/store'
import AuthStore from '../../lib/AuthStore'
import {
  UserIcon,
  ArrowRightOnRectangleIcon,
  BuildingOffice2Icon,
  PlusIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/solid'

import {
  orgHomePrefix,
  organisationsHomeUrl,
  settingsAccountUrl,
} from '../../lib/routes'
import { UserImage } from '../layout/UserImage'
import { Organisation, User } from '../../lib/validators'
import posthog from '../../lib/posthog'
import { FaHelmetSafety } from 'react-icons/fa6'
import { IconType } from 'react-icons'

// eslint-disable-next-line react-refresh/only-export-components
export enum ItemType {
  Item = 'item',
  Divider = 'divider',
  GroupTitle = 'grouptitle',
}
export interface MenuItem {
  type: ItemType
  name: string | ReactNode
  href?: string
  icon?:
    | React.ForwardRefExoticComponent<
        Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
          title?: string | undefined
          titleId?: string | undefined
        } & React.RefAttributes<SVGSVGElement>
      >
    | IconType
  image?: ReactNode
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void
  className?: string
  current: boolean
}

export default function ProfileHeader() {
  const user = useUser()
  const orgs = useOrganisations()
  const org = useCurrentOrganisation()
  const currentPage = useCurrentPage()

  const getOrgs = (
    org: string | null,
    orgs: readonly Organisation[]
  ): MenuItem[] => {
    const orgList: MenuItem[] = []

    if (posthog.isFeatureEnabled('can-access-organisation-creation')) {
      orgList.push({
        type: ItemType.Divider,
        name: 'divider',
        current: false,
      })
      orgList.push({
        type: ItemType.GroupTitle,
        name: 'Organisations',
        current: false,
      })
      orgs.forEach((item) => {
        orgList.push({
          name: item.name,
          type: ItemType.Item,
          href: `${orgHomePrefix}/${item.id}`,
          image: item && item.theme && item.theme.logoSquare && (
            <img
              className={`inline-block mr-2 max-h-4 max-w-4`}
              src={item.theme.logoSquare}
            />
          ),
          icon: BuildingOffice2Icon,
          current: org && org === item.uuid ? true : false,
          onClick: async () => {
            // actions.setCurrentOrganisation(item.id);
          },
        })
      })
      // FF: Remove this once we go live.

      orgList.push({
        name: 'New Organisation',
        type: ItemType.Item,
        href: organisationsHomeUrl,
        icon: PlusIcon,
        current: false,
        onClick: async () => {
          actions.setCurrentOrganisation(null)
        },
      })
    }
    return orgList
  }

  const getPersonal = (u: User | null): MenuItem[] => {
    if (u === null) {
      return []
    }
    console.log('current page is', currentPage)
    return [
      {
        type: ItemType.GroupTitle,
        name: 'Personal',
        current: false,
      },
      {
        // name: [u.firstName, u.lastName].join(' '),
        name: 'My Results',
        type: ItemType.Item,
        href: `/`,
        icon: UserIcon,
        current: false,
        // org === null && (currentPage === 'results' || currentPage === 'tests')
        //   ? true
        //   : false,
        onClick: async () => {
          actions.setCurrentOrganisation(null)
        },
      },
    ]
  }

  const getAdmin = (u: User | null): MenuItem[] => {
    if (u === null || !user?.isAdministrator) {
      return []
    }
    return [
      {
        name: 'Admin Area',
        type: ItemType.Item,
        href: `/admin`,
        icon: FaHelmetSafety,
        current: false,
        onClick: async () => {
          actions.setCurrentOrganisation(null)
        },
      },
    ]
  }

  const userNavigation: MenuItem[] = useMemo(
    () => [
      // { name: "Your profile", href: "#" },
      ...getPersonal(user),
      ...getOrgs(org, orgs || []),

      // {
      //   name: "My org",
      //   type: ItemType.Item,
      //   href: settingsAccountUrl,
      //   icon: UserIcon,
      //   onClick: async (e: React.MouseEvent<HTMLElement>) => {
      //     console.log("in the onclick", e.getModifierState("Control"));
      //   },
      // },
      {
        type: ItemType.Divider,
        name: 'divider',
        current: false,
      },
      // {
      //   name: "Health Record",
      //   href: settingsHealthRecordUrl,
      //   icon: ClipboardDocumentCheckIcon,
      // },
      {
        type: ItemType.GroupTitle,
        name: 'Account',
        current: false,
      },
      {
        name: 'Settings',
        type: ItemType.Item,
        href: settingsAccountUrl,
        icon: Cog6ToothIcon,
        onClick: async () => {
          actions.setCurrentOrganisation(null)
        },
        current: false, // fix this
      },
      ...getAdmin(user),
      {
        name: 'Sign out',
        type: ItemType.Item,
        href: undefined,
        icon: ArrowRightOnRectangleIcon,
        onClick: async () => {
          actions.setCurrentOrganisation(null)
          AuthStore.beginLogoutProcess()
        },
        className: 'pt-2',
        current: false,
      },
    ],
    [orgs, org, user]
  )
  return (
    <Menu as="div" className="relative">
      <Menu.Button className="-m-1.5 flex items-center p-1.5 ">
        <span className="sr-only">Open user menu</span>
        <UserImage size="sm" image={user?.image}></UserImage>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2.5 min-w-60 origin-top-right rounded-md bg-white py-1 shadow-xl ring-1 ring-gray-dark/10 focus:outline-none max-h-[80dvh] overflow-x-auto dark:bg-dark-gray-lighter">
          {userNavigation.map((item, k) => (
            <Menu.Item key={`menu-item${k}`}>
              <Fragment>
                {item.type === ItemType.Divider && (
                  <hr className="my-2 dark:border-dark-gray-light"></hr>
                )}
                {item.type === ItemType.GroupTitle && (
                  <small className="uppercase text-gray-dark px-3 py-1 text-xs">
                    {item.name}
                  </small>
                )}
                {item.type === ItemType.Item && (
                  <Link
                    to={item.href ? item.href : window.location.href}
                    onClick={
                      item.onClick !== undefined ? item.onClick : () => {}
                    }
                    className={classNames(
                      'block px-3 pb-1 text-sm leading-6 text-black/70 dark:text-white/70 hover:text-black dark:hover:text-white hover:bg-gray-light dark:hover:bg-dark-gray-lighter',
                      item.className ? item.className : '',
                      item.current
                        ? 'bg-gray-light text-black dark:text-white dark:bg-dark-gray-light'
                        : ''
                    )}
                  >
                    {item.image && item.image}
                    {!item.image && item.icon && (
                      <item.icon
                        className="h-4 w-4 shrink-0 inline-block mr-2"
                        aria-hidden="true"
                      ></item.icon>
                    )}
                    {item.name}
                  </Link>
                )}
              </Fragment>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
