import { PropsWithChildren } from "react";

interface ComingSoonProps extends PropsWithChildren {
  title?: string;
}

export function ComingSoon({ children, title }: ComingSoonProps) {
  return (
    <div className="relative">
      <div className="backdrop-blur-sm bg-off-white/50 absolute -top-[10px] -left-[10px] w-[calc(100%+20px)] h-[calc(100%+20px)] flex justify-center items-center text-center select-none">
        {title || "Coming soon"}
      </div>
      <div>{children}</div>
    </div>
  );
}
