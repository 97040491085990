import { Fragment, useEffect, useState } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { Button } from "./button/Button";
import { defaultInputClasses } from "./TextInputField";
import { Popover, Transition } from "@headlessui/react";

export interface ColorPickerResult {
  name: string;
  color: string;
}

export interface ColorPickerProps {
  color: string;
  name: string;
  onPick: (color: ColorPickerResult) => void;
}

export function ColorPicker(props: ColorPickerProps) {
  const [color, setColor] = useState(props.color);

  const originalColor = props.color.toString();

  useEffect(() => {
    setColor(props.color);
  }, [props.color]);

  const onPick = (color: string) => {
    setColor(color);
    props.onPick({
      color,
      name: props.name,
    });
  };

  const resetColor = () => {
    // setColor(originalColor);
    onPick(originalColor);
  };

  return (
    <Fragment>
      <Popover className="relative">
        <Popover.Button as={Fragment}>
          <div className="grid grid-cols-2 max-w-48">
            <div
              className={`w-20 h-10 rounded-md shadow-md bg-[--bg-color]  cursor-pointer inline-block`}
              style={
                {
                  "--bg-color": color,
                } as React.CSSProperties
              }
            ></div>
            <div className="inline-block w-20 h-10 content-center text-sm px-2 dark:text-white">
              {color.toUpperCase()}
            </div>
          </div>
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-10">
            <div className="bg-off-white shadow-lg p-4 rounded-lg">
              <HexColorPicker color={color} onChange={onPick} />
              <HexColorInput
                color={color}
                onChange={onPick}
                className={`${defaultInputClasses} w-32 rounded-md`}
                prefixed={true}
              />
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      <Button
        color="plain"
        label={color !== props.color ? "Reset" : " "}
        type="button"
        classNames="!text-md"
        onClick={resetColor}
        disabled={color === originalColor}
      ></Button>
    </Fragment>
  );
}
