import {
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { orgHomePrefix } from '../lib/routes'
import { useEmailVerified } from '../lib/store'
import { resendVerificationEmail } from '../lib/user'
import { useOrganisationContext } from './context/OrganisationContext'
import Header from './Header'
import Sidebar from './sidebar/Sidebar'

export interface PageWithProps extends PropsWithChildren {
  backgroundColor?: string
  fullViewport?: boolean
  className?: string
}

function Page(props: PageWithProps) {
  const emailVerified = useEmailVerified()
  const location = useLocation()
  const { org, theme } = useOrganisationContext()
  const [isEmailVerified, setIsEmailVerified] = useState<boolean | null>(null)
  const [isChromeless, setChromeless] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false)

  useEffect(() => {
    // set the profile to be the first one
    // console.log("waiting for a user");
    setIsEmailVerified(emailVerified)

    // fetch the tests here
  }, [emailVerified])

  const handlePageLoad = useCallback(
    (pathname: string): void => {
      // console.log("++++ handle page load");
      // handle chromeless browser
      console.log('Loaded Page: ', pathname)
      const baseSearchStr = location.search.substring(1, location.search.length)
      const searchParts = baseSearchStr.split('&')
      for (let i = 0; i < searchParts.length; i += 1) {
        const keyval = searchParts[i].split('=')
        if (keyval[0] === 'chromeless') {
          setChromeless(true)
          break
        }
      }
    },
    [location]
  )

  useEffect(() => {
    handlePageLoad(location.pathname)
  }, [handlePageLoad, location])

  const hasUserVerifiedEmail = (): boolean | null => {
    if (isEmailVerified === null) {
      // default to true so we don't flash the banner
      return true
    }
    return isEmailVerified
  }

  const getPageBackgroundColor = (): string => {
    return props.backgroundColor || ''
  }

  useEffect(() => setSidebarOpen(false), [location])

  return (
    <Fragment>
      {!isChromeless && (
        <div className="h-full">
          <Header
            setSidebarOpen={setSidebarOpen}
            theme={theme || undefined}
          ></Header>
          <Sidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            theme={theme || undefined}
            urlPrefix={org ? `${orgHomePrefix}/${org?.id}` : '/'}
          ></Sidebar>

          <div
            className={`lg:pl-72 z-0 pt-16 relative ${getPageBackgroundColor()}`}
          >
            {!hasUserVerifiedEmail() && (
              <div className="fixed z-40 backdrop-blur-md lg:pl-72 pt-16 left-0 top-0 w-full bg-jade/80 shadow-lg p-2 leading-3">
                <div className="p-2 text-sm pl-4">
                  Your email has not been verified, please check your email for
                  a verification email or{' '}
                  <button
                    className="cursor-pointer underline"
                    onClick={() => resendVerificationEmail()}
                  >
                    resend
                  </button>
                </div>
              </div>
            )}
            <main
              className={`${
                props.fullViewport === true ? '' : 'px-4 sm:px-6 lg:px-4'
              } ${
                props.fullViewport === true
                  ? ''
                  : hasUserVerifiedEmail()
                    ? `pt-4 pb-5`
                    : 'pt-20 pb-5'
              } `}
            >
              <div
                className={`${props.fullViewport ? '' : 'max-w-screen-lg'} ${props.className || ''}`}
              >
                {props.children}
              </div>
            </main>
          </div>
        </div>
      )}
      {isChromeless && <div>{props.children}</div>}
    </Fragment>
  )
}

export default Page
