import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { ProductWithTestsAndBiomarkersSelected } from '../../lib/validators'
import Checkbox from '../form/Checkbox'
import { onChangeInterface } from '../form/TextInputField'
import RoundedSection from '../layout/RoundedSection'

interface PackageCustomisationItemProps {
  product: ProductWithTestsAndBiomarkersSelected
  onChange?: (data: onChangeInterface) => void
  onInfoClick?: (biomarker: ProductWithTestsAndBiomarkersSelected) => void
  highlight?: string
}

export function PackageCustomisationItem({
  product,
  onChange,
  onInfoClick,
  highlight,
}: PackageCustomisationItemProps) {
  const highlightRegex = new RegExp(`(${highlight})`, 'i')
  return (
    <div className={`relative group`}>
      <Checkbox
        id={product.id}
        name={product.id}
        type="checkbox"
        onChange={onChange}
        checked={product.checked}
        className="w-6 h-6 !absolute top-8 right-2 z-50 hidden"
        checkboxLabel={
          <RoundedSection
            size="lg"
            className="relative group !p-0 -mt-6 dark:!bg-dark-gray-lighterer"
          >
            <div className="absolute bottom-2 right-2">
              <InformationCircleIcon
                width={24}
                height={24}
                className="text-white bg-jade/80 hover:bg-jade rounded-full font-bold"
                onClick={(e) => {
                  e.preventDefault()
                  onInfoClick && onInfoClick(product)
                }}
              ></InformationCircleIcon>
            </div>
            <div className="group-has-[:checked]:bg-jade/30 rounded-lg p-4">
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    highlight && highlight.length > 1
                      ? product.name.replace(
                          highlightRegex,
                          '<span class="rounded-sm bg-mandarin/40">$1</span>'
                        )
                      : product.name,
                }}
              >
                {/* {product.name} */}
              </span>
              <div className="text-gray-dark text-sm">
                {product.tests?.map((test) => (
                  <span
                    key={`test-${product.id}-biomarker-${test.id}`}
                    className="text-gray-dark text-sm"
                    dangerouslySetInnerHTML={{
                      __html:
                        highlight && highlight.length > 1
                          ? `${test.name.replace(
                              highlightRegex,
                              '<span class="rounded-sm bg-mandarin/40">$1</span>'
                            )}, `
                          : `${test.name}, `,
                    }}
                  ></span>
                ))}
              </div>
            </div>
          </RoundedSection>
        }
      ></Checkbox>
    </div>
  )
}
