import { useEffect, useState } from 'react'
import { Modal } from '../../layout/Modal'
import { getBiomarkers } from '../../../lib/biomarker'
import { BiomarkerItem } from '../../../lib/validators'
import SimpleSelectField, {
  SimpleSelectItemProps,
} from '../../form/SimpleSelectField'
import { onChangeInterface } from '../../form/TextInputField'

interface SelectAdditionalBiomarkerModalProps {
  show: boolean
  exclude?: string[]
  setShow: (openState: boolean) => void
  onAdd: (biomarker: BiomarkerItem) => void
}

export function SelectAdditionalBiomarkerModal({
  show,
  exclude,
  setShow,
  onAdd,
}: SelectAdditionalBiomarkerModalProps) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [biomarkers, setBiomarkers] = useState<BiomarkerItem[]>()
  const [selectBiomarkers, setSelectBiomarkers] =
    useState<SimpleSelectItemProps[]>()
  const [selectedBiomarker, setSelectedBiomarker] = useState<BiomarkerItem>()

  useEffect(() => {
    if (show) {
      console.log('load the biomarkers', exclude)
      getBiomarkers().then((result) => {
        setBiomarkers(result)
        setSelectBiomarkers(
          result
            .filter((i) => {
              return (
                (exclude && exclude.indexOf(i.id) === -1) ||
                exclude === undefined
              )
            })
            .map((i) => {
              return {
                label: `${i.name} (${i.commonName})`,
                value: i.id,
              }
            })
        )
        setLoading(false)
      })
    }
    setOpen(show)
  }, [show, exclude])

  const handleBiomarkerSelect = (data: onChangeInterface) => {
    const selected = biomarkers?.filter((b) => b.id === data.value)
    if (selected && selected.length === 1) setSelectedBiomarker(selected[0])
  }

  return (
    <Modal
      show={open}
      loading={loading}
      options={{
        mainActions: [
          {
            label: 'Add',
            handler: () => {
              selectedBiomarker && onAdd(selectedBiomarker)
              setShow(false)
            },
          },
        ],
        title: 'Add new biomarker to result',
        content: (
          <div>
            <SimpleSelectField
              label="Select biomarker"
              firstItemText="Select biomarker"
              name="biomarker"
              options={selectBiomarkers || []}
              handleChange={handleBiomarkerSelect}
              value={selectedBiomarker ? selectedBiomarker.id : ''}
            />
          </div>
        ),
      }}
      onClose={() => setShow(false)}
      size="md"
    ></Modal>
  )
}
