import {
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react'
import '../../App.css'

import { useUser } from '../../lib/store'

import { useLocation } from 'react-router-dom'
import { User } from '../../lib/validators'

import PageSection from '../layout/PageSection'
import { settingsUrlRegex } from '../../lib/routes'
import Page from '../Page'
import { SettingsTabAccount } from './settings-tabs/SettingsTabAccount'

import { Tab } from '../../lib/interfaces'
import PageLoader from '../layout/PageLoader'
import { TabbedMenu } from '../layout/TabbedMenu'

export interface SettingsTabProps extends PropsWithChildren {
  profile: string
  user: User
}

// const createDynamicComponent = (
//   component: React.ComponentType<any>,
//   props: any
// ) => {
//   return React.createElement(component, props);
// };

const baseTabs: Record<string, Tab> = {
  '': {
    name: 'Account',
    href: '',
    current: false,
  },
  // health: {
  //   name: "My Health Record",
  //   href: "health",
  //   current: false,
  // },
}

export default function SettingsPage() {
  const [profile, setProfile] = useState('')
  const [currentPage, setCurrentPage] = useState<string>('')
  const [tabs, setTabs] = useState<Record<string, Tab>>(baseTabs)

  const location = useLocation()
  const user = useUser()
  // const params = useParams();

  const getTabs = useCallback(
    (page: string) => {
      Object.entries(baseTabs).map(([key, val]) => {
        val.current = false
        if (key === page) {
          val.current = true
        }

        return val
      })
      return tabs
    },
    [tabs]
  )

  const handlePageLoad = useCallback(
    (pathname: string): void => {
      const re = new RegExp(settingsUrlRegex)
      const matches = re.exec(pathname)
      // console.log("matches", matches, re.test(location.pathname));
      if (!matches || matches.length === 0) {
        throw new Error('Tab not found')
      }

      const tab = matches.pop()?.replace('/', '')
      if (tab === undefined) {
        throw new Error('Tab not found')
      }

      setCurrentPage(tab)
      const latestTabs = getTabs(tab)
      setTabs(latestTabs)
    },
    [getTabs]
  )

  useEffect(() => {
    handlePageLoad(location.pathname)
  }, [location, handlePageLoad])

  useEffect(() => {
    handlePageLoad(window.location.pathname)
  }, [handlePageLoad])

  useEffect(() => {
    // set the profile to be the first one
    if (user) {
      setProfile(user.profileUuid)
    }
    // if (profile) {
    //   getResultsData();
    // }
    // set default profile to be the first one

    // fetch the tests here
  }, [user, profile])

  return (
    <Page>
      <PageSection>
        <Fragment>
          <TabbedMenu tabs={tabs} currentPage={currentPage}></TabbedMenu>

          {!user && <PageLoader></PageLoader>}
          {user && currentPage !== null && currentPage === '' && (
            <SettingsTabAccount
              profile={profile}
              user={user}
            ></SettingsTabAccount>
          )}
        </Fragment>
      </PageSection>
    </Page>
  )
}
