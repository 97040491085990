import { PageHeading } from '../../../layout/PageHeading'
import PageSection from '../../../layout/PageSection'
import { useOrganisationContext } from '../../../context/OrganisationContext'
import { setDocumentTitle } from '../../../../lib/utils'
import { Fragment } from 'react'
import OrgPage from '../../../OrgPage'

export function OrgHome() {
  const { org, theme } = useOrganisationContext()

  setDocumentTitle(org && org?.name, 'BRANDED')

  return (
    <Fragment>
      {org && (
        <OrgPage org={org} theme={theme || undefined}>
          <PageSection>
            <PageHeading title={org.name}></PageHeading>
            <div>Organisation home page</div>
          </PageSection>
        </OrgPage>
      )}
    </Fragment>
  )
}
