import { Fragment, useCallback, useEffect, useState } from 'react'
import '../../../../App.css'

import { useUser } from '../../../../lib/store'
import { useParams } from 'react-router-dom'
import { ResultReferral } from '../../../../lib/validators'

import PageSection from '../../../layout/PageSection'
import { orgResultUrlRegex } from '../../../../lib/routes'

import {
  getHistoricalResultsForBiomarkers,
  getResultsDataForProfileAndUuid,
  HistoricalBiomarkerResultMap,
  transformHistoricalResultData,
} from '../../../../lib/results'
import PageLoader from '../../../layout/PageLoader'
import { ResultBiomarkerViewer } from '../../../ui/ResultBiomarkerViewer'
import { useOrganisationContext } from '../../../context/OrganisationContext'
import OrgPage from '../../../OrgPage'
import posthog from '../../../../lib/posthog'
import { ResultDocumentViewer } from '../../../ui/ResultDocumentViewer'

export default function OrgResult() {
  const { org, theme } = useOrganisationContext()
  const [loading, setLoading] = useState(true)
  const [result, setResult] = useState<ResultReferral>()
  const [historicalData, setHistoricalData] = useState<
    HistoricalBiomarkerResultMap | undefined
  >()

  const [profile, setProfile] = useState('')

  const user = useUser()
  const params = useParams()

  const getResultsData = useCallback(async (): Promise<ResultReferral> => {
    // setTimeout(async () => {
    if (!user) {
      throw new Error('Missing user')
    }
    if (!params.uuid) {
      throw new Error('Missing url param')
    }

    const parsedData = await getResultsDataForProfileAndUuid({
      resultUuid: params.uuid,
      profileUuid: profile,
      organisationUuid: org!.uuid,
    })
    setResult(parsedData)
    setLoading(false)
    return parsedData
  }, [params.uuid, profile, user])

  useEffect(() => {
    // set the profile to be the first one
    if (user && !profile) {
      setProfile(user.profileUuid)
      return
    }
    if (profile) {
      getResultsData()
    }

    // fetch the tests here
  }, [user, profile, getResultsData])

  const getHistoricalBiomarkerData = useCallback(
    async (biomarkers: string[]): Promise<void> => {
      // setTimeout(async () => {
      if (!result) {
        return
      }
      if (!user) {
        return
      }
      if (!params.uuid) {
        return
      }
      if (!org) {
        return
      }

      const parsedData = await getHistoricalResultsForBiomarkers({
        profileUuid: profile,
        organisationUuid: org.uuid,
        biomarkers: biomarkers,
        forProfile: result?.profileUuid,
      })
      const transformed = transformHistoricalResultData(parsedData)
      setHistoricalData(transformed)
    },
    [params.uuid, profile, user, org, result]
  )

  useEffect(() => {
    // set the profile to be the first one
    if (
      result &&
      result.biomarkers &&
      posthog.isFeatureEnabled('can-view-biomarker-history')
    ) {
      const biomarkerIds = result.biomarkers.map((b) => b.id)
      getHistoricalBiomarkerData(biomarkerIds)
    }

    // fetch the tests here
  }, [result, profile, getHistoricalBiomarkerData])

  return (
    <Fragment>
      {org && (
        <OrgPage
          theme={theme || undefined}
          org={org}
          tabs={[
            { name: 'Results', href: `/org/${org.id}/results` },
            {
              name: `${result?.patientName ? `${result.patientName} - ` : ''}${result?.referral?.testName}`,
              href: `/org/${org.id}/results/${result?.uuid}`,
            },
          ]}
        >
          <PageSection>
            {!loading &&
              result &&
              result.referral?.referralType === 'blood' && (
                <ResultBiomarkerViewer
                  profileUuid={profile}
                  resultItem={result}
                  patientName={result.patientName || undefined}
                  urlRegex={orgResultUrlRegex}
                  historicalData={historicalData}
                  org={org}
                ></ResultBiomarkerViewer>
              )}
            {!loading &&
              result &&
              result.referral?.referralType !== 'blood' && (
                <ResultDocumentViewer
                  profileUuid={profile}
                  resultItem={result}
                  urlRegex={orgResultUrlRegex}
                ></ResultDocumentViewer>
              )}
          </PageSection>
          {loading && <PageLoader></PageLoader>}
        </OrgPage>
      )}
    </Fragment>
  )
}
