import { Puff } from 'react-loading-icons'
import { ThemeInterface } from '../../lib/interfaces'

interface PageLoaderProps {
  theme?: ThemeInterface
  size?: number
  speed?: number
}
//<div className="w-full lg:w-[calc(100vw-18rem)] h-full lg:h-[calc(100vh-4rem)] fixed top-0 lg:top-16 lg:left-72 left-0 bg-off-white opacity-75 z-100 flex justify-center items-center text-center">
export default function PageLoader({
  theme,
  size = 100,
  speed = 0.8,
}: PageLoaderProps) {
  return (
    <div className="w-full lg:w-[calc(100dvw-18rem)] h-full lg:h-[calc(100dvh-4rem)] fixed top-0 lg:top-16 lg:left-72 left-0 bg-off-white opacity-75 z-100 flex justify-center items-center text-center dark:bg-dark-gray-light ">
      <div className="flex justify-center items-center">
        <Puff
          height={size}
          width={size}
          speed={speed}
          stroke={theme && theme.primaryColor ? theme.primaryColor : `#FF3A5F`}
          fill={theme && theme.primaryColor ? theme.primaryColor : `#FF3A5F`}
        />
      </div>
    </div>
  )
}
