import { useEffect } from "react";
import "../../App.css";

export default function CallbackPage() {
  // this page is just a placeholder so we have a route for /callback,
  // all of the logic is actually happening in the AppWrapper
  useEffect(() => {
    console.log("callback page loaded");
  });

  return (
    <>
      <div>callback page</div>
    </>
  );
}
