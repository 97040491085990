import { Fragment, ReactNode, useEffect, useState } from 'react'
import { ThemeInterface } from '../../../../../lib/interfaces'
import { OrganisationWithBranding } from '../../../../context/OrganisationContext'

import { Modal } from '../../../../layout/Modal'
import {
  ActiveProducts,
  PackageWithTestsAndBiomarkers,
} from '../../../../../lib/validators'
import { getBasicProductsForOrganisation } from '../../../../../lib/organisation'

import { InfoSection } from '../../../../ui/InfoSection'
import SimpleSelectField, {
  SimpleSelectItemProps,
} from '../../../../form/SimpleSelectField'
import TextInputField, {
  onChangeInterface,
} from '../../../../form/TextInputField'

import { TrashIcon } from '@heroicons/react/24/solid'

interface PickProductsModalProps {
  show: boolean
  theme?: ThemeInterface
  setShow: (openState: boolean) => void
  org: OrganisationWithBranding
  dismissOnSelect?: boolean
  message: string | ReactNode
  onSelect?: (added: ActiveProducts) => void
  onDismiss?: () => void
  btnLabel?: string
  selected?: ActiveProducts
  provider?: string
}

type ProductMap = Record<string, PackageWithTestsAndBiomarkers>

export function PickProductsModal({
  show,
  setShow,
  theme,
  onSelect,
  onDismiss,
  dismissOnSelect = false,
  btnLabel,
  selected,
  org,
  provider,
}: PickProductsModalProps) {
  const [open, setOpen] = useState(false)
  const [productMap, setProductMap] = useState<ProductMap>({})
  const [products, setProducts] = useState<
    PackageWithTestsAndBiomarkers[] | undefined
  >()
  const [loading, setLoading] = useState(true)
  const [formValid, setFormValid] = useState(false)
  const [validOptions, setValidOptions] = useState<SimpleSelectItemProps[]>([])
  const [activeProducts, setActiveProducts] = useState<ActiveProducts>({})

  useEffect(() => {
    setOpen(show)
    if (show && org && !products) {
      resetForm()
      setLoading(true)

      getBasicProductsForOrganisation(org.uuid).then((theProducts) => {
        setProducts(theProducts)

        const tmpProductMap: ProductMap = {}
        theProducts.map((p) => (tmpProductMap[p.id] = p))
        setProductMap(tmpProductMap)
        // const tmpSelectedProducts: SelectedProduct = {}
        // theProducts.map((p) =>
        //   selected && selected.indexOf(p.id) !== -1
        //     ? (tmpSelectedProducts[p.id] = p)
        //     : false
        // )
        // setSelectedDict(tmpSelectedProducts)

        // const tmpActive: ActiveProducts = {}
        // theProducts.map((p) =>
        //   selected && selected[p.id]
        //     ? (tmpActive[p.id] = selected[p.id])
        //     : false
        // )
        // setActiveProducts(tmpActive)
        setLoading(false)
      })
    }
  }, [show, products, org, selected])

  useEffect(() => {
    console.log('the active products', activeProducts)
    const tmpValidOptions: SimpleSelectItemProps[] = []
    if (products) {
      products.map((p) =>
        (selected && selected[p.id]) || (activeProducts && activeProducts[p.id])
          ? false
          : tmpValidOptions.push({ value: p.id, label: p.name })
      )
      setValidOptions(tmpValidOptions)
    }
    if (Object.keys(activeProducts).length === 0) {
      setFormValid(false)
      return
    }
    console.log('lets see if the form is valid')
    let isValid = true
    for (const i in activeProducts) {
      if (!activeProducts[i].externalId) {
        isValid = false
      }
    }
    setFormValid(isValid)
  }, [selected, products, activeProducts])

  // lets get the products for an org
  // useEffect(() => {
  //   console.log('incoming selected', selected)
  //   console.log(
  //     'updated selected dict with the ones we have selected',
  //     selectedDict
  //   )
  // }, [selectedDict, selected])

  const testSelected = (e: onChangeInterface) => {
    setActiveProducts((prev) => ({
      ...prev,
      [e.strValue]: {
        id: e.strValue,
        name: productMap[e.strValue] ? productMap[e.strValue].name : 'Unknown',
      },
    }))
    // wait a bit and select the elemtn.. its a hack
    setTimeout(() => {
      document.getElementById(`external_${e.strValue}`)?.focus()
    }, 100)
  }
  const handleExternalIdChange = (e: onChangeInterface) => {
    const name = e.name.replace('external_', '')
    const prevData = { ...activeProducts[name] }
    prevData.externalId = e.strValue
    setActiveProducts((prev) => ({
      ...prev,
      [name]: prevData,
    }))
  }

  const removeItem = (itemId: string) => {
    const tmpActiveProducts: ActiveProducts = {}
    Object.values(activeProducts).map((p) => {
      if (p.id !== itemId) {
        tmpActiveProducts[p.id] = p
      }
    })
    setActiveProducts(tmpActiveProducts)
  }

  const resetForm = () => {
    console.info('RESET THE FORM ++++++++++++++++++++++++++++++++ ')
    setValidOptions([])
    setProducts(undefined)
    setFormValid(false)
    setProductMap({})
    setActiveProducts({})
  }

  return (
    <Modal
      show={open}
      loading={loading}
      options={{
        secondaryAction: {
          label: 'Dismiss',
          handler: () => {
            setShow(false)
            setTimeout(() => resetForm(), 500)
            onDismiss && onDismiss()
          },
        },
        mainActions: [
          {
            label: btnLabel ? btnLabel : 'Select',
            disabled: !formValid,
            handler: async () => {
              // lets submit the form now.
              onSelect && onSelect(activeProducts)
              if (dismissOnSelect) {
                setShow(false)
              }
              setTimeout(() => resetForm(), 500)
            },
          },
        ],
        title: 'Are you sure?',
        content: (
          <div>
            <InfoSection>
              To add a bundle here, you will need the id of your product in your{' '}
              {provider} store (external ID).
            </InfoSection>
            {
              <div className="grid grid-cols-12 mt-6 gap-4">
                <div className="font-bold col-span-6">Bundle</div>
                <div className="font-bold col-span-5">
                  External {provider} Id
                </div>
                <div className="font-bold col-span-1"></div>
                {activeProducts &&
                  Object.values(activeProducts).length > 0 &&
                  Object.values(activeProducts).map((p) => (
                    <Fragment key={p.id}>
                      <div className="col-span-6">{p.name}</div>
                      <div className="col-span-5">
                        <TextInputField
                          id={`external_${p.id}`}
                          name={`external_${p.id}`}
                          type="text"
                          value={p.externalId || ''}
                          required
                          handleChange={handleExternalIdChange}
                        ></TextInputField>
                      </div>
                      <div className="col-span-1">
                        <TrashIcon
                          width={20}
                          className="cursor-pointer text-[--active-color] hover:text-[--active-hover-color] mt-2"
                          style={
                            theme
                              ? ({
                                  '--active-color': theme.linkColor,
                                  '--active-hover-color': theme.linkHoverColor,
                                } as React.CSSProperties)
                              : {}
                          }
                          onClick={() => removeItem(p.id)}
                        ></TrashIcon>
                      </div>
                    </Fragment>
                  ))}
                {validOptions.length > 0 && (
                  <Fragment>
                    <div className="col-span-6">
                      <SimpleSelectField
                        name={'tmp'}
                        options={validOptions}
                        firstItemText="Select bundle"
                        handleChange={testSelected}
                      ></SimpleSelectField>
                    </div>
                    <div className="col-span-5">
                      <TextInputField
                        id={`external_tmp`}
                        name={`external_tmp`}
                        type="text"
                      ></TextInputField>
                    </div>
                    <div className="col-span-1"></div>
                  </Fragment>
                )}
              </div>
            }
          </div>
        ),
      }}
      onClose={() => {
        setTimeout(() => resetForm(), 500)
        setShow(false)
      }}
      theme={theme || undefined}
      size="lg"
    ></Modal>
  )
}
