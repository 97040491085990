import { Fragment, useEffect, useState } from 'react'
import '../../App.css'
// import { authFetch } from '../../providers/AuthProvider'
import { useUser } from '../../lib/store'
import { Result } from '../../lib/validators'
import { PageHeading } from '../layout/PageHeading'
import { BasicTableGroupProps, BasicTableProps } from '../layout/BasicTable'
import { StatusTag } from '../layout/StatusTag'
import { Link } from 'react-router-dom'
import { resultUrl } from '../../lib/routes'
import PageSection from '../layout/PageSection'
import { getNiceDate, months, setDocumentTitle } from '../../lib/utils'
import Page from '../Page'
import { StatusTagColors } from '../../lib/interfaces'
import { Button } from '../form/button/Button'

import { EmptyState } from '../layout/EmptyState'
import TestTube from '../../../public/img/test-tube.svg'
import PageLoader from '../layout/PageLoader'
import { BuyTestButton } from '../form/button/BuyTestButton'
import { getResultsData } from '../../lib/results'

export default function ResultsPage() {
  const [results, setResults] = useState<Result[]>([])
  const [tableData, setTableData] = useState<BasicTableProps | undefined>()
  const [profile, setProfile] = useState('')
  const user = useUser()
  const [loading, setLoading] = useState(true)

  setDocumentTitle('Results')

  // if (!user?.profileUuid) {
  //   throw new Error("Missing Profile");
  // }

  useEffect(() => {
    // set the profile to be the first one
    if (user) {
      setProfile(user?.profileUuid)
    }
    if (profile) {
      getResultsData(profile).then((results) => {
        setResults(results)
        setLoading(false)
      })
    }
    // set default profile to be the first one

    // fetch the tests here
  }, [user, profile])

  useEffect(() => {
    // set the profile to be the first one
    // console.log("we are here, the tests should have updated", results);
    if (results) {
      massageResultsDataIntoTable(results)
    }
    // set default profile to be the first one

    // fetch the tests here
  }, [results])

  const massageResultsDataIntoTable = (results: Result[]) => {
    const result = []
    const resultsByYear: Record<string, BasicTableGroupProps> = {}
    // console.log("referrals", results);
    for (const i in results) {
      const d = new Date(results[i].resultDate)
      const year = d.getFullYear()

      // if (year !== currentYear) {
      if (!resultsByYear[year]) {
        resultsByYear[year] = {
          groupLabel: year.toString(),
          rows: [],
        }
      }

      resultsByYear[year].rows.push([
        results[i].name,
        `${getNiceDate(d.getDate())} ${
          months[d.getMonth()]
        } ${d.getFullYear()}`,
        <Fragment>
          {/* TSC being dumb, for some reason we have to bang the summary */}
          {results[i].biomarkerSummary &&
          results[i].biomarkerSummary!.total > 0 ? (
            <StatusTag
              color={StatusTagColors.JADE}
              label={`${results[i].biomarkerSummary?.normal} normal`}
              className="min-w-20 text-center"
              filled={true}
            ></StatusTag>
          ) : (
            ''
          )}
          {results[i].biomarkerSummary?.abnormal ? (
            <StatusTag
              color={StatusTagColors.MANDARIN}
              label={`${results[i].biomarkerSummary?.abnormal} abnormal`}
              filled={true}
            ></StatusTag>
          ) : (
            ''
          )}
        </Fragment>,
        <Link
          className="hover:text-bgt-primary"
          to={`${resultUrl
            .replace(':uuid', results[i].uuid!)
            .replace('/*', '')}`}
        >
          <Button
            color={results[i].status === 'COMPLETE' ? `jade` : 'mandarin'}
            type="button"
            label={
              results[i].status === 'COMPLETE'
                ? `View Results`
                : 'View Partial Results'
            }
          ></Button>
        </Link>,
      ])
    }

    const keys = Object.keys(resultsByYear)
    keys.sort().reverse()
    for (const y in keys) {
      result.push(resultsByYear[keys[y]])
    }
    if (result.length > 0) {
      setTableData({
        columns: ['Name', 'Results Date', 'Summary', ''],
        data: result,
      })
    }
  }

  // const columns = ["Name", "Reference", "Date Purchased", "Status"];
  // const data = [
  //   {
  //     groupLabel: "2024",
  //     rows: [
  //       [
  //         "Lindsay Walton With a really l ong name",
  //         "Front-end Developer",
  //         "lindsay.walton@example.com2",
  //         <StatusTag color="green" label="Active"></StatusTag>,
  //       ],
  //     ],
  //   },
  // ];

  const colClasses = [
    'text-left col-span-3 sm:col-span-4 border-b-1 border-gray-dark content-center pt-4  rounded-tl-lg rounded-bl-lg', //name
    'text-left text-left sm:text-center text-gray-semi-dark col-span-3 sm:col-span-2 content-center', //reference
    'text-left text-left sm:text-left text-gray-semi-dark col-span-3 sm:col-span-3 content-center', //purchase date
    'text-left sm:text-right text-gray-semi-dark col-span-3 sm:col-span-3 content-center pb-4 rounded-tr-lg rounded-br-lg', // status
    // "sm:text-right text-gray-semi-dark col-span-3 sm:col-span-3", // status
  ]

  return (
    <Page>
      {!loading && tableData && tableData.data && (
        <PageSection>
          <PageHeading title="Results"></PageHeading>

          <div
            key={`test-table`}
            className="grid grid-cols-3 sm:grid-cols-12 mt-2 sm:mt-10"
          >
            {/* <GridTableHeader className="col-span-3 sm:col-span-8">
                Pathology Report
              </GridTableHeader> */}
            <div
              className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50 font-semibold text-sm px-2 py-1 sm:p-3 sm:px-4 hidden sm:block ${colClasses[0]}`}
            >
              {tableData.columns[0]}
            </div>
            <div
              className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50 font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[1]}`}
            >
              {tableData.columns[1]}
            </div>
            <div
              className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50 font-semibold text-sm px-2 py-1 sm:p-3 hidden sm:block ${colClasses[2]}`}
            >
              {tableData.columns[2]}
            </div>
            <div
              className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50 font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[3]}`}
            >
              {tableData.columns[3]}
            </div>
            {tableData.data.map((group, g) => (
              <Fragment key={`group-fragment-${g}`}>
                {tableData.data.length > 1 && (
                  <div
                    key={`group-label-${g}`}
                    className={`py-1 sm:p-3 sm:px-4 col-span-3 sm:col-span-12 text-sm text-gray-semi-dark bg-gray-light dark:bg-dark-gray-light font-bold`}
                  >
                    {group.groupLabel}
                  </div>
                )}
                {group.rows.map((row, k) =>
                  row.map((item, i) => (
                    <div
                      key={`item-row-${k}-${i}`}
                      className={`py-1 sm:p-3 sm:px-4 col-span-1 sm:col-span-2 text-md text-black dark:text-white ${
                        k % 2 === 1
                          ? 'bg-gray-light dark:bg-dark-gray-light'
                          : ''
                      }  ${colClasses[i]} ${
                        i !== row.length - 1
                          ? ''
                          : 'last-row sm:border-none border-b border-gray'
                      } ${k === group.rows.length - 1 ? 'border-none' : ''}`}
                    >
                      {item}
                    </div>
                  ))
                )}
              </Fragment>
            ))}
          </div>
        </PageSection>
      )}
      {!loading && !tableData && (
        <EmptyState
          image={TestTube}
          title="Looking for Results?"
          secondTitle="They're Just a Heartbeat Away!"
          alt="Test Tube Image"
        >
          Seems like your tests are playing hide and seek. But don't worry,
          they'll show up soon, ready or not! In the meantime, why not take a
          peek at our other{' '}
          <a
            target="bgt-store"
            className="text-blood hover:underline"
            href={`${import.meta.env.VITE_SHOP_URL}/blood-tests`}
          >
            health checks
          </a>
          ? They’re waiting for you — no appointment needed!
          <div className="mt-8">
            <BuyTestButton></BuyTestButton>
          </div>
        </EmptyState>
      )}
      {loading && <PageLoader></PageLoader>}
    </Page>
  )
}
