import { Fragment, useEffect } from 'react'

import Page, { PageWithProps } from '../../Page'

import { useUser } from '../../../lib/store'
import { useNavigate } from 'react-router-dom'

export interface AdminPageWithProps extends PageWithProps {}

export default function AdminPage(props: AdminPageWithProps) {
  const user = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (user && user.isAdministrator === false) {
      navigate('/')
    }
  }, [user, navigate])

  return (
    <Page
      backgroundColor={props.backgroundColor}
      fullViewport={props.fullViewport}
      className={props.className}
    >
      <Fragment>{props.children}</Fragment>
    </Page>
  )
}
