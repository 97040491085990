import posthog from 'posthog-js'

export default {
  ...posthog,
  /**
   * Returns whether feature flag is enabled (will always return true in dev/staging)
   * @example
   * // returns true
   * isFeatureEnabled('foobar');
   * @example
   * // returns false
   * isFeatureEnabled('foobar2');
   * @returns {Boolean} Whether the flag is active or not
   */
  isFeatureEnabled(flag: string): boolean | undefined {
    if (import.meta.env.VITE_ENV === 'production') {
      return posthog.isFeatureEnabled(flag)
    }
    // just allow all flags in dev and staging
    return true
  },
}
