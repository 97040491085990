import { CheckIcon } from '@heroicons/react/20/solid'
import { classNames } from '../../lib/utils'

interface ProgressNavigationProps {
  steps: { name: string }[]
  currentStep: number
}

export default function ProgressNavigation({
  steps,
  currentStep,
}: ProgressNavigationProps) {
  return (
    <nav aria-label="Progress">
      <ol role="list" className="flex items-center">
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              stepIdx !== steps.length - 1 ? 'pr-8 sm:pr-20' : '',
              'relative'
            )}
          >
            {stepIdx < currentStep ? (
              <>
                <div
                  aria-hidden="true"
                  className="absolute inset-0 flex items-center"
                >
                  <div className="h-0.5 w-full bg-jade" />
                </div>
                <span
                  title={step.name}
                  className="relative flex h-8 w-8 items-center justify-center rounded-full bg-jade"
                >
                  <CheckIcon
                    aria-hidden="true"
                    className="h-5 w-5 text-white"
                  />
                  <span className="sr-only">{step.name}</span>
                </span>
              </>
            ) : stepIdx === currentStep ? (
              <>
                <div
                  aria-hidden="true"
                  className="absolute inset-0 flex items-center"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <span
                  title={step.name}
                  aria-current="step"
                  className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-jade bg-white transition ease-in-out delay-150 hover:scale-110"
                >
                  <span
                    aria-hidden="true"
                    className="h-2.5 w-2.5 rounded-full bg-jade"
                  />
                  <span className="sr-only">{step.name}</span>
                </span>
              </>
            ) : (
              <>
                <div
                  aria-hidden="true"
                  className="absolute inset-0 flex items-center"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <span className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400">
                  <span
                    title={step.name}
                    aria-hidden="true"
                    className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                  />
                  <span className="sr-only">{step.name}</span>
                </span>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}
