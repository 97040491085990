import { z } from "zod";

export const GraphQLResponseSchema = z.object({
  errors: z
    .array(
      z.object({
        message: z.string(),
        path: z.array(z.string()),
      })
    )
    .optional(),
  data: z.any(),
});

export type GraphQLResponse = z.infer<typeof GraphQLResponseSchema>;
