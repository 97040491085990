import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { PropsWithChildren } from 'react'

interface InfoSectionProps extends PropsWithChildren {
  className?: string
}

export function InfoSection({ children, className = '' }: InfoSectionProps) {
  const wrapperClassName =
    'text-sm mt-2 rounded-md p-2 relative grid grid-cols-8 align-middle text-left sm:items-start items-center border bg-gray-light dark:!bg-dark-gray-lighter border-gray-dark/20 shadow-md'

  return (
    <div className={`${wrapperClassName} ${className}`}>
      <div className="col-span-8 sm:col-span-1 center self-center text-center content-center sm:content-normal sm:self-start">
        <InformationCircleIcon
          className="text-jade sm:text-jade/90 inline-block"
          width={40}
        ></InformationCircleIcon>
      </div>
      <div className="col-span-8 sm:col-span-7 italic">{children}</div>
    </div>
  )
}
