import { authFetch } from '../providers/AuthProvider'
import {
  ProductCheckout,
  ProductCheckoutSchema,
  ProductCheckoutSession,
  ProductCheckoutSessionSchema,
} from './validators'

export const createProductCheckout = async (
  productId: string,
  returnUrl: string
): Promise<ProductCheckout> => {
  const result = await authFetch(
    `${import.meta.env.VITE_API_URL}/stripe/checkout`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify({
        productId,
        returnUrl,
      }),
    }
  )

  const parsedData = ProductCheckoutSchema.parse(result.data)
  return parsedData
}

export const getStripeCheckoutSession = async (
  sessionId: string
): Promise<ProductCheckoutSession> => {
  const result = await authFetch(
    `${import.meta.env.VITE_API_URL}/stripe/checkout/session`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify({
        sessionId,
      }),
    }
  )
  console.log('we got the session back', result.data)
  const parsedData = ProductCheckoutSessionSchema.parse(result.data)
  return parsedData
}
