import { Link } from 'react-router-dom'
import { ThemeInterface } from '../../../lib/interfaces'
import { buttonClasses } from './Button'

interface LinkButtonProps {
  url: string
  label: string
  target?: string
  internal?: boolean
  theme?: ThemeInterface
}
export function LinkButton({
  url,
  label,
  target,
  internal = true,
  theme,
}: LinkButtonProps) {
  if (internal) {
    return (
      <Link
        className={`${buttonClasses} ${
          theme
            ? ` !bg-[--button-color] hover:!bg-[--button-hover-color] !text-[--button-text-color]`
            : ''
        }`}
        style={
          theme
            ? ({
                '--button-color': theme.buttonColor,
                '--button-hover-color': `${theme.buttonHoverColor}`,
                '--button-text-color': `${theme.buttonTextColor}`,
              } as React.CSSProperties)
            : {}
        }
        to={url}
      >
        {label}
      </Link>
    )
  } else {
    return (
      <a
        className={`${buttonClasses} ${
          theme
            ? ` !bg-[--button-color] hover:!bg-[--button-hover-color] !text-[--button-text-color]`
            : ''
        }`}
        style={
          theme
            ? ({
                '--button-color': theme.buttonColor,
                '--button-hover-color': `${theme.buttonHoverColor}`,
                '--button-text-color': `${theme.buttonTextColor}`,
              } as React.CSSProperties)
            : {}
        }
        href={url}
        target={target ? target : '_self'}
      >
        {label}
      </a>
    )
  }
}
