import { FormEvent, useState, useEffect } from 'react'
import { Combobox } from '@headlessui/react'
import { useDebouncedCallback } from 'use-debounce'
import { setDocumentTitle } from '../../../lib/utils'
import Checkbox from '../../form/Checkbox'
import TextInputField, { onChangeInterface } from '../../form/TextInputField'
import { PageHeading } from '../../layout/PageHeading'
import PageSection from '../../layout/PageSection'
import AdminPage from './AdminPage'
import { UserSlim } from '../../../lib/validators'
import {
  getReferralsForTransfer,
  ReferralForTransfer,
  searchUsers,
  transferReferrals,
  validTransferStatuses,
} from '../../../lib/admin'
import toast from 'react-hot-toast'
import { StatusTag } from '../../layout/StatusTag'
import { StatusTagColors } from '../../../lib/interfaces'
import { InfoSection } from '../../ui/InfoSection'

function TransferReferralsSection() {
  const [userSearch, setUserSearch] = useState<string>('')
  const [users, setUsers] = useState<Array<UserSlim>>([])
  const [selectedUser, setSelectedUser] = useState<UserSlim | null>(null)
  const [selectedReferrals, setSelectedReferrals] = useState<string[]>([])
  const [recipientEmail, setRecipientEmail] = useState<string>('')
  const [recipientFirstName, setRecipientFirstName] = useState<string>('')
  const [referrals, setReferrals] = useState<ReferralForTransfer[]>([])

  const setUserSearchDebounced = useDebouncedCallback((searchTerm: string) => {
    setUserSearch(searchTerm)
  }, 500)

  useEffect(() => {
    async function fetchSearchUsers() {
      console.log('searchUsers with', userSearch)
      const users = await searchUsers(userSearch)
      setUsers(users)
    }
    fetchSearchUsers()
  }, [userSearch])

  useEffect(() => {
    async function fetchReferrals(user: UserSlim) {
      console.log('fetch referrals for user', user)
      const referrals = await getReferralsForTransfer(
        user.uuid,
        validTransferStatuses
      )
      console.log('got referrals', referrals)
      setReferrals(referrals)
    }

    setSelectedReferrals([])

    if (selectedUser) {
      fetchReferrals(selectedUser)
    } else {
      setReferrals([])
    }
  }, [selectedUser])

  const handleReferralCheckboxChange = (referralId: string) => {
    setSelectedReferrals(
      (prev) =>
        prev.includes(referralId)
          ? prev.filter((id) => id !== referralId) // Remove if already selected
          : [...prev, referralId] // Add if not selected
    )
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    console.log('Transferring Referral', {
      selectedUser,
      selectedReferrals,
      recipientEmail,
      recipientFirstName,
    })
    try {
      await transferReferrals({
        recipientFirstName,
        recipientEmail,
        referrals: selectedReferrals,
        fromUserUuid: selectedUser!.uuid,
      })
      toast.success('Transferred referrals')
      clearForm()
    } catch (error) {
      toast.error('Failed to transfer referrals')
    }
  }

  const clearForm = () => {
    setUserSearch('')
    setSelectedUser(null)
    setUsers([])
    setSelectedReferrals([])
    setRecipientEmail('')
    setRecipientFirstName('')
    setReferrals([])
  }

  return (
    <>
      <h2 className="text-lg font-semibold text-black/70 dark:text-white mb-2 select-none">
        Transfer Referral
      </h2>
      <InfoSection>
        This tool allows you to transfer referrals to a new recipient for
        referrals of status {validTransferStatuses.toString()}. Referrals can be
        transferred in bulk for a single organisation at a time i.e. the
        selected referrals Organisation UUID must be the same or none for
        selected referrals.
      </InfoSection>
      <form className="max-w-5xl space-y-4" onSubmit={handleSubmit}>
        {/* User Search Combobox */}
        <div className="pb-5">
          <label className="block text-sm font-medium text-gray-700">
            User Search
          </label>
          <Combobox value={selectedUser} onChange={setSelectedUser}>
            <div className="relative mt-1">
              <Combobox.Input
                className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm leading-5 shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500"
                onChange={(event) => {
                  setUserSearchDebounced(event.target.value)
                  setSelectedUser(selectedUser)
                  setReferrals([])
                }}
                displayValue={(user: UserSlim | null) =>
                  user
                    ? `${user.firstName} ${user.lastName} | ${user.email}`
                    : ''
                }
                placeholder="Search for users..."
              />
              <Combobox.Options className="absolute w-full mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm z-50">
                {users.length === 0 && userSearch !== '' ? (
                  <div className="cursor-default select-none py-2 px-4 text-gray-700">
                    No users found.
                  </div>
                ) : (
                  users.map((user) => (
                    <Combobox.Option
                      key={user.uuid}
                      value={user}
                      className={({ active }) =>
                        `cursor-default select-none relative py-2 pl-10 pr-4 ${
                          active ? 'text-white bg-indigo-600' : 'text-gray-900'
                        }`
                      }
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {user.firstName} {user.lastName} | {user.email}
                          </span>
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </div>
          </Combobox>
        </div>

        {/* Multi-Select Referrals with Checkboxes */}
        <div className="pb-5">
          <label className="block text-sm font-medium text-gray-700">
            Select Referrals (Reference | Test Name | Status | Customer Name |
            Organisation UUID)
          </label>
          <div className="mt-1 flex flex-col gap-4">
            {referrals.map((referral) => (
              <div key={referral.uuid} className="inline-flex items-center">
                <Checkbox
                  id={referral.uuid}
                  name="selectedReferrals"
                  type="checkbox"
                  checked={selectedReferrals.includes(referral.uuid)}
                  onChange={() => handleReferralCheckboxChange(referral.uuid)}
                  checkboxLabel={
                    <span>
                      {referral.reference} | {referral.testName} |{' '}
                      <StatusTag
                        color={StatusTagColors.JADE}
                        label={referral.status}
                      />{' '}
                      | {referral.customerName} |{' '}
                      {referral.organisationUuid ?? ''}
                    </span>
                  }
                />
              </div>
            ))}
          </div>
        </div>

        {/* Recipient First Name */}
        <div className="pb-5">
          <TextInputField
            id={'recipientFirstName'}
            label="Recipient First Name"
            name="recipientFirstName"
            required={true}
            type={'text'}
            value={recipientFirstName}
            handleChange={(data: onChangeInterface) =>
              setRecipientFirstName(data.value.toString())
            }
            placeholder="Enter recipient's first name..."
          />
        </div>

        {/* Recipient Email Address */}
        <div className="pb-5">
          <TextInputField
            id="recipientEmail"
            label="Recipient Email"
            name="recipientEmail"
            required={true}
            type="email"
            value={recipientEmail}
            handleChange={(data: onChangeInterface) =>
              setRecipientEmail(data.value.toString())
            }
            placeholder="Enter recipient email address..."
          />
        </div>

        {/* Submit and Clear Buttons */}
        <div className="flex space-x-4 pt-5">
          <button
            type="button"
            onClick={clearForm}
            className="flex-1 px-4 py-2 font-semibold text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300"
          >
            Reset
          </button>
          <button
            type="submit"
            className="flex-1 px-4 py-2 font-semibold text-white bg-jade rounded-md hover:bg-jade/80 disabled:!cursor-default disabled:bg-gray-dark disabled:!text-gray-700"
            disabled={
              !selectedUser ||
              selectedReferrals.length === 0 ||
              !recipientFirstName ||
              !recipientEmail
            }
          >
            Transfer Referral
          </button>
        </div>
      </form>
    </>
  )
}

export default function AdminReferralsPage() {
  setDocumentTitle('Referrals Admin', 'CLEAN')

  return (
    <AdminPage>
      <PageSection>
        <PageHeading title="Referrals Admin" />
        <div>
          <TransferReferralsSection />
        </div>
      </PageSection>
    </AdminPage>
  )
}
