import { authFetch } from '../providers/AuthProvider'
import { Profile, ProfileSchema } from './validators'
export async function getProfileData(profileUuid: string): Promise<Profile> {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          profile(uuid: "${profileUuid}") {
            uuid
            dob
            gender
            edm
            phone
            address {
              addressLine1
              addressLine2
              city
              state
              postCode
              country
            }
          }
        }`,
    }),
  })
  const data = result.data
  const profileData = ProfileSchema.parse(data.data.profile)
  return profileData
}
