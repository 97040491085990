import toast from 'react-hot-toast'
import { z } from 'zod'
import { GraphQLResponseSchema } from '../lib/errors'
import { authFetch } from '../providers/AuthProvider'

export const SentUserEmailVerificationSchema = z.object({
  success: z.boolean(),
  message: z.string(),
})

export const resendVerificationEmail = async () => {
  const response = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
            mutation {
              sendUserEmailVerification {
                success
                message
              }
            }
          `,
    }),
  })
  const result = response.data
  try {
    const envelope = GraphQLResponseSchema.parse(result)
    if (envelope.errors && envelope.errors.length > 0) {
      throw new Error(envelope.errors[0].message)
    }
    const sentUserVerificationEmail = SentUserEmailVerificationSchema.parse(
      envelope.data?.sendUserEmailVerification
    )
    if (sentUserVerificationEmail.success) {
      toast.success('A verification email has been sent to your email address!')
      return
    }

    toast.error('Unable to send verification email to your email address.')
  } catch (error) {
    toast.error('Unable to send verification email to your email address.')
  }
}
