import { authFetch } from '../providers/AuthProvider'
import { Referral, ReferralListApiSchema } from './validators'

export const getTestsData = async (
  profileUuid: string,
  organisationUuid?: string
): Promise<Referral[]> => {
  // setTimeout(async () => {

  // console.log("we have a profile", profile);
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          referrals(profileUuid: "${profileUuid}" ${organisationUuid ? `organisationUuid: "${organisationUuid}"` : ''}) {
            uuid
            status
            saleUuid
            createdAt
            reference
            testName
            resultUuid
            fileUuids
            customerName
            result {
              uuid
              status
            }
          }}`,
    }),
  })
  const data = result.data
  const parsedData = ReferralListApiSchema.parse(data)

  return parsedData.data.referrals
}
