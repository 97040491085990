import "../../../App.css";
import Page from "../../Page";
// import { PageHeading } from "../../layout/PageHeading";
// import PageSection from "../../layout/PageSection";
import PageSection from "../../layout/PageSection";
import { Elements } from "@stripe/react-stripe-js";
import OrganisationCreateForm from "./OrganisationCreateForm";
import { stripeOptions, stripePromise } from "../../../lib/billing";

export default function OrganisationCreatePage() {
  return (
    <Page>
      <PageSection>
        <Elements stripe={stripePromise} options={stripeOptions}>
          <OrganisationCreateForm></OrganisationCreateForm>
        </Elements>
      </PageSection>
    </Page>
  );
}
