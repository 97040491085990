import { Fragment, useEffect, useState } from 'react'

import { SettingsTabProps } from '../Settings'
import {
  OrganisationWithBranding,
  useOrganisationContext,
} from '../../../../context/OrganisationContext'
// import { useCurrentBilling } from '../../../../../lib/store'
import { getInvoices } from '../../../../../lib/billing'
import { BillingInvoice } from '../../../../../lib/validators'
import {
  BasicTableGroupProps,
  BasicTableProps,
} from '../../../../layout/BasicTable'
import { formatMoney, getNiceDate, months } from '../../../../../lib/utils'
import { StatusTag } from '../../../../layout/StatusTag'
import { StatusTagColors } from '../../../../../lib/interfaces'
import { useCurrentBilling } from '../../../../../lib/store'
export const SettingsTabBillingInvoices: React.FC<SettingsTabProps> = () => {
  const { org } = useOrganisationContext()
  const billing = useCurrentBilling()
  const [loading, setLoading] = useState(true)
  const [invoices, setInvoices] = useState<BillingInvoice[]>([])
  const [tableData, setTableData] = useState<BasicTableProps | undefined>()

  useEffect(() => {
    async function getOrganisationInvoices(
      organisation: OrganisationWithBranding
    ) {
      console.log('lets load the invoices...')
      const invs = await getInvoices(organisation)
      console.log('LOADED THEM ===>', invs)
      setInvoices(invs)
      setLoading(false)
    }
    console.log('do we have billing and org?', billing, org)
    if (billing && org) {
      console.log('we do!, lets grab the invoices..')
      getOrganisationInvoices(org)
    } else {
      setLoading(false)
    }
  }, [billing, org])

  useEffect(() => {
    // set the profile to be the first one
    // console.log("we are here, the tests should have updated", tests);
    if (invoices) {
      console.log('invoices?', invoices)
      massageTestDataIntoTable(invoices)
    }
    // set default profile to be the first one

    // fetch the tests here
  }, [invoices])

  const massageTestDataIntoTable = (invoices: BillingInvoice[]) => {
    const result = []
    const resultsByYear: Record<string, BasicTableGroupProps> = {}
    // console.log("referrals", referrals);
    for (const i in invoices) {
      const d = new Date(invoices[i].periodEnd! * 1000)
      console.log(d)
      const year = d.getFullYear()

      // if (year !== currentYear) {
      if (!resultsByYear[year]) {
        resultsByYear[year] = {
          groupLabel: year.toString(),
          rows: [],
        }
      }
      // console.log("files", referrals[i].fileUuids);

      const invoice = invoices[i]
      resultsByYear[year].rows.push([
        `${invoice && invoice.number ? invoice.number?.split('-')[1] : '-'}`,
        `${getNiceDate(d.getDate())} ${
          months[d.getMonth()]
        } ${d.getFullYear()}`,
        formatMoney(invoice.total / 100, invoice.currency || 'aud'),
        <StatusTag
          label={invoice.status}
          color={invoice.paid ? StatusTagColors.JADE : StatusTagColors.YELLOW}
        ></StatusTag>,
        invoice.invoicePdfUrl ? (
          <div>
            <a
              href={invoice.invoicePdfUrl!}
              target="_blank"
              className="cursor-pointer text-blood hover:underline"
            >
              download pdf
            </a>
          </div>
        ) : (
          ''
        ),
      ])
    }
    const keys = Object.keys(resultsByYear)
    keys.sort().reverse()
    for (const y in keys) {
      result.push(resultsByYear[keys[y]])
    }
    console.log('the results are', result)
    if (result.length > 0) {
      setTableData({
        columns: ['Invoice Number', 'Date', 'Total', 'Status', 'Invoice'],
        data: result,
      })
    }
  }

  const colClasses = [
    'text-left border-b-1 border-gray-dark content-center pt-4 col-span-3 sm:col-span-3', //name
    'text-left text-left sm:text-center text-gray-semi-dark col-span-3 sm:col-span-2 content-center', //reference
    'text-left text-left sm:text-center text-gray-semi-dark col-span-3 sm:col-span-2 content-center', //purchase date
    'text-left text-left sm:text-center text-gray-semi-dark col-span-3 sm:col-span-2 content-center', //purchase date
    'text-left sm:text-right text-gray-semi-dark col-span-3 sm:col-span-3 content-center pb-4', // status
    // "sm:text-right text-gray-semi-dark col-span-3 sm:col-span-3", // status
  ]

  return (
    <Fragment>
      {loading && <div>loading</div>}
      {!loading && (
        <Fragment>
          {tableData && tableData.data && (
            <div
              key={`test-table`}
              className="grid grid-cols-3 sm:grid-cols-12 mt-10"
            >
              {/* <GridTableHeader className="col-span-3 sm:col-span-8">
                Pathology Report
              </GridTableHeader> */}
              <div
                className={`bg-gray-light text-gray-semi-dark border-b border-gray-medium font-semibold text-sm px-2 py-1 sm:p-3 sm:px-4 hidden sm:block ${colClasses[0]}`}
              >
                {tableData.columns[0]}
              </div>
              <div
                className={`bg-gray-light text-gray-semi-dark border-b border-gray-medium font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[1]}`}
              >
                {tableData.columns[1]}
              </div>
              <div
                className={`bg-gray-light text-gray-semi-dark border-b border-gray-medium font-semibold text-sm px-2 py-1 sm:p-3 hidden sm:block ${colClasses[2]}`}
              >
                {tableData.columns[2]}
              </div>
              <div
                className={`bg-gray-light text-gray-semi-dark border-b border-gray-medium font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[3]}`}
              >
                {tableData.columns[3]}
              </div>
              <div
                className={`bg-gray-light text-gray-semi-dark border-b border-gray-medium font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[4]}`}
              >
                {tableData.columns[4]}
              </div>

              {tableData.data.map((group, g) => (
                <Fragment key={`group-fragment-${g}`}>
                  {tableData.data.length > 1 && (
                    <div
                      key={`group-label-${g}`}
                      className={`px-2 py-1 sm:p-3 sm:px-4 col-span-3 sm:col-span-12 text-sm text-gray-semi-dark bg-gray-light font-bold`}
                    >
                      {group.groupLabel}
                    </div>
                  )}
                  {group.rows.map((row, k) =>
                    row.map((item, i) => (
                      <div
                        key={`item-row-${k}-${i}`}
                        className={`py-1 sm:p-3 sm:px-4 col-span-1 sm:col-span-2 text-md text-black ${
                          k % 2 === 1 ? 'bg-gray-light' : ''
                        } rounded-tl-lg rounded-bl-lg ${colClasses[i]} ${
                          i !== row.length - 1
                            ? ''
                            : 'last-row sm:border-none border-b border-gray !rounded-none'
                        } ${k === group.rows.length - 1 ? 'border-none' : ''}`}
                      >
                        <div className="inline-block align-middle">{item}</div>
                      </div>
                    ))
                  )}
                </Fragment>
              ))}
            </div>
          )}
          {!tableData && <div>No invoices found</div>}
        </Fragment>
      )}
    </Fragment>
  )
}
