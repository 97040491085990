import { HomeIcon } from '@heroicons/react/20/solid'
import { OrganisationWithBranding } from '../context/OrganisationContext'
import { getOrgUrl } from '../../lib/routes'
import { Link } from 'react-router-dom'
import { ThemeInterface } from '../../lib/interfaces'
import { useEffect, useState } from 'react'

export interface TabbarItem {
  name: string
  href?: string
}

export interface TabbarInterface {
  home: TabbarItem
  tabs?: TabbarItem[]
  theme?: ThemeInterface
  className?: string
}

export interface OrgTabbarInterface {
  org: OrganisationWithBranding
  tabs?: TabbarItem[]
  theme?: ThemeInterface
}

export default function Tabbar({ tabs, home, className }: TabbarInterface) {
  const [pageTabs, setPageTabs] = useState<TabbarItem[]>()

  useEffect(() => {
    if (!tabs || tabs.length === 0) {
      setPageTabs([
        {
          name: 'Home',
        },
      ])
    } else {
      setPageTabs(tabs)
    }
  }, [tabs])

  return (
    <nav
      aria-label="Breadcrumb"
      className={`flex border-b border-gray-200 dark:border-dark-gray-light bg-white dark:!bg-dark-gray-lighter mb-2 rounded-lg mt-2 shadow-none ${className || ''}`}
    >
      <ol
        role="list"
        className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8 overflow-auto"
      >
        <li className="flex">
          <div className="flex items-center">
            <Link
              to={home.href || ''}
              className="text-gray-400 dark:text-white hover:text-gray-500"
            >
              <HomeIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0" />
              <span className="sr-only">{home.name}</span>
            </Link>
          </div>
        </li>
        {pageTabs &&
          pageTabs.map((tab, idx) => (
            <li key={tab.name} className="flex">
              <div className="flex items-center">
                <svg
                  fill="currentColor"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  aria-hidden="true"
                  className="h-full w-6 flex-shrink-0 text-gray-200 dark:text-gray-semi-dark"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                {tab.href && idx !== pageTabs.length - 1 ? (
                  <Link
                    to={tab.href}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-white"
                  >
                    {tab.name}
                  </Link>
                ) : (
                  <span className="ml-4 text-sm font-medium text-gray-500 dark:text-white">
                    {tab.name}
                  </span>
                )}
              </div>
            </li>
          ))}
      </ol>
    </nav>
  )
}

export function OrgTabbar({ tabs, org, theme }: OrgTabbarInterface) {
  return (
    <Tabbar
      theme={theme}
      home={{
        name: org.name,
        href: getOrgUrl(org.id),
      }}
      tabs={tabs}
      className=""
    ></Tabbar>
  )
}
