import { Fragment, PropsWithChildren } from 'react'
import { defaultInputClasses, onChangeInterface } from './TextInputField'

interface TextareaProps extends PropsWithChildren {
  disabled?: boolean
  label?: string
  name: string
  id: string
  value?: string
  handleChange?: (data: onChangeInterface) => void
  required?: boolean
}

export function Textarea({
  label,
  disabled = false,
  name,
  id,
  value,
  required = false,
  handleChange,
}: TextareaProps) {
  const handleValueChange = (e: React.FormEvent<HTMLTextAreaElement>): void => {
    const element = e.target as HTMLTextAreaElement
    const itemValue: string = element.value
    handleChange &&
      handleChange({
        name: element.name,
        value: itemValue,
        strValue: itemValue,
      })
  }

  return (
    <Fragment>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-6 text-gray-dark"
        >
          {label}
        </label>
      )}
      <textarea
        rows={4}
        name={name}
        id={id}
        required={required}
        disabled={disabled}
        className={defaultInputClasses}
        value={value}
        onChange={(e) => handleValueChange && handleValueChange(e)}
      ></textarea>
    </Fragment>
  )
}
