import { Fragment, useEffect, useState } from 'react'

import PageLoader from '../../../layout/PageLoader'
import PageSection from '../../../layout/PageSection'
import { useOrganisationContext } from '../../../context/OrganisationContext'
import { getProductsForOrganisation } from '../../../../lib/organisation'
import { ProductWithTestsAndBiomarkersSelected } from '../../../../lib/validators'
import { PageHeading } from '../../../layout/PageHeading'
import { Link } from 'react-router-dom'
import { getBundleUrl, getCreateTestBundleUrl } from '../../../../lib/routes'
import { getPrettyDate, setDocumentTitle } from '../../../../lib/utils'
import OrgPage from '../../../OrgPage'
import { LinkButton } from '../../../form/button/LinkButton'
import { ShareIcon } from '@heroicons/react/24/solid'
import { SendPackageModal } from './modals/SendPackageModal'
import { StatusTag } from '../../../layout/StatusTag'
import { StatusTagColors } from '../../../../lib/interfaces'
import posthog from '../../../../lib/posthog'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import CopyToClipboard from 'react-copy-to-clipboard'
import toast from 'react-hot-toast'
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline'

interface BasicProduct {
  name: string
  id: string
}

export function OrgTestBundles() {
  const [loading, setLoading] = useState(false)
  const { org, theme } = useOrganisationContext()
  const [product, setProduct] = useState<BasicProduct>()
  const [openInviteModal, setOpenInviteModal] = useState(false)
  const [packages, setPackages] = useState<
    ProductWithTestsAndBiomarkersSelected[] | null
  >(null)

  setDocumentTitle(`Test Bundles  | ${org && org?.name}`, 'BRANDED')

  useEffect(() => {
    if (org && !loading && packages === null) {
      setLoading(true)
      getProductsForOrganisation(org.uuid).then((products) => {
        setPackages(products)
        setLoading(false)
      })
    }
  }, [org, packages, loading])

  const getBundleURL = (bundleId: string): string => {
    return `${import.meta.env.VITE_APP_URL}/buy/${bundleId}`
  }
  return (
    <Fragment>
      {org && (
        <OrgPage
          org={org}
          tabs={[
            {
              name: 'Test Bundles',
            },
          ]}
          className="pb-20"
        >
          {loading && <PageLoader theme={theme || undefined}></PageLoader>}
          {!loading && org && (
            <PageSection>
              <PageHeading
                title={`${org?.name} Test Bundles`}
                action={
                  <Fragment>
                    {posthog.isFeatureEnabled('can-create-org-test') && (
                      <LinkButton
                        url={getCreateTestBundleUrl(org.id)}
                        label="Create Test Bundle"
                        internal={true}
                        theme={theme || undefined}
                      ></LinkButton>
                    )}
                  </Fragment>
                }
              ></PageHeading>
              {!packages ||
                (packages.length === 0 && <div>No test bundles created.</div>)}
              {packages && packages.length > 0 && (
                <div className="grid grid-cols-12">
                  {packages.map((pack) => (
                    <Fragment key={`${pack.id}-pack`}>
                      <div className="col-span-4 font-bold content-center">
                        <Link to={getBundleUrl(org?.id, pack.id)}>
                          {pack.name}
                        </Link>
                      </div>
                      <div className="col-span-4 text-gray-semi-dark content-center">
                        {pack.description}
                      </div>
                      <div className="col-span-1 text-gray-semi-dark content-center">
                        {!pack.internal && (
                          <StatusTag
                            color={StatusTagColors.ORANGE}
                            label="Template"
                          ></StatusTag>
                        )}
                      </div>
                      <div className="col-span-2 content-center text-center">
                        {getPrettyDate(pack.createdAt)}
                        {/* <p
                        title={pack.tests?.map((t) => t.name).join('\n')}
                        className="truncate text-sm"
                      >
                        {pack.tests?.map((t) => t.shortName).join(', ')}
                      </p> */}
                      </div>
                      <div className="col-span-1 text-right">
                        <span data-tooltip-id="share-test-tooltip">
                          <ShareIcon
                            className="inline-block cursor-pointer"
                            width={20}
                            onClick={() => {
                              setProduct({
                                id: pack.id,
                                name: pack.name,
                              })
                              setOpenInviteModal(true)
                            }}
                          />
                          <Tooltip
                            id="share-test-tooltip"
                            place="top"
                            content="Send test to someone"
                            className="rounded-lg"
                          />
                        </span>
                        <span data-tooltip-id="copy-test-url-tooltip">
                          <CopyToClipboard
                            text={getBundleURL(pack.id)}
                            onCopy={() =>
                              toast.success(`${pack.name} URL copied`, {
                                duration: 3000,
                              })
                            }
                          >
                            <ClipboardDocumentListIcon
                              width={20}
                              className="inline cursor-pointer"
                            ></ClipboardDocumentListIcon>
                          </CopyToClipboard>
                          <Tooltip
                            id="copy-test-url-tooltip"
                            place="top"
                            content="Copy Bundle URL"
                          />
                        </span>
                      </div>
                    </Fragment>
                  ))}
                </div>
              )}
            </PageSection>
          )}
          <SendPackageModal
            org={org}
            productId={product?.id}
            productName={product?.name}
            theme={theme || undefined}
            show={openInviteModal}
            setShow={setOpenInviteModal}
            dismissOnSkip={true}
            dismissOnSend={true}
          ></SendPackageModal>
        </OrgPage>
      )}
    </Fragment>
  )
}
