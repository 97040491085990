import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { FunctionComponent } from 'react'

import { XMarkIcon } from '@heroicons/react/24/outline'
import BgtBloodDropSmall from '../../../public/img/bgt-drop-small.png?url'

import { IMobileSidebarProps } from '../../lib/interfaces'
import { Link } from 'react-router-dom'
import { useOrganisationContext } from '../context/OrganisationContext'

import { MainMenuItem } from '../menu/MainMenuItem'
import { PoweredBy } from '../organisation/PoweredBy'

const MobileSidebar: FunctionComponent<IMobileSidebarProps> = ({
  // teams,
  setSidebarOpen,
  sidebarOpen,
  menuItemGroups,
  theme,
  urlPrefix,
}) => {
  const { org } = useOrganisationContext()

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 lg:hidden"
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button
                    type="button"
                    className="-m-2.5 p-2.5"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-off-white p-4 dark:bg-dark-gray-light dark:shadow-xl dark:border-0">
                <Link
                  to={urlPrefix}
                  className="text-center center justify-center flex"
                >
                  <img
                    className="center max-h-12 max-w-12"
                    src={
                      theme && theme.logoSquare
                        ? theme.logoSquare
                        : BgtBloodDropSmall
                    }
                    alt={`${org?.name} Logo`}
                  />
                </Link>

                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    {menuItemGroups.map((group, idx) => (
                      <li key={`menu-item-mobile-${idx}`}>
                        {group.items.length > 0 && group.header && (
                          <div className="text-xs font-semibold leading-6 text-gray-semi-dark uppercase">
                            {group.header}
                          </div>
                        )}
                        <ul role="list" className="-mx-2 space-y-1">
                          {group.items.map((item) => (
                            <MainMenuItem
                              item={item}
                              theme={theme}
                              identifier="mobile"
                              key={`mobile-${item.name}`}
                            ></MainMenuItem>
                          ))}
                        </ul>
                      </li>
                    ))}

                    {org && org.id && <PoweredBy></PoweredBy>}
                  </ul>
                </nav>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default MobileSidebar
