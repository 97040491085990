import { useNavigate } from 'react-router-dom'
import { useOnceCall } from '../lib/hooks'
import AuthStore from '../lib/AuthStore'
import { Fragment, PropsWithChildren, useEffect, useState } from 'react'
import {
  actions,
  useCurrentPage,
  useEmailVerified,
  useMissingProfileData,
} from '../lib/store'
import posthog from 'posthog-js'
import * as Sentry from '@sentry/react'
// import ReactGA from "react-ga4";

import { useLocation } from 'react-router-dom'
import { findMatchingRoute, orgHomePrefix } from '../lib/routes'
import { Toaster } from 'react-hot-toast'
import FullPageLoader from './layout/FullPageLoader'
import { refreshCurrentUser } from '../lib/utils'
import { useOrganisationContext } from './context/OrganisationContext'
import { useIntercom } from 'react-use-intercom'
import ReactGA from 'react-ga4'

interface FaviconWrapperProps extends PropsWithChildren {
  icon?: string | null
}

function FaviconWrapper({ children, icon }: FaviconWrapperProps) {
  useEffect(() => {
    const newIcon = icon || '/img/tight-favicon.png'
    let link: HTMLLinkElement | null =
      document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'

      document.getElementsByTagName('head')[0].appendChild(link)
    }
    link.type = 'image/png'
    link.href = newIcon
  }, [icon])
  return <Fragment>{children}</Fragment>
}

export interface AppWrapperProps extends PropsWithChildren {
  excludedAuthUrls?: string[]
}

export default function AppWrapper(props: AppWrapperProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)
  const missingProfileData = useMissingProfileData()
  const emailVerified = useEmailVerified()
  const currentPage = useCurrentPage()
  const { org, orgs } = useOrganisationContext()
  const [shouldRedirectOnOrgUpdate, setShouldRedirectOnOrgUpdate] =
    useState(false)
  const intercom = useIntercom()

  // only turn on post hog in production
  if (import.meta.env.VITE_ENV === 'production') {
    posthog.init('phc_e3J0egpSSF7ZWWnWjK5kxCL47kHV1cfzK5oolPXSSIS', {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
      session_recording: {
        maskAllInputs: true,
        maskTextSelector: '*',
      },
      enable_heatmaps: true,
    })
  }

  // console.log("add some excluded routes here", props.excludedAuthUrls);

  // redirect if we dont own this org.
  useEffect(() => {
    if (
      orgs !== null &&
      orgs.length > 0 &&
      org === null &&
      shouldRedirectOnOrgUpdate
    ) {
      setShouldRedirectOnOrgUpdate(false)
      navigate('/')
    }
    // if we have the orgs, and a valid org object, redirect to it.
    else if (
      orgs !== null &&
      orgs.length > 0 &&
      org !== null &&
      shouldRedirectOnOrgUpdate
    ) {
      if (
        window.location.pathname.indexOf(`${orgHomePrefix}/${org.id}`) !== 0
      ) {
        navigate(`${orgHomePrefix}/${org.id}`)
      }
      setShouldRedirectOnOrgUpdate(false)
    }
    // console.log("()()()()() ==> We need to send the user to the right org ");

    // navigate(`${orgHomePrefix}/${org?.id}`);
  }, [org, orgs, shouldRedirectOnOrgUpdate, navigate])

  useOnceCall(async () => {
    // if we need to exclude some routes from the auth stuff
    if (props.excludedAuthUrls) {
      AuthStore.addExcludedPaths(props.excludedAuthUrls)
    }

    AuthStore.setOnInitCallback(async (isLoggedIn: boolean) => {
      // console.log("init callback, is user logged in? ", isLoggedIn);
      setLoggedIn(isLoggedIn)
    })

    AuthStore.initAuthState()

    AuthStore.setOnRedirectCallback(() => {
      // grab the user object
      // at this point we should check if the users profile is complete
      navigate('/verify', {})
    })
  })

  // useEffect(() => {
  //   console.log(
  //     "ORG CHANGED+++++++====++++++++",
  //     window.location.pathname,
  //     org
  //     // urlParts
  //   );
  //   const urlParts = window.location.pathname.split("/");
  //   urlParts.shift();
  //   // handle redirect if the user has an org saved but isnt loading an org url
  //   if (org && `/${urlParts[0]}` !== orgHomePrefix) {
  //     console.log("+++++++====++++++++ REDIRECT");
  //     navigate(`${orgHomePrefix}/${org.id}`);
  //     return;
  //   }
  // }, [org]);

  useEffect(() => {
    // const route = routes[window.location.pathname];
    // just force everyone who hasn't completed their profle to the verify page.

    // if we have an org populated, we make sure the url begins with the org route
    // if (
    //   org &&
    //   window.location.pathname.indexOf(`${orgHomePrefix}/${org.id}`) !== 0
    // ) {
    //   navigate(`${orgHomePrefix}/${org.id}`);
    //   return;
    // }

    const orgUrlMatches = window.location.pathname.match(
      /^(?:\/org\/)([\w\d-]+)\/?(?:.*)$/
    )

    if (!org && orgUrlMatches?.length == 2) {
      // actions.setCurrentOrganisation(orgUrlMatches[1]);
      actions.setCurrentOrganisation(orgUrlMatches[1])
      setShouldRedirectOnOrgUpdate(true)
    }
    // if we are moving to a different org.. update the current org
    else if (
      org &&
      orgUrlMatches?.length === 2 &&
      orgUrlMatches[1] !== org.id
    ) {
      actions.setCurrentOrganisation(orgUrlMatches[1])
    } else if (!org) {
      actions.setCurrentOrganisation(null)
    }

    const route = findMatchingRoute(window.location.pathname)
    if (!route) {
      console.warn(`No route configured for ${window.location.href}`)
      return
    }
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth",
    // });
    // console.log("new current page is", route.name);
    if (currentPage !== route.name) {
      // console.log("set the page to be the new route", route.name);
      actions.setCurrentPage(route.name)
      // fire the GA event
      if (currentPage) {
        // console.log("calling ga-send");
        ReactGA.send({
          hitType: 'pageview',
          page: window.location.pathname,
          title: document.title,
        })
      }
    }

    // console.log("route name", route.name);

    if (
      missingProfileData &&
      missingProfileData.length > 0 &&
      route.name !== 'verify'
    ) {
      navigate('/verify')
      return
    }

    // if (
    //   props.excludedAuthUrls &&
    //   props.excludedAuthUrls.indexOf(window.location.pathname) !== -1
    // ) {
    //   setLoading(false)
    // }
    setLoading(false)
  }, [
    location,
    missingProfileData,
    emailVerified,
    currentPage,
    navigate,
    org,
    props.excludedAuthUrls,
  ])

  useEffect(() => {
    async function fetchLoggedInUser() {
      if (loggedIn) {
        const user = await refreshCurrentUser()

        console.log('UPDATE THE USER INFO')
        // update GA
        ReactGA.gtag('set', 'user_properties', {
          account_type: user.organisations.length > 0 ? 'practitioner' : 'user',
        })
        ReactGA.gtag('set', 'user_id', user.uuid)

        if (intercom) {
          intercom.update({
            avatar: user.image
              ? {
                  type: 'google',
                  imageUrl: user.image || undefined,
                }
              : undefined,
            email: user.email,
            name: [user.firstName, user.lastName].join(' '),
            userId: user.uuid,
            customAttributes: {
              'Verified Email': user.emailVerified,
              'Has Set Password': user.userSetPassword,
              'Missing Profile Data': user.missingProfileData.join(', '),
              'Is Administrator': user.isAdministrator,
            },
          })
        }
        if (posthog) {
          posthog.identify(
            user.uuid, // Replace 'distinct_id' with your user's unique identifier
            {
              email: user.email,
              name: [user.firstName, user.lastName].join(' '),
            } // optional: set additional person properties
          )
        }
        if (Sentry) {
          Sentry.setUser({
            email: user.email,
            id: user.uuid,
            username: `${user.firstName}${user.lastName ? ` ${user.lastName}` : ''}`,
          })
        }
        setLoading(false)
      }
    }
    fetchLoggedInUser()
  }, [loggedIn, intercom])

  return (
    <Fragment>
      <div>
        <Toaster
          // position="top-right"
          containerStyle={{
            top: 90,
          }}
          toastOptions={{
            // className: "",
            style: {
              // background: "rgb(255 58 95 / 0.9)",
              // color: "#ffffff",
              // boxShadow:
              //   "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
            },
          }}
        />
      </div>
      {!loading && (
        <FaviconWrapper icon={org?.theme && org.theme.logoSquare}>
          <div className="page">{props.children}</div>
        </FaviconWrapper>
      )}
      {loading && <FullPageLoader></FullPageLoader>}
    </Fragment>
  )
}
