import { Puff } from 'react-loading-icons'
import { ThemeInterface } from '../../lib/interfaces'

interface FullPageLoaderProps {
  theme?: ThemeInterface
  size?: number
  speed?: number
}

export default function FullPageLoader({
  theme,
  size = 100,
  speed = 0.8,
}: FullPageLoaderProps) {
  return (
    <div className="w-full h-full fixed top-0 left-0 bg-off-white opacity-75 z-100 dark:bg-dark-gray-light ">
      <div className="flex justify-center items-center mt-[40vh]">
        <Puff
          height={size}
          width={size}
          speed={speed}
          stroke={theme && theme.primaryColor ? theme.primaryColor : `#FF3A5F`}
          fill={theme && theme.primaryColor ? theme.primaryColor : `#FF3A5F`}
        />
      </div>
    </div>
  )
}
