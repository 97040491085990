import { createContext, useContext } from 'react'
import {
  useCurrentOrganisation,
  useOrganisations,
  useTheme,
} from '../../lib/store'
import { Organisation } from '../../lib/validators'
import { ThemeInterface } from '../../lib/interfaces'

// https://peterkellner.net/2023/05/11/Creating-a-Custom-React-Context-Provider/

export interface OrganisationWithBranding extends Organisation {
  theme?: ThemeInterface | null
}

interface OrganisationContextInterface {
  org: OrganisationWithBranding | null
  orgs: readonly Organisation[] | [] | null
  theme: ThemeInterface | null
}

const OrganisationContext = createContext<
  OrganisationContextInterface | undefined
>(undefined)

export const useOrganisationContext = () => {
  const context = useContext(OrganisationContext)
  if (context === undefined) {
    return { org: null, orgs: [], theme: null }
  }
  return context
}

export const OrganisationProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const orgs = useOrganisations()
  const org = useCurrentOrganisation()
  const theme = useTheme()

  const filterdOrgs = orgs?.filter((o) => o.id === org)
  const currentOrg = filterdOrgs && filterdOrgs.length ? filterdOrgs[0] : null

  let item: OrganisationWithBranding | null = null
  if (currentOrg) {
    item = currentOrg || undefined
    // item = {
    //   ...currentOrg,
    //   theme: {
    //     logo: "https://www.defeatdiabetes.com.au/wp-content/uploads/2022/01/DD_Logo_Light.png",
    //     primaryColor: "#EF4444",
    //     secondaryColor: "#10B981",
    //     headerBarBackgroundColor: "#0F657C".toLowerCase(),
    //     buttonColor: "#FDC02B".toLowerCase(),
    //     buttonHoverColor: "#FDD470".toLowerCase(),
    //     buttonTextColor: "#000000".toLowerCase(),
    //     linkColor: "#0F657C".toLowerCase(),
    //   },
    // };
  }

  return (
    <OrganisationContext.Provider
      value={{ org: item, orgs: orgs, theme: theme }}
    >
      {children}
    </OrganisationContext.Provider>
  )
}

export default OrganisationContext
