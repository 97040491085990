import { organisationCreateUrl } from "../../../lib/routes";
import { LinkButton } from "./LinkButton";

export function CreateOrgButton() {
  return (
    <LinkButton
      url={organisationCreateUrl}
      label="Create Organisation"
      internal={true}
    ></LinkButton>
  );
}
