import { Fragment } from 'react'
import { ResultReferral } from '../../lib/validators'
import { PageHeading } from '../layout/PageHeading'
import { StatusTag } from '../layout/StatusTag'
import { StatusTagColors } from '../../lib/interfaces'
import { Tooltip } from 'react-tooltip'
import { getPrettyDate } from '../../lib/utils'

interface ResultHeaderProps {
  patientName?: string
  result: ResultReferral
}

export function ResultHeader({ patientName, result }: ResultHeaderProps) {
  return (
    <PageHeading
      title={result?.referral?.testName || 'Results'}
      description={
        <div>
          {patientName && (
            <Fragment>
              {patientName}
              <br />
            </Fragment>
          )}
          {result.referral?.product?.description || ''}
        </div>
      }
      action={
        <div>
          {result.status === 'PARTIAL_RESULTS' ? (
            <span
              data-tooltip-id="partial-results-tooltip"
              className="cursor-default"
            >
              <StatusTag
                color={StatusTagColors.MANDARIN}
                label="Partial Results"
              ></StatusTag>
              <Tooltip
                id="partial-results-tooltip"
                place="left"
                content="We have only received a subset of your results."
                className="rounded-lg"
              />
            </span>
          ) : (
            ''
          )}
        </div>
      }
    >
      {result.referral && (
        <Fragment>
          <span className="text-gray-semi-dark dark:text-gray-dark">
            Reference:{' '}
          </span>
          <span className="mr-2">{result?.referral.reference}</span>
        </Fragment>
      )}
      {'    '}
      <span className="text-gray-semi-dark dark:text-gray-dark">
        Results date:{' '}
      </span>
      {getPrettyDate(result?.resultDate)}
    </PageHeading>
  )
}
