import AuthStore from '../lib/AuthStore'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export async function authFetch(
  input: string,
  config: AxiosRequestConfig
): Promise<AxiosResponse> {
  // export async function authFetch(input: RequestInfo, init?: RequestInit): Promise<Response> {

  // console.log("calling getAccessToken");
  const token = await AuthStore.getAccessToken()
  // console.log("the token is", token);

  const tokenHeaders: Record<string, string> = {}

  if (token) {
    tokenHeaders['Authorization'] = `Bearer ${token || ''}`
  }
  // if(!config.headers) {
  //   config.headers = {}
  // }

  config.headers = {
    ...(config.headers || {}),
    ...tokenHeaders,
  }
  config.url = input

  // console.log("the config is", tokenHeaders);

  // return fetch(input, requestInit);
  return axios(config)
}
