import { Biomarker, HistoricalBiomarkerResult } from '../../lib/validators'
import { getTextForUsersReference } from './utils'
import HorizontalBiomakerOverview from '../result/HorizontalBiomarkerOverview'
import { Fragment, useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import ResultInfoSection from '../result/ResultInfoSection'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { BiomarkerLineChart } from '../charts/BiomarkerLineChart'
import posthog from '../../lib/posthog'

interface BiomarkerResultRow {
  className?: string
  biomarker: Biomarker
  startOpen?: boolean
  historicalData?: HistoricalBiomarkerResult[]
}

export default function BiomarkerResultRow({
  biomarker,
  historicalData,
  startOpen = false,
}: BiomarkerResultRow) {
  const [expandedOpen, setExpandedOpen] = useState(false)
  const [historicalBiomarkerData, setHistoricalBiomarkerData] = useState<
    HistoricalBiomarkerResult[] | undefined
  >(historicalData)

  useEffect(() => {
    setHistoricalBiomarkerData(historicalData)
  }, [historicalData])

  useEffect(() => {
    setExpandedOpen(startOpen)
  }, [startOpen])
  const baseTransitionClasses =
    'transition-all duration-500 overflow-hidden transform '
  return (
    <Fragment>
      <div
        className="cursor-pointer "
        onClick={() => setExpandedOpen((expandedOpen) => !expandedOpen)}
      >
        <div
          className="float-right cursor-pointer text-sm text-blood hover:underline"
          key={`view-more-${biomarker.name}`}
        >
          Click to view more
          {expandedOpen ? (
            <ChevronUpIcon
              className="inline-block"
              style={{ marginTop: '-1px' }}
              width={16}
            ></ChevronUpIcon>
          ) : (
            <ChevronDownIcon
              className="inline-block"
              width={16}
              style={{ marginTop: '-1px' }}
            ></ChevronDownIcon>
          )}
        </div>
        <div className="leading-5 text-md font-bold">{biomarker.name}</div>
        <div className="leading-5 text-md text-black/70 dark:text-gray-dark mb-8">
          {biomarker.prefix && biomarker.unit}
          <span className="text-4xl font-semibold">{biomarker.value} </span>
          {biomarker.suffix && biomarker.unit}
        </div>
        {/* <div className="leading-5 text-sm text-black/70">
          {getReferenceRange(biomarker, true)}
        </div> */}

        <HorizontalBiomakerOverview
          biomarker={biomarker}
        ></HorizontalBiomakerOverview>
      </div>
      <Transition
        show={expandedOpen}
        as={Fragment}
        appear={true}
        enterFrom={`${baseTransitionClasses} scale-95 opacity-0 max-h-0`}
        enterTo={`${baseTransitionClasses} scale-100 opacity-100 max-h-screen`}
        leaveFrom={`${baseTransitionClasses} scale-100 opacity-100 max-h-screen`}
        leaveTo={`${baseTransitionClasses} scale-95 opacity-0 max-h-0`}
      >
        <div className="mt-10">
          <div className="grid grid-cols-12 gap-4">
            <div
              className={`col-span-12 ${posthog.isFeatureEnabled('can-view-biomarker-history') ? 'sm:col-span-6' : 'sm:col-span-12'}`}
            >
              <ResultInfoSection title={`What is ${biomarker.commonName}?`}>
                {biomarker.description}
              </ResultInfoSection>
            </div>
            {posthog.isFeatureEnabled('can-view-biomarker-history') && (
              <div className="col-span-12 sm:col-span-6">
                <ResultInfoSection title="History">
                  <BiomarkerLineChart
                    data={historicalBiomarkerData}
                  ></BiomarkerLineChart>
                </ResultInfoSection>
              </div>
            )}
            <div className="col-span-12 sm:col-span-12">
              <ResultInfoSection title="What your score may indicate">
                {getTextForUsersReference(biomarker)}
              </ResultInfoSection>
            </div>

            {/* <div className="col-span-4"></div> */}
            {/* <div className="col-span-4">
              <ResultInfoSection title={`Health areas affected?`}>
                <StatusTag
                  color={StatusTagColors.RED}
                  label="Energy"
                ></StatusTag>
              </ResultInfoSection>
            </div>
            <div className="col-span-4">
              <ResultInfoSection title={`Learn More`}>
                <Link className="text-bgt-primary" to={""}>
                  {biomarker.commonName} blood test article
                </Link>
              </ResultInfoSection>
            </div> */}
          </div>
        </div>
      </Transition>
    </Fragment>
  )
}
