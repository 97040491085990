import { UserIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";

interface UserImageProps {
  image?: string | null;
  size?: "3xl" | "2xl" | "xl" | "lg" | "md" | "sm";
}

export function UserImage({ image, size = "sm" }: UserImageProps) {
  // defaults to small
  let imageSizeClass = "w-6 h-6 sm:w-8 sm:h-8";
  if (size) {
    switch (size) {
      case "3xl":
        imageSizeClass = "w-24 h-24 sm:w-28 sm:h-28";
        break;
      case "2xl":
        imageSizeClass = "w-20 h-20 sm:w-24 sm:h-24";
        break;
      case "xl":
        imageSizeClass = "w-16 h-16 sm:w-20 sm:h-20";
        break;
      case "lg":
        imageSizeClass = "w-14 h-14 sm:w-16 sm:h-16";
        break;
      case "md":
        imageSizeClass = "w-12 h-12 sm:w-14 sm:h-14";
        break;
      case "sm":
        imageSizeClass = "w-6 h-6 sm:w-8 sm:h-8";
        break;
    }
  }
  return (
    <Fragment>
      {image && (
        <img
          className={`${imageSizeClass} rounded-full bg-gray-50`}
          src={image}
          alt=""
        />
      )}
      {!image && (
        <UserIcon
          className={`${imageSizeClass} sm:p-0.5 p-0.25 rounded-full border  bg-white/80 shadow-sm`}
          color="gray"
        />
      )}
    </Fragment>
  );
}
