import { useNavigate } from "react-router-dom";
import "../../App.css";
import { useOnceCall } from "../../lib/hooks";

import { PageHeading } from "../layout/PageHeading";
import PageSection from "../layout/PageSection";
import { resultsUrl } from "../../lib/routes";

export default function DashboardPage() {
  const navigate = useNavigate();
  // check for access token
  useOnceCall(async () => {
    // }, 1000)
    navigate(resultsUrl);
  });

  return (
    <main>
      <PageSection>
        <PageHeading
          title="Dashboard"
          description="Dashboard goes here"
        ></PageHeading>
      </PageSection>
    </main>
  );
}
