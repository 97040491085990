import { PropsWithChildren } from 'react'
import RoundedSection from '../layout/RoundedSection'
import { formatMoney } from '../../lib/utils'
import { CalculatedPrice } from '../../lib/validators'

interface TestBuilderFooterProps extends PropsWithChildren {
  hasSelectedTest: boolean
  price: CalculatedPrice
}

export default function TestBuilderFooter({
  children,
  hasSelectedTest,
  price,
}: TestBuilderFooterProps) {
  return (
    <RoundedSection
      size="lg"
      className={`sticky transition duration-200  -bottom-2 ${hasSelectedTest ? ' opacity-100' : 'opacity-0 pointer-events-none'} mt-2 left-0 w-full  min-h-20 shadow-xl !bg-gray-light dark:!bg-dark-gray-lighterer/90 dark:!text-white backdrop-blur-sm`}
    >
      <div className="grid sm:grid-cols-12 grid-cols-12">
        <div className="col-span-8 sm:col-span-10 font-bold text-lg pt-1 text-right">
          <div className={`${price.testsTotal > 0 ? '' : 'hidden'}`}>
            {price.testsTotal === price.grandTotal && (
              <span>
                Total Price:
                {formatMoney(price.testsTotal / 100, 'aud')}
              </span>
            )}
            {price.testsTotal !== price.grandTotal && (
              <span>
                Total Price:
                {price.subTotal > price.grandTotal && (
                  <span className="line-through text-gray-dark">
                    {formatMoney(price.subTotal / 100, 'aud')}
                  </span>
                )}{' '}
                {formatMoney(price.grandTotalIncTax / 100, 'aud')} (incl. fees &
                taxes)
              </span>
            )}
          </div>
        </div>
        {children && (
          <div className="col-span-4 sm:col-span-2 text-right">{children}</div>
        )}
      </div>
    </RoundedSection>
  )
}
