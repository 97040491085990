import { FunctionComponent } from 'react'
import { PageHeadingProps } from '../../lib/interfaces'

export const PageHeading: FunctionComponent<PageHeadingProps> = ({
  title,
  description,
  children,
  className,
  action,
  size = 'xl',
}) => {
  const getSize = () => {
    if (size === 'xl') {
      return 'text-xl sm:text-2xl'
    } else if (size === 'lg') {
      return 'text-lg sm:text-xl'
    } else if (size === 'md') {
      return 'text-md sm:text-lg'
    }
  }

  return (
    <div className={`pb-5 ${className || ''}`}>
      <div className="sm:flex">
        <h4
          className={`font-bold sm:grow text-black/70 dark:text-white ${getSize()}`}
        >
          {title}
        </h4>
        <div className="sm:flex-none sm:mt-0 mt-2">{action}</div>
      </div>
      {description && (
        <div className="pt-1 text-md text-gray-semi-dark max-w-full  dark:text-white">
          {description}
        </div>
      )}
      {children && (
        <div className="pt-1 max-w-4xl text-sm text-gray-dark dark:text-white">
          {children}
        </div>
      )}
    </div>
  )
}
