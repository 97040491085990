import { PropsWithChildren, ReactNode } from "react";
// import { StatusTag } from "./StatusTag";

export type CellItemType = string | number | ReactNode;
export interface GridTableRowInterface {
  expandedContent?: CellItemType;
  columnData: JSX.Element[];
}
export interface ColumnHeading {
  title: string;
  tooltip?: CellItemType;
  span: number;
}

export interface GridTableProps {
  colCount: number;
  colCountMobile: number;
  columns?: JSX.Element[];
  rows: JSX.Element[];
  name: string;
}
export const getColspan = (col: number) => {
  if (col === 1) {
    return "col-span-1";
  } else if (col === 2) {
    return "col-span-2";
  } else if (col === 3) {
    return "col-span-3";
  } else if (col === 4) {
    return "col-span-4";
  } else if (col === 5) {
    return "col-span-5";
  } else if (col === 6) {
    return "col-span-6";
  } else if (col === 7) {
    return "col-span-7";
  } else if (col === 8) {
    return "col-span-8";
  } else if (col === 9) {
    return "col-span-9";
  } else if (col === 10) {
    return "col-span-10";
  } else if (col === 11) {
    return "col-span-11";
  } else if (col === 12) {
    return "col-span-12";
  }
};

export const getClassForCols = (col: number): string => {
  if (col === 1) {
    return "grid-cols-1";
  } else if (col === 2) {
    return "grid-cols-2";
  } else if (col === 3) {
    return "grid-cols-3";
  } else if (col === 4) {
    return "grid-cols-4";
  } else if (col === 5) {
    return "grid-cols-5";
  } else if (col === 6) {
    return "grid-cols-6";
  } else if (col === 7) {
    return "grid-cols-7";
  } else if (col === 8) {
    return "grid-cols-8";
  } else if (col === 9) {
    return "grid-cols-9";
  } else if (col === 10) {
    return "grid-cols-10";
  } else if (col === 11) {
    return "grid-cols-11";
  } else {
    return "grid-cols-12";
  }
};
export default function GridTable({
  columns,
  rows,
  colCount,
  colCountMobile,
  name,
}: GridTableProps) {
  return (
    <div
      key={`grid-table-${name}`}
      className={`grid ${getClassForCols(
        colCount
      )} md:grid-cols-${colCountMobile}`}
    >
      {columns && columns}
      {rows}
    </div>
  );
}

interface GridTableHeaderProps extends PropsWithChildren {
  colspan?: number;
  className?: string;
}
export function GridTableHeader({
  children,
  className,
}: GridTableHeaderProps): ReactNode {
  return (
    <div
      className={`bg-gray-light text-gray-semi-dark border-b border-gray-medium p-3 px-4 font-semibold text-sm ${className}`}
    >
      {children}
    </div>
  );
}
