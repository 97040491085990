import { useEffect, useState } from 'react'
import '../../../App.css'
import { useUser } from '../../../lib/store'
import { PageHeading } from '../../layout/PageHeading'
import PageSection from '../../layout/PageSection'

import { authFetch } from '../../../providers/AuthProvider'
import { setDocumentTitle } from '../../../lib/utils'
import AdminPage from './AdminPage'

export default function AdminRawSalesIssuesPage() {
  const [saveEnabled, setSaveEnabled] = useState(false)
  const user = useUser()

  setDocumentTitle('Admin: Webhook Sale Issues')

  useEffect(() => {
    console.log('the user is', user)
    setSaveEnabled(true)
  }, [user])
  // first we need to get the current tests without results

  interface RetrySalesFormElement extends HTMLFormElement {
    readonly elements: HTMLFormControlsCollection
  }

  const handleSubmit = async (
    event: React.FormEvent<RetrySalesFormElement>
  ) => {
    event.preventDefault()
    console.log('we dsubmitted')
    await sendRequest()
  }

  const sendRequest = async () => {
    const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify({
        query: `{
            retryFailedRawSales {
              status
            }
          }
        `,
      }),
    })
    console.log(result)
  }

  return (
    <AdminPage>
      <PageSection>
        <form className="max-w-5xl" onSubmit={handleSubmit}>
          {/* <form className="max-w-5xl" onSubmit={handleSubmit} ref={formRef}></form> */}
          <PageHeading
            title="Webhook Sale Issues"
            description="If there are any issues during the processing of a sale, they will appear here."
          ></PageHeading>

          <div className="max-w-96 pt-10">
            <button
              type="submit"
              className="rounded-md bg-bgt-primary/90 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-bgt-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-dark"
              disabled={!saveEnabled}
            >
              Retry failed sales
            </button>
          </div>
        </form>
      </PageSection>
    </AdminPage>
  )
}
