import { PropsWithChildren, ReactNode } from 'react'

interface GroupSectionsProps extends PropsWithChildren {}

export function GroupSections({ children }: GroupSectionsProps) {
  return (
    <div className="divide-y divide-gray dark:divide-dark-gray-light">
      {children}
    </div>
  )
}

interface GroupSectionProps extends PropsWithChildren {
  title: string
  description?: string | ReactNode
  reducedPadding?: boolean
  underneath?: boolean
}

export function GroupSection({
  title,
  description,
  reducedPadding,
  children,
  underneath,
}: GroupSectionProps) {
  return (
    <div
      className={`${underneath !== undefined ? 'max-w-7xl px-4 py-16 sm:px-6 lg:px-8' : 'grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 sm:px-6 md:grid-cols-3 lg:px-8'} ${reducedPadding !== undefined ? 'py-4' : 'py-16'} `}
    >
      <div>
        <h2 className="text-base font-semibold leading-7 text-black dark:text-white">
          {title}
        </h2>
        <div className="mt-1 text-sm leading-6 text-gray-dark">
          {description}
        </div>
      </div>
      {underneath !== undefined && <div className="text-md">{children}</div>}
      {!underneath && (
        <div className="md:col-span-2">
          <div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:max-w-xl sm:grid-cols-6 divide-y divide-gray dark:divide-dark-gray-light">
            {children}
          </div>
        </div>
      )}
    </div>
  )
}

interface GroupSectionItemProps extends PropsWithChildren {
  title?: string
  className?: string
}

export function GroupSectionItem({
  title,
  children,
  className,
}: GroupSectionItemProps) {
  return (
    <div className={`col-span-full text-md pt-4`}>
      {title && (
        <div
          className={`text-gray-dark font-semibold text-sm ${className || ''}`}
        >
          {title}
        </div>
      )}
      {children}
    </div>
  )
}
