import { authFetch } from '../providers/AuthProvider'
import { biomarkerItemFields } from './admin'
import { BiomarkerItem, BiomarkerListSchema } from './validators'

export const getBiomarkers = async (): Promise<BiomarkerItem[]> => {
  // setTimeout(async () => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          biomarkers {
            ${biomarkerItemFields}
          }
        }`,
    }),
  })
  const data = result.data
  const parsedData = BiomarkerListSchema.parse(data.data.biomarkers)
  return parsedData
}
