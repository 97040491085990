import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { TestWithBiomarkersSelected } from '../../lib/validators'
import Checkbox from '../form/Checkbox'
import { onChangeInterface } from '../form/TextInputField'
import RoundedSection from '../layout/RoundedSection'
import { Fragment, useEffect, useState } from 'react'
import { ThemeInterface } from '../../lib/interfaces'
import { formatMoney } from '../../lib/utils'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

interface TestCustomisationItemProps {
  test: TestWithBiomarkersSelected
  checked?: boolean
  onChange?: (data: onChangeInterface) => void
  onInfoClick?: (biomarker: TestWithBiomarkersSelected) => void
  highlight?: string
  theme?: ThemeInterface
}

export function TestCustomisationItem({
  test,
  checked = false,
  onChange,
  onInfoClick,
  highlight,
}: TestCustomisationItemProps) {
  const [localTest, setLocalTest] = useState<TestWithBiomarkersSelected>()

  useEffect(() => {
    setLocalTest(test)
  }, [test])

  const highlightRegex = new RegExp(`(${highlight})`, 'i')
  return (
    <Fragment>
      {localTest &&
        (localTest.standalone === null || localTest.standalone === true) && (
          <div className={`relative group`}>
            <Checkbox
              id={localTest.id}
              name={localTest.id}
              type="checkbox"
              onChange={onChange}
              checked={checked}
              className={`w-6 h-6 !absolute top-8 right-2 z-50 hidden`}
              checkboxLabel={
                <RoundedSection
                  size="lg"
                  className="relative !p-0 -mt-6 dark:!bg-dark-gray-lighterer"
                >
                  <div className="absolute bottom-2 right-2">
                    <InformationCircleIcon
                      width={24}
                      height={24}
                      className="text-white bg-jade/80 hover:bg-jade rounded-full font-bold"
                      onClick={(e) => {
                        e.preventDefault()
                        onInfoClick && onInfoClick(localTest)
                      }}
                    ></InformationCircleIcon>
                  </div>
                  <div className="group-has-[:checked]:bg-jade/30 rounded-lg p-4">
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          highlight && highlight.length > 1
                            ? localTest.name.replace(
                                highlightRegex,
                                '<span class="rounded-sm bg-mandarin/40">$1</span>'
                              )
                            : localTest.name,
                      }}
                    ></span>
                    <small
                      dangerouslySetInnerHTML={{
                        __html:
                          highlight && highlight.length > 1
                            ? localTest.shortName.replace(
                                highlightRegex,
                                '<span class="rounded-sm bg-mandarin/40">$1</span>'
                              )
                            : localTest.shortName,
                      }}
                      className="pb-2 block text-gray-dark"
                    ></small>
                    {localTest.priceCategory && (
                      <Fragment>
                        <b data-tooltip-id="discount-tooltip">
                          {formatMoney(localTest.price / 100, 'aud')} *
                        </b>
                        <Tooltip
                          id="discount-tooltip"
                          place="bottom"
                          content="This item is eligable for a bulk discount."
                          className="rounded-lg"
                        />
                      </Fragment>
                    )}
                    {!localTest.priceCategory && (
                      <Fragment>
                        <b>{formatMoney(localTest.price / 100, 'aud')}</b>
                      </Fragment>
                    )}
                    <ul className="hidden">
                      <li>Included Biomarkers:</li>
                      {localTest.biomarkers?.map((biomarker) => (
                        <li
                          key={`test-${localTest.id}-biomarker-${biomarker.id}`}
                          className="text-gray-dark text-sm"
                          dangerouslySetInnerHTML={{
                            __html:
                              highlight && highlight.length > 1
                                ? biomarker.commonName.replace(
                                    highlightRegex,
                                    '<span class="rounded-sm bg-mandarin/40">$1</span>'
                                  )
                                : biomarker.commonName,
                          }}
                        ></li>
                      ))}
                    </ul>
                  </div>
                </RoundedSection>
              }
            ></Checkbox>
          </div>
        )}
    </Fragment>
  )
}
