import Checkbox from '../form/Checkbox'
import { onChangeInterface } from '../form/TextInputField'

interface FilterButtonProps {
  name: string
  id: string
  filled?: boolean
  label: string
  className?: string
  onChange?: (data: onChangeInterface) => void
  checked?: boolean
}

export function FilterButton({
  id,
  name,
  label,
  checked = false,
  className = '',
  onChange,
}: FilterButtonProps) {
  return (
    <div className="group inline-block mb-2 select-none">
      <Checkbox
        id={id}
        name={name}
        type="checkbox"
        onChange={onChange}
        checked={checked}
        className="hidden"
        checkboxLabel={
          <span
            className={`${className} text-gray-semi-dark cursor-pointer px-2 py-1 bg-jade/30 rounded-full group-has-[:checked]:bg-jade/80 group-has-[:checked]:text-white  `}
          >
            {label}
          </span>
        }
      ></Checkbox>
    </div>
  )
}
