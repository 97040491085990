import { Fragment, useEffect, useState } from 'react'

import TextInputField, { onChangeInterface } from '../form/TextInputField'
import { Textarea } from '../form/Textarea'
import { getResultStatus, ResultStatusTypes } from '../../lib/admin'
import { BiomarkerMasterItem } from '../pages/admin/AdminCreateResultsPageNew'

export interface onBiomarkerChange {
  id: string
  value?: number
  name: string
  refLow?: number
  refHigh?: number
  refLowNotes?: string
  refHighNotes?: string
  refMidNotes?: string
  description?: string
}

export interface onBiomarkerInvalidChange {
  id: string
}

interface AdminBiomarkerElementProps {
  biomarker: BiomarkerMasterItem
  showDescriptionFields?: boolean
  handleChange?: (data: onChangeInterface) => void
  handleValid?: (data: BiomarkerMasterItem) => void
  handleInvalid?: (data: onBiomarkerInvalidChange) => void
}

export default function AdminBiomarkerElement({
  biomarker,

  showDescriptionFields,
  handleValid,
}: AdminBiomarkerElementProps) {
  // console.log("biomarker", biomarker, description);

  const [abnormal, setAbnormal] = useState(false)

  const [biomarkerCopy, setBiomarkerCopy] =
    useState<BiomarkerMasterItem>(biomarker)

  // const biomarkerMemo = useMemo(() => {
  //   console.log('in biomarker memo', biomarker.id)
  //   return biomarker
  // }, [biomarker])

  // useEffect(() => {
  //   console.log('BIOMARKER UPDATED')
  // }, [biomarker.value, biomarker.refIntervalLow, biomarker.refIntervalHigh])

  const onChangeDescription = (data: onChangeInterface) => {
    // setDescription(data.strValue)
    setBiomarkerCopy((prev) => ({
      ...prev,
      description: data.strValue,
    }))
  }
  const onChangeLowNotes = (data: onChangeInterface) => {
    // setLowNotes(data.strValue)
    setBiomarkerCopy((prev) => ({
      ...prev,
      refIntervalLowNotes: data.strValue,
    }))
  }
  const onChangeHighNotes = (data: onChangeInterface) => {
    // setHighNotes(data.strValue)
    setBiomarkerCopy((prev) => ({
      ...prev,
      refIntervalHighNotes: data.strValue,
    }))
  }
  const onChangeMidNotes = (data: onChangeInterface) => {
    // setMidNotes(data.strValue)
    setBiomarkerCopy((prev) => ({
      ...prev,
      refIntervalMidNotes: data.strValue,
    }))
  }
  const onChangeLow = (data: onChangeInterface) => {
    let val: string | undefined = undefined
    if (data.value === '') {
      val = undefined
    } else {
      val = data.value.toString().replace(/[^0-9.]+/gi, '')
    }
    setBiomarkerCopy((prev) => ({
      ...prev,
      refIntervalLow: val,
    }))
  }
  const onChangeHigh = (data: onChangeInterface) => {
    let val: string | undefined = undefined
    if (data.value === '') {
      val = undefined
    } else {
      val = data.value.toString().replace(/[^0-9.]+/gi, '')
    }
    setBiomarkerCopy((prev) => ({
      ...prev,
      refIntervalHigh: val,
    }))
  }

  const onChangeValue = (data: onChangeInterface) => {
    let val: string | undefined = undefined
    if (data.value === '') {
      val = undefined
    } else {
      val = data.value.toString().replace(/[^0-9.]+/gi, '')
    }
    setBiomarkerCopy((prev) => ({
      ...prev,
      value: val,
    }))
  }

  useEffect(() => {
    // if (Object.keys(diff(biomarker, biomarkerCopy)).length > 0) {
    //   handleValid && handleValid(biomarkerCopy)
    // }
    console.log('{}{}{}{}{}', biomarkerCopy)
    handleValid && handleValid(biomarkerCopy)
  }, [biomarkerCopy, handleValid])

  useEffect(() => {
    setAbnormal(
      getResultStatus(
        biomarkerCopy.value?.toString(),
        biomarkerCopy.refIntervalLow?.toString(),
        biomarkerCopy.refIntervalHigh?.toString()
      ) === ResultStatusTypes.abnormal
    )
  }, [biomarkerCopy])

  const doesUnitMatch = (): boolean => {
    if (!biomarkerCopy.unitParsed) {
      return true
    }
    return biomarkerCopy.unitParsed == biomarkerCopy.unit ? true : false
  }

  return (
    <div
      className={
        abnormal
          ? '!ring-blood focus:ring-blood ring-2 bg-blood/10 rounded-lg p-4 relative'
          : !doesUnitMatch()
            ? '!ring-mandarin focus:ring-mandarin ring-2 bg-mandarin/10 rounded-lg p-4 relative'
            : 'p-4'
      }
    >
      {abnormal && (
        <div className="absolute bg-blood text-white text-sm top-0 right-0 font-bold px-2 rounded-lg rounded-tl-none rounded-br-none">
          Abnormal
        </div>
      )}
      {!doesUnitMatch() && (
        <div className="absolute bg-mandarin text-white text-sm top-0 right-0 font-bold px-2 rounded-lg rounded-tl-none rounded-br-none">
          Unit Mismatch
        </div>
      )}
      <TextInputField
        id={`biomarker_${biomarker.id}`}
        label={`${biomarker.name}`}
        name={`biomarker_${biomarker.id}`}
        type="text"
        tooltip={biomarker.commonName || undefined}
        handleChange={onChangeValue}
        required={true}
        unit={biomarker.unit}
        inputmode="numeric"
        pattern="[\d.]*"
        value={biomarkerCopy.value ? biomarkerCopy.value.toString() : ''}
      />
      <div className="grid grid-cols-2 pt-2 gap-4">
        <div>
          <TextInputField
            id={`biomarker_${biomarker.id}_ref-low`}
            name={`biomarker_${biomarker.id}_ref-low`}
            type="text"
            tooltip="Ref Interval Low"
            handleChange={onChangeLow}
            inputmode="numeric"
            pattern="[\d.]*"
            // placeholder={biomarker.refIntervalLow?.toString()}
            value={
              biomarkerCopy.refIntervalLow
                ? biomarkerCopy.refIntervalLow.toString()
                : ''
            }
          />
        </div>
        <div>
          <TextInputField
            id={`biomarker_${biomarker.id}_ref-high`}
            name={`biomarker_${biomarker.id}_ref-high`}
            type="text"
            inputmode="numeric"
            pattern="[\d.]*"
            tooltip="Ref Interval High"
            handleChange={onChangeHigh}
            // placeholder={biomarker.refIntervalHigh?.toString()}
            value={
              biomarkerCopy.refIntervalHigh
                ? biomarkerCopy.refIntervalHigh.toString()
                : ''
            }
          />
        </div>
      </div>
      {!doesUnitMatch() && (
        <div className="text-mandarin font-bold">
          There is a unit mismatch. Expected: {biomarkerCopy.unit}, received:{' '}
          {biomarkerCopy.unitParsed}.
        </div>
      )}
      {showDescriptionFields && (
        <Fragment>
          <div className="pt-2">
            <Textarea
              id={`biomarker_${biomarker.id}_description`}
              name={`biomarker_${biomarker.id}_description`}
              label="Biomarker Description"
              value={biomarker.description || ''}
              handleChange={onChangeDescription}
            ></Textarea>
          </div>
          <div className="pt-2">
            <Textarea
              id={`biomarker_${biomarker.id}_low-ref-notes`}
              name={`biomarker_${biomarker.id}_low-ref-notes`}
              label="Low Result Reference Notes"
              value={biomarker.refIntervalLowNotes || ''}
              handleChange={onChangeLowNotes}
            ></Textarea>
          </div>
          <div className="pt-2">
            <Textarea
              id={`biomarker_${biomarker.id}_mid-ref-notes`}
              name={`biomarker_${biomarker.id}_mid-ref-notes`}
              label="Mid Result Reference Notes"
              value={biomarker.refIntervalMidNotes || ''}
              handleChange={onChangeMidNotes}
            ></Textarea>
          </div>
          <div className="pt-2">
            <Textarea
              id={`biomarker_${biomarker.id}_high-ref-notes`}
              name={`biomarker_${biomarker.id}_high-ref-notes`}
              label="High Result Reference Notes"
              value={biomarker.refIntervalHighNotes || ''}
              handleChange={onChangeHighNotes}
            ></Textarea>
          </div>
        </Fragment>
      )}
    </div>
  )
}
