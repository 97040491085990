import { Fragment, useEffect, useState } from 'react'
import { ResultReferral } from '../../../lib/validators'
import { ResultsTabProps } from '../../../lib/interfaces'

export const ResultTabDocument: React.FC<ResultsTabProps> = ({
  resultReferral,
  files,
}) => {
  // export function ResultTabOverview({ referralResult }: ResultsTabProps) {
  const [result, setResult] = useState<ResultReferral>()
  const [resultFileUrls, setResultFileUrls] = useState<string[]>([])

  useEffect(() => {
    setResult(resultReferral)
  }, [resultReferral])
  useEffect(() => {
    if (files) setResultFileUrls(files)
  }, [files])

  useEffect(() => {}, [resultFileUrls])

  return (
    <Fragment>
      {!result && <div>Loading...</div>}
      {result && (
        <Fragment>
          {resultFileUrls &&
            resultFileUrls.map((url) => (
              // <object
              //   style={{
              //     width: '100%',
              //     height: '75dvh',
              //   }}
              //   data={url}
              // ></object>
              <embed
                src={url}
                type="application/pdf"
                width="100%"
                height="1000px"
              />
            ))}
        </Fragment>
      )}
    </Fragment>
  )
}
