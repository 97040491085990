import React from 'react'
import { Button, ButtonColors } from '../form/button/Button'
import { HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/outline'

interface BooleanFeedbackProps {
  positiveContent?: React.ReactNode
  negativeContent?: React.ReactNode
  positiveClassName?: string
  negativeClassName?: string
  positiveButtonColor?: ButtonColors
  negativeButtonColor?: ButtonColors
  scaleOnHover?: boolean
  containerClassName?: string
  onFeedback: (positive: boolean) => void
}

const baseButtonClassName = '!text-xl hover:scale-150 transition-transform duration-200 ease-in-out dark:!text-white';

const BooleanFeedback: React.FC<BooleanFeedbackProps> = ({
  positiveContent = <HandThumbUpIcon height={20} />,
  negativeContent = <HandThumbDownIcon height={20} />,
  positiveClassName = `${baseButtonClassName} hover:!text-jade dark:hover:!text-jade`,
  negativeClassName = `${baseButtonClassName} hover:!text-blood dark:hover:!text-blood`,
  positiveButtonColor = 'plain',
  negativeButtonColor = 'plain',
  containerClassName = 'flex gap-2 items-center justify-center',
  onFeedback,
}) => {
  return (
    <div className={containerClassName}>
      <Button
        classNames={positiveClassName}
        onClick={() => onFeedback(true)}
        color={positiveButtonColor}
      >
        {positiveContent}
      </Button>
      <Button
        classNames={negativeClassName}
        onClick={() => onFeedback(false)}
        color={negativeButtonColor}
      >
        {negativeContent}
      </Button>
    </div>
  )
}

export default BooleanFeedback
