import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import * as Sentry from '@sentry/react'
import ReactGA from 'react-ga4'

Sentry.init({
  dsn: 'https://a9c8d54fed2b81dad89267ac04c6538d@o4506238615420928.ingest.sentry.io/4506272230211584',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  enabled:
    window.location.hostname.indexOf('bloodygoodtests.com.au') !== -1
      ? true
      : false,
  environment: import.meta.env.VITE_ENV,
  release: import.meta.env.VITE_RELEASE,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

if (import.meta.env.VITE_GA_CODE) {
  console.log('INIT GTAG')
  ReactGA.initialize(import.meta.env.VITE_GA_CODE)
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
