import { useCallback } from 'react'
import { useSnapshot } from 'valtio'
import { z } from 'zod'
import { authFetch } from '../providers/AuthProvider'
import { store } from './store'
import { Feedback, FeedbackSchema, FeedbackValue } from './validators'

export function useUserFeedback() {
  const userFeedback = useSnapshot(store).userFeedback

  const hasFeedback = useCallback(
    ({
      feature,
      source,
      agent,
    }: {
      feature: string
      source?: string
      agent?: string
    }): boolean => {
      return !!userFeedback.find(
        (f) =>
          f.feature === feature &&
          (source ? f.source === source : true) &&
          (agent ? f.agent === agent : true)
      )
    },
    [userFeedback]
  )

  return {
    userFeedback,
    hasFeedback,
    submitFeedback,
  }
}

interface FeedbackSubmissionProps {
  feedback: FeedbackValue
  feature: string
  source: string
  agent: string
}

export const submitFeedback = async (
  data: FeedbackSubmissionProps
): Promise<Feedback> => {
  const response = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation submitFeedback($data: FeedbackSubmission!) {
            submitFeedback(data: $data) {
              uuid
              profileUuid
              feedback
              feature
              source
              agent
              createdAt
              updatedAt
            }
          }
        `,
      variables: {
        data: {
          ...data,
          feedback: JSON.stringify(data.feedback), // serialise to JSON string for GraphQL
        },
      },
    }),
  })

  const feedback = FeedbackSchema.parse(response.data.data.submitFeedback)

  // Update the store with latest feedback
  store.userFeedback.push(feedback)

  return feedback
}

export const getMyFeedback = async (): Promise<Feedback[]> => {
  const response = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
        query {
          myFeedback {
            uuid
            profileUuid
            source
            feedback
            feature
            agent
            createdAt
            updatedAt
          }
        }
      `,
    }),
  })

  return z.array(FeedbackSchema).parse(response.data.data.myFeedback)
}
