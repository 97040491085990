import BgtFullImage from '../../../public/img/bgt-logo-full.svg'

export function PoweredBy() {
  return (
    <li className="mt-auto uppercase text-xs  font-semibold">
      <a
        href="https://bloodygoodtests.com.au"
        target="_blank"
        className="text-black/70 dark:text-white/80"
      >
        Powered by
        <img src={BgtFullImage} alt="Powered by BGT" />
      </a>
    </li>
  )
}
