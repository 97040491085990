import { MoonIcon, SunIcon } from '@heroicons/react/24/outline'
import { Fragment, useEffect, useState } from 'react'
import { useOnceCall } from '../..//lib/hooks'

export function ThemeToggle() {
  const [darkModeEnabled, setDarkModeEnabled] = useState<boolean>(
    document.getElementsByTagName('html')[0].classList.contains('dark')
  )

  useOnceCall(() => {
    const localStorageSetting = localStorage.getItem('theme') || undefined
    const localSettingDarkPreference =
      localStorageSetting && localStorageSetting === 'dark' ? true : false
    const osPrefersDarkSetting = window.matchMedia(
      '(prefers-color-scheme: dark)'
    ).matches
    const hasDarkClassAlready = darkModeEnabled

    if (localSettingDarkPreference === false) {
      setDarkModeEnabled(false)
      return
    }
    setDarkModeEnabled(hasDarkClassAlready || osPrefersDarkSetting)
  })

  const toggleDarkMode = () => {
    setDarkModeEnabled(!darkModeEnabled)
  }

  useEffect(() => {
    localStorage.setItem('theme', darkModeEnabled ? 'dark' : 'light')
    if (darkModeEnabled) {
      document.getElementsByTagName('html')[0].classList.add('dark')
    } else {
      document.getElementsByTagName('html')[0].classList.remove('dark')
    }
  }, [darkModeEnabled])

  return (
    <Fragment>
      {darkModeEnabled && (
        <MoonIcon
          className="cursor-pointer dark:text-white"
          onClick={() => toggleDarkMode()}
          width={20}
        ></MoonIcon>
      )}
      {!darkModeEnabled && (
        <SunIcon
          className="cursor-pointer"
          onClick={() => {
            toggleDarkMode()
          }}
          width={20}
        ></SunIcon>
      )}
    </Fragment>
  )
}
