import { Fragment } from 'react'
import { OrganisationWithBranding } from './context/OrganisationContext'
import Page, { PageWithProps } from './Page'
import { OrgTabbar, TabbarItem } from './layout/Tabbar'
import { ThemeInterface } from '../lib/interfaces'

export interface OrgPageWithProps extends PageWithProps {
  org: OrganisationWithBranding
  theme?: ThemeInterface
  tabs?: TabbarItem[]
}

export default function OrgPage(props: OrgPageWithProps) {
  return (
    <Page
      backgroundColor={props.backgroundColor}
      fullViewport={props.fullViewport}
      className={props.className}
    >
      <OrgTabbar
        theme={props.theme}
        org={props.org}
        tabs={props.tabs}
      ></OrgTabbar>
      <Fragment>{props.children}</Fragment>
    </Page>
  )
}
