import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Puff } from 'react-loading-icons'
import { Button } from '../form/button/Button'
import { ImageWithCrop, ThemeInterface } from '../../lib/interfaces'
import 'react-image-crop/dist/ReactCrop.css'

import { AcceptedFileTypes, FileDrop } from './FileDrop'
import ReactCrop, {
  Crop,
  PixelCrop,
  centerCrop,
  makeAspectCrop,
} from 'react-image-crop'
// import { useDebounceEffect } from "../../lib/utils";

export async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop
) {
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('No 2d context')
  }

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

  ctx.scale(pixelRatio, pixelRatio)
  ctx.imageSmoothingQuality = 'high'

  const cropX = crop.x * scaleX
  const cropY = crop.y * scaleY

  const centerX = image.naturalWidth / 2
  const centerY = image.naturalHeight / 2

  ctx.save()

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY)
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY)
  // 3) Rotate around the origin
  // 2) Scale the image
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY)
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  )

  ctx.restore()
}

export interface OnImageSelectedCallback {
  blob: Blob
  type: string
  // complete: () => void;
  url?: string
  filename: string
  name: string
  crop?: Crop
  rawUrl: string
  rawFile: File | Blob
  rawFileFilename: string
  newImage: boolean
  // apply?: boolean;
}

export interface ImageUploaderAndCropperProps {
  show: boolean
  theme?: ThemeInterface
  acceptedFileTypes: AcceptedFileTypes
  originalImageWithCrop?: ImageWithCrop
  aspectRatio?: number
  name: string
  circularCrop?: boolean
  desiredFilename?: string
  onCancel?: () => void
  onClose?: () => void
  onImageSelected: (data: OnImageSelectedCallback) => void
}

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

export function ImageUploaderAndCropper(props: ImageUploaderAndCropperProps) {
  const [open, setOpen] = useState(props.show)
  const [isSaving, setIsSaving] = useState(false)
  const [rawFile, setRawFile] = useState<File | Blob>()
  const [imgSrc, setImgSrc] = useState('')
  const [filename, setFilename] = useState('')
  const [crop, setCrop] = useState<Crop>()
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const blobUrlRef = useRef('')
  const [didPickNewImage, setDidPickNewimage] = useState(false)

  const imgRef = useRef<HTMLImageElement>(null)
  const [aspect, setAspect] = useState<number | undefined>(undefined) //240 / 64
  // const [dialogErrorMessage, setDialogErrorMessage] = useState<
  //   string | undefined
  // >();

  const handleFileDrop = (files: File[]): void => {
    if (files && files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || '')
      )
      reader.readAsDataURL(files[0])
      setRawFile(files[0])
      setFilename(files[0].name)
      setDidPickNewimage(true)
    }
    // setUploads(items);
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect && !crop) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    } else if (!crop) {
      setCrop({
        unit: '%',
        width: 100,
        height: 100,
        x: 0,
        y: 0,
      })
    }
  }
  useEffect(() => {
    if (open) {
      setDidPickNewimage(false)
    }
  }, [open])

  useEffect(() => {
    // if (props.originalImageWithCrop && props.originalImageWithCrop.crop) {
    //   setCrop(props.originalImageWithCrop.crop);
    // }
    if (
      open &&
      props.originalImageWithCrop &&
      props.originalImageWithCrop.url
      // props.originalImageWithCrop.crop
    ) {
      setIsSaving(true)
      // load the image
      fetch(props.originalImageWithCrop.url, {})
        .then((res) => res.blob())
        .then((blob) => {
          setRawFile(blob)

          setImgSrc(URL.createObjectURL(blob))
          setFilename(
            props.originalImageWithCrop?.filename ||
              props.originalImageWithCrop?.url ||
              ''
          )
          setCrop(props.originalImageWithCrop?.crop || undefined)
          setIsSaving(false)
        })
    } else if (!crop) {
      setCrop({
        unit: '%',
        width: 50,
        height: 50,
        x: 25,
        y: 25,
      })
    }
  }, [open, props.originalImageWithCrop])

  // useEffect(() => {
  //   if (!crop) {
  //     setCrop({
  //       unit: "%",
  //       width: 50,
  //       height: 50,
  //       x: 25,
  //       y: 25,
  //     });
  //   }
  // }, [crop]);

  const resetDialog = () => {
    setCrop(undefined)
    setImgSrc('')
    setFilename('')
  }

  // useEffect(() => {
  //   resetDialog();
  // }, [props]);
  useEffect(() => {
    setAspect(props.aspectRatio || undefined)
  }, [props.aspectRatio])

  const closeDialog = () => {
    if (isSaving) {
      return
    }
    resetDialog()
    setOpen(false)
    props.onClose && props.onClose()
  }
  const finished = () => {
    setIsSaving(false)
    setOpen(false)
    props.onClose && props.onClose()
  }

  const cancel = () => {
    props.onCancel && props.onCancel()
    setTimeout(() => {
      resetDialog()
    }, 500)

    finished()
    setOpen(false)
  }

  useEffect(() => {
    async function foo() {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop)
      }
    }
    foo()
  }, [completedCrop])

  async function handleConfirmationDialog(apply?: boolean) {
    const image = imgRef.current
    const previewCanvas = previewCanvasRef.current
    if (!image || !previewCanvas || !completedCrop) {
      console.log(image, previewCanvas, completedCrop)
      throw new Error('Crop canvas does not exist')
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    )
    const ctx = offscreen.getContext('2d')
    if (!ctx) {
      throw new Error('No 2d context')
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    )
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    })

    console.log('the blob => ', blob)
    // setIsSaving(true);

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current)
    }
    blobUrlRef.current = URL.createObjectURL(blob)

    if (!rawFile) {
      throw new Error('We need the raw file object to complete this')
    }

    const parts = filename.split('.')
    console.log('PARTS', parts)
    const ogExt = parts.pop()
    console.log('OGEXT', ogExt)

    props.onImageSelected({
      blob: blob,
      // complete: finished,
      type: 'image/png',
      url: blobUrlRef.current,

      name: props.name,
      filename: props.desiredFilename
        ? `${props.desiredFilename}.png`
        : `${parts.join('.')}.png`,
      crop: crop,
      rawUrl: imgSrc,
      rawFile: rawFile,
      rawFileFilename: props.desiredFilename
        ? `${props.desiredFilename}.${ogExt}`
        : filename,
      newImage: didPickNewImage,
      // apply: apply,
    })
    if (!apply) {
      finished()
      setTimeout(() => {
        resetDialog()
      }, 500)
    }

    // if (blobUrlRef.current) {
    //   URL.revokeObjectURL(blobUrlRef.current);
    // }
    // blobUrlRef.current = URL.createObjectURL(blob);

    // if (hiddenAnchorRef.current) {
    //   hiddenAnchorRef.current.href = blobUrlRef.current;
    //   hiddenAnchorRef.current.click();
    // }
  }

  return (
    <div className="mt-2">
      <Button
        label="Select image"
        onClick={() => setOpen(true)}
        type="button"
        theme={props.theme}
      ></Button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className={`relative z-10 ${isSaving ? 'cursor-progress' : ''}`}
          // initialFocus={cancelButtonRef}
          onClose={closeDialog}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div
            className={`fixed inset-0 z-10 w-screen overflow-y-auto ${
              isSaving ? 'cursor-progress' : ''
            }`}
          >
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl ${
                    isSaving ? 'cursor-progress' : ''
                  }`}
                >
                  {isSaving && (
                    <div className="absolute w-full h-full bg-white/50 z-50 flex justify-center items-center ">
                      <Puff
                        height={100}
                        width={100}
                        speed={0.8}
                        stroke="#FF3A5F"
                        fill="#FF3A5F"
                      />
                    </div>
                  )}
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <div className="mt-2">
                          {/* <ExpressCheckoutElement
                          onConfirm={(event) => console.log("onconfirm", event)}
                        /> */}
                          {!!imgSrc && (
                            <ReactCrop
                              crop={crop}
                              circularCrop={props.circularCrop}
                              onChange={(_, percentCrop) =>
                                setCrop(percentCrop)
                              }
                              onComplete={(c) => setCompletedCrop(c)}
                              aspect={aspect}
                              minHeight={32}
                              // circularCrop
                            >
                              <img
                                ref={imgRef}
                                alt="Crop me"
                                src={imgSrc}
                                onLoad={onImageLoad}
                              />
                            </ReactCrop>
                          )}
                          {!!completedCrop && (
                            <>
                              <div>
                                <canvas
                                  ref={previewCanvasRef}
                                  className="hidden"
                                  style={{
                                    border: '1px solid black',
                                    objectFit: 'contain',
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                  }}
                                />
                              </div>
                            </>
                          )}
                          <FileDrop
                            acceptedFileTypes={props.acceptedFileTypes}
                            multiple={false}
                            onDropCallback={handleFileDrop}
                            label={
                              filename
                                ? `Click here to select a new image`
                                : `Click here to select an image`
                            }
                            classNames={
                              filename
                                ? `!border-0 text-sm !p-2 m-0`
                                : 'block w-full'
                            }
                          />

                          {/* {dialogErrorMessage && (
                            <div className="text-blood font-semibold text-sm p-4 border border-blood rounded-md bg-blood/5">
                              ⚠️ {dialogErrorMessage}
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse sm:px-6 items-stretch">
                    <Button
                      type="button"
                      color="jade"
                      onClick={() => handleConfirmationDialog()}
                      disabled={
                        completedCrop === undefined ||
                        isSaving === true ||
                        !filename
                      }
                      theme={props.theme}
                      label="Confirm"
                    ></Button>
                    {/* <Button
                      type="button"
                      color="mandarin"
                      onClick={() => handleConfirmationDialog(true)}
                      disabled={isSaving || !filename}
                      classNames="mr-2"
                    >
                      Apply
                    </Button> */}
                    <Button
                      type="button"
                      label="Cancel"
                      classNames="mr-2 cursor-pointer"
                      onClick={() => cancel()}
                      disabled={isSaving}
                    ></Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}
