import { authFetch } from '../providers/AuthProvider'
import { ResultAiSummary, ResultAiSummarySchema } from './validators'

export const generateResultAiSummary = async (
  resultUuid: string
): Promise<ResultAiSummary> => {
  const response = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation generateResultAiSummary($resultUuid: UUID!) {
            generateResultAiSummary(data: {
              resultUuid: $resultUuid,
            }) {
              summary
            }
          }
        `,
      variables: {
        resultUuid: resultUuid,
      },
    }),
  })
  const summary = ResultAiSummarySchema.parse(
    response.data.data.generateResultAiSummary
  )

  return summary
}
