import { PropsWithChildren } from 'react'
import PageSection from './PageSection'

interface EmptyStateProps extends PropsWithChildren {
  title: string
  secondTitle?: string
  image?: string
  alt?: string
}

export function EmptyState({
  title,
  children,
  image,
  secondTitle,
  alt,
}: EmptyStateProps) {
  return (
    <PageSection fullHeight={true} centerContent={true}>
      <div className="max-w-full sm:max-w-[60%] text-sm sm:text-md">
        {image && (
          <img
            className="text-center mb-4 sm:mb-8 inline-block"
            src={image}
            alt={alt ? alt : 'Empty page icon'}
          ></img>
        )}
        <div className="sm:mb-8 mb-4">
          <div className="text-lg font-bold">{title}</div>
          {secondTitle && (
            <div className="text-lg font-bold">{secondTitle}</div>
          )}
        </div>
        {children}
      </div>
    </PageSection>
  )
}
