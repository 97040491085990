import { Fragment, useEffect, useState } from 'react'

import { SettingsTabProps } from '../Settings'
import { Profile, User } from '../../../lib/validators'
import SettingsTabAccountProfile from './SettingsTabAccountProfile'
import SettingsTabAccountPassword from './SettingsTabAccountPassword'
import SettingsTabAccountDeletion from './SettingsTabAccountDeletion'
import { getProfileData } from '../../../lib/profile'
import { setDocumentTitle } from '../../../lib/utils'
import { GroupSections } from '../../layout/GroupSection'

export interface SettingsAccountProps {
  user: User
  profile: Profile
}

// avatar crop
// https://www.npmjs.com/package/react-avatar-editor

export const SettingsTabAccount: React.FC<SettingsTabProps> = ({ user }) => {
  const [profile, setProfile] = useState<Profile>()

  setDocumentTitle('Account')

  useEffect(() => {
    if (user) {
      getProfileData(user.profileUuid).then((profile) => setProfile(profile))
    }
  }, [user])

  return (
    <Fragment>
      {(!profile || !user) && <div>Loading</div>}
      {profile && (
        <GroupSections>
          <SettingsTabAccountProfile
            profile={profile}
            user={user}
          ></SettingsTabAccountProfile>
          <SettingsTabAccountPassword
            profile={profile}
            user={user}
          ></SettingsTabAccountPassword>
          <SettingsTabAccountDeletion></SettingsTabAccountDeletion>
        </GroupSections>
      )}
    </Fragment>
  )
}
