import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'

import AppWrapper from './components/AppWrapper'
import { OrganisationProvider } from './components/context/OrganisationContext'

import { authExcludedRoutes, routes } from './lib/routes'
import { NotFound } from './components/pages/NotFound'

const INTERCOM_APP_ID =
  import.meta.env.VITE_ENV === 'production' ? 'ndrkocl7' : 'nbj0b05u'

export default function App() {
  return (
    <BrowserRouter>
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}>
        <OrganisationProvider>
          <AppWrapper excludedAuthUrls={authExcludedRoutes}>
            <Routes>
              {Object.entries(routes).map(([key, value]) => (
                <Route path={key} key={key} element={<value.handler />} />
              ))}
              <Route path="*" key="not-found" element={<NotFound />} />
            </Routes>
          </AppWrapper>
        </OrganisationProvider>
      </IntercomProvider>
    </BrowserRouter>
  )
}
