import { setDocumentTitle } from '../../../lib/utils'

import AdminPage from './AdminPage'
import { PageHeading } from '../../layout/PageHeading'
import PageSection from '../../layout/PageSection'

export function AdminHome() {
  setDocumentTitle('Admin Home', 'CLEAN')

  return (
    <AdminPage>
      <PageSection>
        <PageHeading title={'Admin Page'}></PageHeading>
        <div>Admin home page home page</div>
      </PageSection>
    </AdminPage>
  )
}
