import { Fragment, useCallback, useEffect, useState } from 'react'
import { useDropzone, Accept } from 'react-dropzone'

export interface DropBoxProps {
  acceptedFileTypes: AcceptedFileTypes
  multiple?: boolean
  onDropCallback: (files: File[]) => void
  label?: string
  classNames?: string
}

export type AcceptedFileTypes = Accept

export enum UploadStatus {
  Ready,
  Uploading,
  Processing,
  Complete,
}

export interface FileUploadElement {
  file: File
  uploadProgress: number
  status: UploadStatus
  uuid?: string
}
export interface UploadDict {
  [key: string]: FileUploadElement
}

export const acceptedImageFiles: AcceptedFileTypes = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/webp': ['.webp'],
}

export function FileDrop({
  acceptedFileTypes,
  multiple,
  onDropCallback,
  label,
  classNames,
}: DropBoxProps) {
  const [activeFiles, setActiveFiles] = useState<File[]>([])
  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((acceptedFile) => {
      console.log('We got a file', acceptedFile.name)
      console.log(activeFiles)

      // const reader = new FileReader();

      // reader.onabort = () => console.log("file reading was aborted");
      // reader.onerror = () => console.log("file reading has failed");
      // reader.onload = () => {
      //   // Do whatever you want with the file contents
      //   const binaryStr = reader.result;
      //   console.log(binaryStr);
      // };
      // reader.readAsArrayBuffer(file);
    })
    setActiveFiles(acceptedFiles)
  }, [])
  useEffect(() => {
    onDropCallback(activeFiles)
  }, [activeFiles])
  // useCallback();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: multiple ? multiple : false,
    accept: acceptedFileTypes,
    // validator: (file: File): FileError | FileError[] | null => {
    //   console.log("validate the file.", file);
    //   return null;
    // },
  })

  return (
    <Fragment>
      <div
        className={`rounded-lg bg-gray-light dark:bg-dark-gray-light dark:text-white p-4 pt-6 pb-6 w-full border-dashed border-4 cursor-pointer ${
          isDragActive ? 'border-bgt-primary/50' : 'border-gray-dark/20'
        } ${classNames}`}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop it now.</p>
        ) : (
          <p>
            {label
              ? label
              : "Drag 'n' drop some files here, or click to select files"}
          </p>
        )}
      </div>
      {/* {activeFiles.length > 0 && (
        <div className="mt-4">
          {activeFiles.map((file, idx) => (
            <FileDropFile
              key={`file-${idx}`}
              name={file.name}
              size={file.size}
            ></FileDropFile>
          ))}
        </div>
      )} */}
    </Fragment>
  )
}
