import { useEffect, useRef, useState } from 'react'
import '../../../App.css'
import { useUser } from '../../../lib/store'
import { authFetch } from '../../../providers/AuthProvider'

import toast from 'react-hot-toast'
import { PageHeading } from '../../layout/PageHeading'
import { AcceptedFileTypes, FileDrop } from '../../form/FileDrop'
import { CheckCircleIcon, DocumentTextIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import PageSection from '../../layout/PageSection'

import { setDocumentTitle } from '../../../lib/utils'
import AdminPage from './AdminPage'
import SimpleSelectField from '../../form/SimpleSelectField'

enum UploadStatus {
  Ready,
  Uploading,
  Processing,
  Complete,
}
interface FileUploadElement {
  file: File
  uploadProgress: number
  status: UploadStatus
  uuid?: string
}
interface UploadDict {
  [key: string]: FileUploadElement
}

enum FileUploadTypes {
  None = 'none',
  // Master = 'master',
  Biomarkers = 'biomarkers',
  BiomarkerCategories = 'biomarkerCategories',
  Tests = 'tests',
  Tests2Biomarkers = 'tests2biomarkers',
  TestPriceCategories = 'testPriceCategories',
  Products = 'products',
  Products2Tests = 'products2tests',
  Tests2Tests = 'tests2tests',
}

export default function AdminUpdateBiomarkersPage() {
  const user = useUser()
  const navigate = useNavigate()
  const formRef = useRef<HTMLFormElement>(null)
  const [saveEnabled, setSaveEnabled] = useState(false)
  const [initComplete, setInitComplete] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [uploads, setUploads] = useState<UploadDict>({})
  const [fileSelector, setFileSelector] = useState<FileUploadTypes>(
    FileUploadTypes.None
  )

  console.log('the admin user', user?.isAdministrator)

  setDocumentTitle('Admin: Update Biomarkers/Tests')
  // first we need to get the current tests without results.

  useEffect(() => {
    if (!initComplete) {
      setInitComplete(true)
    }
  }, [initComplete, user, navigate])

  interface CreateResultsFormElement extends HTMLFormElement {
    readonly elements: HTMLFormControlsCollection
  }

  const handleSubmit = async (
    event: React.FormEvent<CreateResultsFormElement>
  ) => {
    event.preventDefault()
    if (fileSelector === FileUploadTypes.None) {
      toast.error('Please select the type of file you are uploading', {
        duration: 3000,
      })
      return
    }

    // event.currentTarget.elements.biomarker;
    // const formData: Record<string, unknown> = {
    //   biomarkers: [],
    // };
    const formData = new FormData()
    formData.append('bgtFileType', fileSelector)
    // formData.append("file", fileupload.files[0]);
    Object.entries(uploads).map(([, item]) => {
      formData.append('file', item.file)
    })
    // console.log(formData);
    // uncomment if you want to pass extra stuff?
    // ;[...event.currentTarget.elements].forEach((item) => {
    //   if (item.getAttribute('type') !== 'file' && item.getAttribute('name')) {
    //     console.log('processing this item ==> ', item)
    //     formData.append(item.getAttribute('name')!, item.nodeValue!)
    //   }
    // })

    // setProcessing(true);
    // save the data
    const toastId = toast.loading('Uploading...', {
      duration: 3000,
      icon: '⤴️',
    })

    toast.loading('Saving...', {
      duration: 3000,
      icon: '💾',
      id: toastId,
    })

    const result = await authFetch(
      `${import.meta.env.VITE_API_URL}/upload-biomarkers`,
      {
        method: 'POST',
        data: formData,
      }
    )
    console.log('thee result is', result)

    const response = result.data
    if (response.error) {
      toast.error(response.message, {
        duration: 5000,
        id: toastId,
        icon: '❌',
      })
      setProcessing(false)
    } else {
      toast.success(response.message, {
        duration: 3000,
        id: toastId,
        icon: '✅',
      })
      // reset the fields
      resetForm()
    }
  }

  useEffect(() => {
    if (Object.keys(uploads).length > 0) {
      setSaveEnabled(true)
    }
  }, [uploads])

  const resetForm = () => {
    setUploads({})
    formRef.current?.reset()
    setInitComplete(false)
    setProcessing(false)
    setSaveEnabled(false)
  }

  const acceptedFiles: AcceptedFileTypes = {
    'text/plain': ['.csv'],
  }

  const handleFileDrop = (files: File[]): void => {
    // setUploads(files);
    const items: UploadDict = {}
    files.forEach((item) => {
      items[item.name] = {
        file: item,
        status: UploadStatus.Ready,
        uploadProgress: 0,
      }
    })
    console.log(items)
    setUploads(items)
  }

  const updateSelectedFileType = (ft: string): FileUploadTypes => {
    switch (ft) {
      // case FileUploadTypes.Master:
      //   return FileUploadTypes.Master
      case FileUploadTypes.Biomarkers:
        return FileUploadTypes.Biomarkers
      case FileUploadTypes.Tests:
        return FileUploadTypes.Tests
      case FileUploadTypes.Tests2Biomarkers:
        return FileUploadTypes.Tests2Biomarkers
      case FileUploadTypes.TestPriceCategories:
        return FileUploadTypes.TestPriceCategories
      case FileUploadTypes.Products:
        return FileUploadTypes.Products
      case FileUploadTypes.Products2Tests:
        return FileUploadTypes.Products2Tests
      case FileUploadTypes.Tests2Tests:
        return FileUploadTypes.Tests2Tests
      case FileUploadTypes.BiomarkerCategories:
        return FileUploadTypes.BiomarkerCategories
      default:
        return FileUploadTypes.None
    }
  }

  const getDropAreaLabel = (): string => {
    switch (fileSelector) {
      // case FileUploadTypes.Master:
      //   return 'Drop the MASTER sheet here'
      case FileUploadTypes.Biomarkers:
        return 'Drop the BIOMARKERS only sheet here'
      case FileUploadTypes.Tests:
        return 'Drop the TESTS only sheet here'
      case FileUploadTypes.Tests2Biomarkers:
        return 'Drop the BIOMARKERS TO TEST mapping file only sheet here'
      case FileUploadTypes.TestPriceCategories:
        return 'Drop the TEST PRICE CATEGORIES mapping file only sheet here'
      case FileUploadTypes.Products:
        return 'Drop the PRODUCTS mapping file only sheet here'
      case FileUploadTypes.Products2Tests:
        return 'Drop the PRODUCTS TO TESTS mapping file only sheet here'
      case FileUploadTypes.Tests2Tests:
        return 'Drop the TestTestLink mapping file only sheet here'
      case FileUploadTypes.BiomarkerCategories:
        return 'Drop the Biomarker Categories sheet here'

      default:
        return FileUploadTypes.None
    }
  }

  return (
    <AdminPage>
      <PageSection>
        <form className="max-w-5xl" onSubmit={handleSubmit} ref={formRef}>
          <PageHeading
            title="Update biomarkers/tests"
            description="Select which kind of file you are going to upload, then pick the file and hit upload."
          ></PageHeading>

          <SimpleSelectField
            name="file-selector"
            value={fileSelector}
            handleChange={(e) => {
              resetForm()
              setFileSelector(updateSelectedFileType(e.strValue))
            }}
            options={[
              {
                label: '-- Please select an option --',
                value: FileUploadTypes.None,
              },
              {
                label: 'Base Entities',
                value: 'baseEntities',
                disabled: true,
              },
              {
                label: '------------------',
                value: 'divider',
                disabled: true,
              },
              // {
              //   label:
              //     'Master Biomarker Mapping File - (MASTER SHEET FOR APP IMPORT sheet)',
              //   value: FileUploadTypes.Master,
              // },
              {
                label: 'Biomarker Categories (BIOMARKER CATEGORIES sheet)',
                value: FileUploadTypes.BiomarkerCategories,
              },
              {
                label: 'Biomarkers - (BIOMARKERS sheet)',
                value: FileUploadTypes.Biomarkers,
              },
              {
                label: 'Tests - (TESTS sheet)',
                value: FileUploadTypes.Tests,
              },
              {
                label: 'Products (PRODUCTS sheet)',
                value: FileUploadTypes.Products,
              },

              {
                label: 'Mappers',
                value: 'mappers',
                disabled: true,
              },
              {
                label: '------------------',
                value: 'divider2',
                disabled: true,
              },
              {
                label: 'Test to Biomarker Mapper (BiomarkerTestLink sheet)',
                value: FileUploadTypes.Tests2Biomarkers,
              },
              {
                label: 'Test Price Categories (TEST PRICE CATEGORIES sheet)',
                value: FileUploadTypes.TestPriceCategories,
              },

              {
                label: 'Product to Test Mapper (ProductTestLink sheet)',
                value: FileUploadTypes.Products2Tests,
              },
              {
                label: 'Test to Test mapper (TestTestLink sheet)',
                value: FileUploadTypes.Tests2Tests,
              },
            ]}
          ></SimpleSelectField>
          {fileSelector !== FileUploadTypes.None && (
            <div className="max-w-96 pt-10">
              <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
                <FileDrop
                  acceptedFileTypes={acceptedFiles}
                  multiple={false}
                  label={getDropAreaLabel()}
                  onDropCallback={handleFileDrop}
                />
                {Object.keys(uploads).length > 0 && (
                  <div className="mt-4">
                    {Object.values(uploads).map((file, idx) => (
                      <FileDropFile
                        key={`file-${idx}`}
                        file={file.file}
                        uploadProgress={file.uploadProgress}
                        status={file.status}
                      ></FileDropFile>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={resetForm}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-bgt-primary/90 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-bgt-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-dark"
              disabled={!saveEnabled || processing}
            >
              Save
            </button>
          </div>
        </form>
      </PageSection>
    </AdminPage>
  )
}

interface FileDropFileProps {
  file: File
  uploadProgress: number
  status: UploadStatus
}

export function FileDropFile({
  file,
  uploadProgress,
  status,
}: FileDropFileProps) {
  const getSize = (): number => {
    return Math.round(file.size / 1000)
  }
  return (
    <div
      className={`p-4 border-solid border-2 border-gray-dark/20 rounded-lg mb-2 flex relative`}
    >
      <div className="size-10 mr-2">
        {status === UploadStatus.Ready && (
          <DocumentTextIcon width={40} className=""></DocumentTextIcon>
        )}
        {status === UploadStatus.Complete && (
          <CheckCircleIcon width={40} className=""></CheckCircleIcon>
        )}
        {status === UploadStatus.Uploading && (
          <div className="size-8 relative">
            <svg
              className="size-full"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="18"
                cy="18"
                r="16"
                fill="none"
                className="stroke-current text-gray-200 dark:text-gray-700"
                stroke-width="4"
              ></circle>
              <g className="origin-center -rotate-90 transform">
                <circle
                  cx="18"
                  cy="18"
                  r="16"
                  fill="none"
                  className="stroke-current text-bgt-primary dark:text-bgt-primary"
                  stroke-width="4"
                  stroke-dasharray="100"
                  stroke-dashoffset={uploadProgress}
                ></circle>
              </g>
            </svg>
          </div>
        )}
      </div>
      <div className="max-w-72">
        <p className="truncate leading-4 font-bold p-0">{file.name}</p>
        <small>({getSize()}kb)</small>
      </div>
    </div>
  )
}
