import { ReactNode, useEffect, useState } from 'react'
import { ThemeInterface } from '../../../../../lib/interfaces'
import { OrganisationWithBranding } from '../../../../context/OrganisationContext'

import { Modal } from '../../../../layout/Modal'

interface ConfirmDeleteModalProps {
  show: boolean
  theme?: ThemeInterface
  setShow: (openState: boolean) => void
  org: OrganisationWithBranding
  dismissOnDelete?: boolean
  message: string | ReactNode
  onDelete?: (identifier?: string) => void
  onDismiss?: (identifier?: string) => void
  identifier?: string
}

export function ConfirmDeleteModal({
  show,
  setShow,
  theme,
  onDelete,
  onDismiss,
  identifier,
  message,
  dismissOnDelete = false,
}: ConfirmDeleteModalProps) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(show)
  }, [show])

  return (
    <Modal
      show={open}
      options={{
        secondaryAction: {
          label: 'Dismiss',
          handler: () => {
            setShow(false)
            onDismiss && onDismiss()
          },
        },
        mainActions: [
          {
            label: 'Delete',
            handler: async () => {
              // lets submit the form now.
              console.log('user hit delete')
              onDelete && onDelete(identifier)
              if (dismissOnDelete) {
                setShow(false)
              }
            },
          },
        ],
        title: 'Are you sure?',
        content: message,
      }}
      onClose={() => setShow(false)}
      theme={theme || undefined}
      size="lg"
    ></Modal>
  )
}
