import { Fragment, useEffect, useState } from 'react'
import PageLoader from '../../../layout/PageLoader'
import PageSection from '../../../layout/PageSection'
import { useOrganisationContext } from '../../../context/OrganisationContext'
import { PageHeading } from '../../../layout/PageHeading'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import toast from 'react-hot-toast'
import {
  CalculatedPrice,
  ProductWithTestsAndBiomarkersSelected,
  TestWithBiomarkers,
  TestWithBiomarkersSelected,
} from '../../../../lib/validators'
import {
  calculatePrice,
  getProductForOrganisation,
  getTests,
  saveOrganisationTestPackage,
} from '../../../../lib/organisation'
import { useParams } from 'react-router-dom'
import { setDocumentTitle } from '../../../../lib/utils'
import { getBundleUrl, getBundlesUrl } from '../../../../lib/routes'
import OrgPage from '../../../OrgPage'
import TextInputField, { onChangeInterface } from '../../../form/TextInputField'
import { Button } from '../../../form/button/Button'
import { TestCustomisationItem } from '../../../test/TestCustomisationItem'
import { TestInfoModal } from './modals/TestInfoModal'
import { Textarea } from '../../../form/Textarea'
import Switch from '../../../form/Switch'
import { SendPackageModal } from './modals/SendPackageModal'
import TestBuilderFooter from '../../../organisation/TestBuilderFooter'

import { defaultPriceObject } from './CreateTest'
import PriceSummary from '../../../organisation/PriceSummary'
import { BuyPackageModal } from './modals/BuyPackageModal'
import DropdownMenu from '../../../menu/DropdownMenu'
import {
  // ArchiveBoxIcon,
  ClipboardDocumentListIcon,
  CreditCardIcon,
  PaperAirplaneIcon,
} from '@heroicons/react/24/outline'
import { ItemType, MenuItem } from '../../../header/ProfileHeader'
import { stripeOptions, stripePromise } from '../../../../lib/billing'
import { Elements } from '@stripe/react-stripe-js'

interface BasicTestData {
  name: string
  description?: string | null
  internal?: boolean
}

export function OrgTestBundle() {
  const [loading, setLoading] = useState(false)
  const { org, theme } = useOrganisationContext()
  const params = useParams()
  const [pack, setPackage] =
    useState<ProductWithTestsAndBiomarkersSelected | null>(null)
  const [formData, setFormData] = useState<BasicTestData>()
  const [isSaving, setIsSaving] = useState(false)
  const [disableSave, setDisableSave] = useState(true)
  const [tests, setTests] = useState<TestWithBiomarkersSelected[]>([])
  const [rawTests, setRawTests] = useState<TestWithBiomarkers[]>([])
  const [openTestModal, setOpenTestModal] = useState(false)
  const [checkedSelected, setCheckedSelected] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [isFiltering, setIsFiltering] = useState(false)
  const [openInviteModal, setOpenInviteModal] = useState(false)
  const [openBuyModal, setOpenBuyModal] = useState(false)
  const [filteredTests, setFilteredTests] = useState<
    TestWithBiomarkersSelected[]
  >([])
  const [selectedTest, setSelectedTest] =
    useState<TestWithBiomarkersSelected | null>()

  const [totalPrice, setTotalPrice] =
    useState<CalculatedPrice>(defaultPriceObject)
  const [calculatePriceController, setCalculatePriceController] = useState<
    AbortController | undefined
  >()

  useEffect(() => {
    setDocumentTitle(`Loading Bundle | ${org && org?.name}`, 'BRANDED')
  }, [])

  useEffect(() => {
    let total = 0

    tests.forEach((test) =>
      test.checked && test.price ? (total += test.price) : 0
    )
    const testsPicked: string[] = []
    tests.map((test) => test.checked && testsPicked.push(test.id))

    console.log('the number of tests checked is...', testsPicked.length)
    if (testsPicked.length === 0) {
      setTotalPrice({
        ...defaultPriceObject,
        calculating: false,
      })
      setDisableSave(true)
      return
    }
    setTotalPrice((prev) => ({
      ...prev,
      calculating: true,
    }))
    if (calculatePriceController) {
      console.log('clear the old controller if we have one')
      calculatePriceController.abort()
    }
    const abortSignal = new AbortController()
    setCalculatePriceController(abortSignal)
    calculatePrice(testsPicked, abortSignal).then((price) => {
      if (price) {
        setTotalPrice({
          ...price,
          calculating: false,
        })
        setDisableSave(false)
      }
    })
    // setTotalPrice(total)
  }, [tests])

  const handleElementChange = (e: onChangeInterface) => {
    if (formData) {
      setFormData((prev: BasicTestData) => ({
        ...prev,
        [e.name]: e.value,
      }))
    }
  }
  const onChange = (data: onChangeInterface) => {
    const newState = tests.map((test) => {
      if (
        test.id === data.name &&
        (data.value === true || data.value === false)
      ) {
        return { ...test, checked: data.value }
      }
      return test
    })
    setTests(newState)
  }
  const onInfoClick = (test: TestWithBiomarkersSelected) => {
    setSelectedTest(test)
    setOpenTestModal(true)
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (!org) {
      return
    }

    const testsToSave = tests.filter((t) => t.checked).map((i) => i.id)

    setIsSaving(true)
    await saveOrganisationTestPackage(
      org.uuid,
      {
        internal: !formData?.internal,
        tests: testsToSave,
        price: totalPrice.grandTotal,
        description: formData?.description || undefined,
        name: formData?.name,
      },
      pack?.id
    )
    setIsSaving(false)
    toast.success('Test bundle changes saved.', {
      duration: 3000,
    })

    // setIsSaving(true)
    // if (formData) {
    //   await updateOrganisation(org?.uuid, formData)
    //   await refreshCurrentUser()
    //   toast.success('Organisation changes saved', {
    //     duration: 3000,
    //   })
    // }
    // setIsSaving(false)
  }

  useEffect(() => {
    if (org && !loading && pack === null && params.bundleId) {
      setLoading(true)
      getProductForOrganisation(org.uuid, params.bundleId).then((product) => {
        setPackage(product)
        setLoading(false)
        setFormData({
          name: product.name === 'Unnamed Test' ? '' : product.name,
          description: product.description,
          internal: !product.internal,
        })
      })
      getTests().then((fetchedTests) => {
        setRawTests(
          fetchedTests.map((test) => ({
            ...test,
          }))
        )
      })
    }
  }, [org, pack, loading, params.bundleId])

  useEffect(() => {
    if (rawTests && rawTests.length > 0 && pack && !checkedSelected) {
      const checkedTests = rawTests.map((test) => ({
        ...test,
        ...{ price: test.price },
        checked:
          (pack.tests &&
            pack.tests.filter((item) => test.id === item.id).length > 0) ||
          false,
      }))
      setTests(checkedTests)
      setCheckedSelected(true)
    }
  }, [tests, pack, checkedSelected, rawTests])

  useEffect(() => {
    if (pack) {
      setDocumentTitle(`${pack && pack.name} | ${org && org?.name}`, 'BRANDED')
    }
  }, [pack, org])

  useEffect(() => {
    if (searchText) {
      let filtered: TestWithBiomarkersSelected[] = tests
      setIsFiltering(true)

      filtered = tests.filter((test) => {
        if (test.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
          return true
        }
        if (test.biomarkers) {
          for (let b = 0; b < test.biomarkers?.length; b += 1) {
            if (
              test.biomarkers[b].name
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) !== -1 ||
              test.biomarkers[b].commonName
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) !== -1
            ) {
              return true
            }
          }
        }
        return false
      })
      setFilteredTests(filtered)
      setIsFiltering(true)
      return
    }
    setFilteredTests([])
    setIsFiltering(false)
  }, [tests, searchText])

  const getActionMenuItems = (): MenuItem[] => {
    return [
      {
        type: ItemType.GroupTitle,
        name: 'Share',
        current: false,
      },
      {
        current: false,
        name: 'Send to...',
        type: ItemType.Item,
        icon: PaperAirplaneIcon,
        className: '',
        onClick: () => {
          setOpenInviteModal(true)
        },
      },
      {
        current: false,
        name: 'Buy for...',
        type: ItemType.Item,
        icon: CreditCardIcon,
        className: '',
        onClick: () => {
          setOpenBuyModal(true)
        },
      },
      // {
      //   type: ItemType.Divider,
      //   name: 'Diviver',
      //   current: false,
      // },
      // {
      //   current: false,
      //   name: 'Archive Bundle',
      //   type: ItemType.Item,
      //   icon: ArchiveBoxIcon,
      //   className: '',
      //   onClick: () => {
      //     console.log('clciked it')
      //   },
      // },
      {
        type: ItemType.Divider,
        name: 'Diviver',
        current: false,
      },
      {
        current: false,
        name: 'Copy Bundle URL',
        type: ItemType.Item,
        icon: ClipboardDocumentListIcon,
        className: '',
        onClick: () => {
          navigator.clipboard.writeText(
            `${import.meta.env.VITE_APP_URL}/buy/${pack?.id}`
          )
          toast.success(`${pack?.name} URL copied`, {
            duration: 3000,
          })
        },
      },
    ]
  }

  return (
    <Fragment>
      {org && (
        <OrgPage
          org={org}
          tabs={[
            {
              name: 'Test Bundles',
              href: getBundlesUrl(org?.id),
            },
            {
              name: `${pack ? pack.name : 'Loading'} `,
              href: getBundleUrl(org?.id, pack?.id || ''),
            },
          ]}
          className="pb-20"
        >
          {loading && <PageLoader theme={theme || undefined}></PageLoader>}
          {!loading && org && pack && (
            <PageSection>
              <form className="" onSubmit={handleSubmit}>
                <PageHeading
                  title={pack.name}
                  action={
                    <div>
                      <DropdownMenu menuItems={getActionMenuItems()}>
                        Actions{' '}
                        <ChevronDownIcon className="size-4 fill-white/60 inline" />
                      </DropdownMenu>
                    </div>
                  }
                ></PageHeading>

                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-0 py-4 sm:py-8 sm:px-6 md:grid-cols-3">
                  <div>
                    <h2 className="text-base font-semibold leading-7 ">
                      Bundle Information
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-dark">
                      General bundle configuration
                    </p>
                  </div>

                  <div className="md:col-span-2">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:max-w-xl sm:grid-cols-6">
                      <div className="col-span-full text-md">
                        <TextInputField
                          id="name"
                          name="name"
                          type="text"
                          label="Bundle Name"
                          className="w-full sm:!max-w-60"
                          value={formData?.name || ''}
                          handleChange={handleElementChange}
                        ></TextInputField>
                      </div>
                      <div className="col-span-full text-md mt-2">
                        <Textarea
                          id="description"
                          name="description"
                          label="Bundle Description"
                          value={formData?.description || ''}
                          handleChange={handleElementChange}
                        ></Textarea>
                      </div>
                      <div className="col-span-full text-md mt-2">
                        <Switch
                          name="internal"
                          checked={formData?.internal || false}
                          label="Save as Template"
                          tooltip="If this is checked, it appears as a template when using the Test Creator"
                          theme={theme || undefined}
                          onChange={(value) => {
                            if (formData && formData.internal !== value) {
                              setFormData((prev: BasicTestData) => ({
                                ...prev,
                                internal: value,
                              }))
                            }
                          }}
                        ></Switch>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <Fragment>
                    <div className="pb-2">
                      <TextInputField
                        id="search"
                        name="search"
                        type="search"
                        required={false}
                        value={searchText}
                        placeholder="Search for test or biomarker"
                        handleChange={(data: onChangeInterface) => {
                          setSearchText(data.value as string)
                        }}
                      />
                    </div>
                  </Fragment>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {!isFiltering &&
                    tests.map((item) => (
                      <TestCustomisationItem
                        test={item}
                        checked={item.checked}
                        key={`test-item-${item.id}`}
                        onChange={onChange}
                        onInfoClick={onInfoClick}
                        theme={theme || undefined}
                      ></TestCustomisationItem>
                    ))}
                  {isFiltering && (
                    <Fragment>
                      {filteredTests.map((item) => (
                        <TestCustomisationItem
                          test={item}
                          checked={item.checked}
                          key={`test-item-${item.id}`}
                          onChange={onChange}
                          onInfoClick={onInfoClick}
                          highlight={searchText}
                        ></TestCustomisationItem>
                      ))}
                      {filteredTests.length === 0 && (
                        <div>No tests matching current filters</div>
                      )}
                    </Fragment>
                  )}
                </div>

                <PriceSummary
                  price={totalPrice}
                  showSummary={true}
                  tests={tests}
                  // onRemove={onRemove}
                ></PriceSummary>

                <TestBuilderFooter hasSelectedTest={true} price={totalPrice}>
                  <Button
                    type="submit"
                    loading={isSaving}
                    disabled={disableSave}
                    label="Save"
                    theme={theme || undefined}
                  ></Button>
                </TestBuilderFooter>
              </form>
            </PageSection>
          )}
          <TestInfoModal
            test={selectedTest || undefined}
            theme={theme || undefined}
            show={openTestModal}
            setShow={setOpenTestModal}
          ></TestInfoModal>
          <SendPackageModal
            org={org}
            productId={pack?.id}
            productName={formData?.name}
            theme={theme || undefined}
            show={openInviteModal}
            setShow={setOpenInviteModal}
            dismissOnSkip={true}
            dismissOnSend={true}
          ></SendPackageModal>
          <Elements stripe={stripePromise} options={stripeOptions}>
            <BuyPackageModal
              org={org}
              productId={pack?.id}
              productName={formData?.name}
              theme={theme || undefined}
              show={openBuyModal}
              setShow={setOpenBuyModal}
              dismissOnSkip={true}
              dismissOnSend={true}
            ></BuyPackageModal>
          </Elements>
        </OrgPage>
      )}
    </Fragment>
  )
}
