import { ThemeInterface } from "../../../lib/interfaces";
import { LinkButton } from "./LinkButton";

interface BtnProps {
  internal?: boolean;
  theme?: ThemeInterface;
}



export function BuyTestButton({ internal, theme }: BtnProps) {
  return (
    <LinkButton
      url={`${import.meta.env.VITE_SHOP_URL}/blood-tests`}
      label="Buy Blood Test"
      target="bgt-store"
      internal={internal}
      theme={theme}
    ></LinkButton>
  );
}