import { Fragment } from 'react'
import { setDocumentTitle } from '../../../../../lib/utils'
import { SettingsTabProps } from '../Settings'
import SettingsTabApiKeys from './SettingsTabApiKeys'

export const SettingsTabApikey: React.FC<SettingsTabProps> = () => {
  setDocumentTitle(`Api Settings`, 'BRANDED')

  return (
    <Fragment>
      <div className="divide-y divide-gray">
        <SettingsTabApiKeys />
      </div>
    </Fragment>
  )
}
